/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import styles from "./PlaceOrder.module.css";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import {
  countryList,
  sizes,
  camelCaseToTitleCase,
  changeDateFormat,
} from "../../../utils/index";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../../../stripe/CheckoutForm";
import { toast } from "react-toastify";
import Autocomplete from "react-google-autocomplete";
import { isEmailValid } from "../../../utils/Validations";

const Steps = ({ desc, val, step, clickHandler }) => {
  return (
    <div className={styles.steps}>
      <span className={`${styles.circle} ${step === val ? styles.active : ""}`}>
        <span></span>
      </span>
      <div>
        <h3>Step - {val}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
};

function PlaceOrder({ setNav }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [senderAddress, setSenderAddress] = useState({
    sender_full_address: "",
    longitude: "",
    latitude: "",
  });
  const [recipientAddress, setRecipientAddress] = useState({
    recipient_address: "",
    reclongitude: "",
    reclatitude: "",
  });
  const [orderData, setOrderData] = useState({
    box_size: "",
    receiver_city: "",
    length: "",
    width: "",
    height: "",
    receiver_country: "United Arab Emirates",
    delivery_service_type: "",
    delivery_service_type_amount: "",
    actual_weight: "",
    payment_type: "",
    number_of_pieces: "1",
    actual_product_amount: 0,
    pickup_date: "",
    sender_name: "",
    sender_email: "",
    sender_mobile: "",
    recipient_mobile: "",
    recipient_name: "",
    recipient_email: "",
    instructions: "",
    description: "",
    totalAmount: "",
    payeeName: "",
    payeeEmail: "",
    onlinePayment: false,
    senderCity: "",
  });
  const [senderEmailError, setSenderEmailError] = useState("");
  const [senderMobileError, setSenderMobileError] = useState("");
  const [recipientEmailError, setRecipientEmailError] = useState("");
  const [recipientMobileError, setRecipientMobileError] = useState("");

  const [step, setStep] = useState(1);
  const [availability, setAvailability] = useState([]);
  const [senderCities, setSetSenderCities] = useState([]);
  const [defaultCheck, setDefaultCheck] = useState(false);
  const { client, token } = useSelector((states) => states.client);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const defaultHandler = (e) => {
    setDefaultCheck(e.target.checked);
    setOrderData({
      ...orderData,
      sender_mobile: client.phoneNumberOne,
      sender_email: client.emailId,
      sender_name: client.fullName,
      pickup_date: changeDateFormat(new Date()),
    });
    setSenderAddress({
      ...senderAddress,
      sender_full_address: client.defaultAddress,
      longitude: client.longitude,
      latitude: client.latitude,
    });
    if (e.target.checked) {
      setSetSenderCities([...client.cities]);
    } else {
      setSetSenderCities([...availability]);
    }
  };

  const defaultLocationHandler = (e) => {
    if (e.target.checked) {
      if (window.navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setSenderAddress({
            ...senderAddress,
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          });
        });
      } else {
        toast.error("Geolocation is not supported by this browser.");
      }
    }
  };

  console.log("order", orderData);

  const clickHandler = (flag) => {
    setStep(flag);
  };

  const getAvailability = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/get`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios(config);
    setAvailability([...result.data.data]);
    setSetSenderCities([...result.data.data]);
  };

  useEffect(() => {
    getAvailability();
  }, []);

  const cardCompleteHandler = (complete) => {
    setCardComplete(complete);
  };

  const submitHandler = async () => {
    if (
      !orderData.sender_name.length > 0 &&
      !orderData.sender_mobile.length > 0 &&
      !orderData.sender_email.length > 0 &&
      !senderAddress.sender_full_address.length > 0 &&
      !senderAddress.latitude.length > 0 &&
      !senderAddress.longitude.length > 0 &&
      !orderData.recipient_name.length > 0 &&
      !orderData.recipient_email.length > 0 &&
      !orderData.recipient_mobile.length > 0 &&
      !orderData.receiver_city.length > 0 &&
      !orderData.delivery_service_type.length > 0 &&
      !orderData.delivery_service_type_amount.length > 0 &&
      !orderData.senderCity.length > 0 &&
      !recipientAddress.recipient_address.length > 0
    ) {
      toast.error("Please fill all the required fields.");
    } else if (orderData.sender_mobile.length < 10) {
      // setSenderMobileError("Invalid Mobile Number");
      toast.error("Invalid Sender Mobile Number");
    } else if (orderData.recipient_mobile.length < 10) {
      // setRecipientMobileError("Invalid Mobile Number");
      toast.error("Invalid Recipient Mobile Number");
    } else if (!isEmailValid(orderData.sender_email)) {
      // setSenderEmailError("Invalid Email");
      toast.error("Invalid Sender Email Address");
    } else if (!isEmailValid(orderData.recipient_email)) {
      // setRecipientEmailError("Invalid Email");
      toast.error("Invalid Recipient Email Address");
    } else {
      let config = {
        url: process.env.REACT_APP_BASE_URL + `/order/place_order`,
        method: "post",
        headers: {
          secret_key: process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${token}`,
        },
        data: {
          client: client._id,
          pickUpDate: orderData.pickup_date,
          senderName: orderData.sender_name,
          senderMobile: orderData.sender_mobile,
          senderAddress: senderAddress.sender_full_address,
          recipientName: orderData.recipient_name,
          recipientMobile: orderData.recipient_mobile,
          recipientCountry: orderData.receiver_country,
          recipientEmail: orderData.recipient_email,
          recipientCity: orderData.receiver_city,
          recipientFullAddress: recipientAddress.recipient_address,
          deliveryType: orderData.delivery_service_type,
          deliveryTypeAmount: orderData.delivery_service_type_amount,
          paymentType: orderData.payment_type,
          instructions: orderData.instructions,
          description: orderData.description,
          packageType: orderData.box_size,
          numberOfPieces: orderData.number_of_pieces,
          actualWeight: orderData.actual_weight,
          actualProductAmount: orderData.actual_product_amount,
          length: orderData.length,
          width: orderData.width,
          height: orderData.height,
          isPaymentSuccess: false,
          senderLatitude: senderAddress.latitude,
          senderLongitude: senderAddress.longitude,
          recipientLatitude: recipientAddress.reclatitude,
          recipientLongitude: recipientAddress.reclongitude,
          totalAmount: calculateTotalAmount(
            orderData.receiver_city === "Saudi Arab" ? "Saudi Arab" : ""
          ),
          isPaymentSettled: false,
          onlinePayment: orderData.onlinePayment,
          payeeName: orderData.payeeName,
          payeeEmail: orderData.payeeEmail,
          senderCity: orderData.senderCity,
          senderEmail: orderData.sender_email,
        },
      };

      if (orderData.onlinePayment) {
        if (!stripe || !elements) {
          return;
        }

        if (cardComplete) {
          setProcessing(true);
        } else {
          return;
        }
        setLoading(true);
        stripe
          .createToken(elements.getElement(CardElement))
          .then(async (tok) => {
            config.data.token = tok.token;
            try {
              const result = await axios(config);

              if (result.data.code) {
                setLoading(false);
                setNav(0);
              }
            } catch (err) {
              setLoading(false);
            }
          });
      } else {
        setLoading(true);
        try {
          const result = await axios(config);
          if (result.data.code) {
            setLoading(false);
            setNav(0);
          }
        } catch (err) {
          setLoading(false);
        }
      }
    }
  };

  const changeHandler = (e) => {
    if (e.target.name === "box_size" && e.target.value !== "") {
      setOrderData({
        ...orderData,
        length: sizes[e.target.value].length,
        width: sizes[e.target.value].width,
        height: sizes[e.target.value].height,
      });
      return;
    }

    if (e.target.name === "box_size" && e.target.value === "") {
      setOrderData({
        ...orderData,
        length: "",
        width: "",
        height: "",
      });
      return;
    }

    if (e.target.name === "delivery_service_type" && e.target.value !== "") {
      console.log(e.target.value);
      let type = e.target.value.split(" ");
      setOrderData({
        ...orderData,
        delivery_service_type: type[1],
        delivery_service_type_amount: type[0],
      });
      return;
    }

    if (e.target.name === "payment_type" && e.target.value !== "") {
      setOrderData({
        ...orderData,
        payment_type: e.target.value,
        onlinePayment: e.target.value === "prepaid" ? true : false,
        actual_product_amount: e.target.value === "prepaid" && 0,
      });
      return;
    }

    if (e.target.name === "longitude" || e.target.name === "latitude") {
      setSenderAddress({
        ...senderAddress,
        [e.target.name]: e.target.value,
      });
      return;
    }

    setOrderData({
      ...orderData,
      [e.target.name]: e.target.value,
    });
  };

  const calculateTotalAmount = (type) => {
    if (type === "Saudi Arab") {
      let estimated_weight =
        (orderData.length * orderData.width * orderData.height) / 5000;
      let Weight =
        estimated_weight > orderData.actual_weight
          ? Math.ceil(estimated_weight)
          : Math.ceil(orderData.actual_weight);
      let calculated_value = (Weight - 1) * 12 + 70;
      return calculated_value;
    }
    let piece =
      orderData.payment_type !== "cash"
        ? 1
        : parseFloat(orderData.number_of_pieces);
    let val = parseFloat(
      parseFloat(orderData.actual_product_amount) * piece
    ).toFixed(2);
    return val;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.leftStaple}>
            <Steps
              clickHandler={() => clickHandler(1)}
              step={step}
              val={1}
              desc='Sender Information'
            />
            <Steps
              step={step}
              clickHandler={() => clickHandler(2)}
              val={2}
              desc='Receiver Information'
            />
            <Steps
              step={step}
              clickHandler={() => clickHandler(3)}
              val={3}
              desc='Package Information'
            />
            <Steps
              step={step}
              clickHandler={() => clickHandler(4)}
              val={4}
              desc='Complete & Finish'
            />
          </div>
          <div className={styles.rightStaple}>
            <form>
              {step === 1 && (
                <>
                  <div className={styles.personal}>
                    <div className={styles.default}>
                      <input
                        onChange={defaultHandler}
                        checked={defaultCheck}
                        value={defaultCheck}
                        id='check'
                        type='checkbox'
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor='check'>Choose default details</label>
                    </div>
                    <div className='flex flex-d gap-1'>
                      {defaultCheck ? (
                        <></>
                      ) : (
                        <div className={styles.fields}>
                          <div>
                            <InputField
                              type='date'
                              onChangeHandler={changeHandler}
                              label='Pickup On'
                              name='pickup_date'
                              value={orderData.pickup_date}
                            />
                            <InputField
                              type='text'
                              onChangeHandler={changeHandler}
                              label='Sender Name'
                              name='sender_name'
                              value={orderData.sender_name}
                            />
                          </div>
                          <div>
                            <InputField
                              type='text'
                              onChangeHandler={changeHandler}
                              label='Sender Mobile'
                              error={senderMobileError}
                              name='sender_mobile'
                              value={orderData.sender_mobile}
                            />
                            {/* <InputField 
                                type='text'
                                onChangeHandler={changeHandler}
                                label = 'Sender Full Address'
                                name = 'sender_full_address'
                                value={orderData.sender_full_address}
                            /> */}
                            <InputField
                              type='text'
                              onChangeHandler={changeHandler}
                              label='Sender Email'
                              name='sender_email'
                              value={orderData.sender_email}
                              error={senderEmailError}
                            />
                          </div>
                        </div>
                      )}
                      <div className={styles.fields}>
                        <div className={styles.select}>
                          <select
                            value={orderData.senderCity}
                            name='senderCity'
                            onChange={changeHandler}>
                            <option value=''>Sender City</option>
                            {senderCities.map((cities, index) => {
                              if (
                                defaultCheck ||
                                cities.pickupAvailability === "Available"
                              ) {
                                return (
                                  <option
                                    key={index}
                                    value={defaultCheck ? cities : cities.city}>
                                    {defaultCheck ? cities : cities.city}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </div>
                        <div className={styles.address}>
                          <div>
                            <Autocomplete
                              apiKey='AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU'
                              style={{ width: "100%" }}
                              name='sender_full_address'
                              placeholder=' '
                              onPlaceSelected={(place) => {
                                let lat = place.geometry.location.lat();
                                let lng = place.geometry.location.lng();
                                setSenderAddress({
                                  sender_full_address: place.formatted_address,
                                  latitude: lat,
                                  longitude: lng,
                                });
                              }}
                              defaultValue={senderAddress.sender_full_address}
                              options={{
                                componentRestrictions: { country: ["ae"] },
                                types: ["geocode", "establishment"],
                              }}
                            />
                            <label>Sender Full Address</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.default}>
                      <input
                        onChange={defaultLocationHandler}
                        id='location'
                        type='checkbox'
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor='location'>Choose From Map</label>
                    </div>
                    <div className={styles.fields}>
                      <div>
                        <InputField
                          type='number'
                          onChangeHandler={changeHandler}
                          label='Longitude'
                          name='longitude'
                          value={senderAddress.longitude}
                        />
                        <InputField
                          type='number'
                          onChangeHandler={changeHandler}
                          label='Latitude'
                          name='latitude'
                          value={senderAddress.latitude}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className={styles.fields}>
                    <div>
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        label='Recipient Name'
                        name='recipient_name'
                        value={orderData.recipient_name}
                      />
                      <InputField
                        type='number'
                        onChangeHandler={changeHandler}
                        label='Recipient Mobile'
                        name='recipient_mobile'
                        value={orderData.recipient_mobile}
                        error={recipientMobileError}
                      />
                    </div>
                    <div>
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        label='Recipient Email'
                        name='recipient_email'
                        value={orderData.recipient_email}
                        error={recipientEmailError}
                      />
                    </div>
                    <div>
                      <div className={styles.select}>
                        <select
                          onChange={changeHandler}
                          name='receiver_country'
                          value={orderData.receiver_country}>
                          {countryList.map((country, index) => {
                            if (country === "United Arab Emirates") {
                              return (
                                <option key={index} selected value={country}>
                                  {country}
                                </option>
                              );
                            } else {
                              return (
                                <option key={index} value={country}>
                                  {country}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                      <div className={styles.select}>
                        <select
                          onChange={changeHandler}
                          name='receiver_city'
                          value={orderData.receiver_city}>
                          {orderData.receiver_country ===
                          "United Arab Emirates" ? (
                            <>
                              <option>Choose City</option>
                              {availability?.map((item, index) => {
                                return (
                                  <option key={index} value={item.city}>
                                    {item.city}
                                  </option>
                                );
                              })}
                              <option value={"Saudi Arab"}>Saudi Arab</option>
                            </>
                          ) : (
                            <>
                              <option value=''>No City Available</option>
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    {/* <div>
                        
                          <InputField 
                              type='text'
                              onChangeHandler={changeHandler}
                              label = 'Recipient Address'
                              name = 'recipient_address'
                              value={orderData.recipient_address}
                          />
                        </div> */}
                    <div className={styles.address}>
                      <div>
                        <Autocomplete
                          apiKey='AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU'
                          style={{ width: "100%" }}
                          required={true}
                          placeholder=''
                          onPlaceSelected={(place) => {
                            let lat = place.geometry.location.lat();
                            let lng = place.geometry.location.lng();
                            setRecipientAddress({
                              recipient_address: place.formatted_address,
                              reclatitude: lat,
                              reclongitude: lng,
                            });
                          }}
                          defaultValue={recipientAddress.recipient_address}
                          options={{
                            componentRestrictions: { country: ["ae"] },
                            types: ["geocode", "establishment"],
                          }}
                        />
                        <label>Recipient Address</label>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 3 && (
                <>
                  <div className={styles.fields}>
                    <div>
                      {orderData.receiver_city !== "Saudi Arab" && (
                        <div className={styles.select}>
                          <select
                            onChange={changeHandler}
                            name='delivery_service_type'
                            value={`${orderData.delivery_service_type_amount} ${orderData.delivery_service_type}`}>
                            <option value=''>
                              Choose Delivery Service Type
                            </option>
                            {availability.map((item) => {
                              if (item.city === orderData.receiver_city) {
                                return Object.keys(item).map((ele) => {
                                  if (
                                    item[ele] !== -1 &&
                                    typeof item[ele] === "number" &&
                                    item[ele] !== 0
                                  ) {
                                    return (
                                      <option value={`${item[ele]} ${ele}`}>
                                        {camelCaseToTitleCase(ele)}
                                      </option>
                                    );
                                  }
                                });
                              }
                            })}
                          </select>
                        </div>
                      )}
                      {orderData.receiver_city === "Saudi Arab" && (
                        <div className={styles.select}>
                          <select onChange={changeHandler} name='box_size'>
                            <option value=''>Choose Package Type</option>
                            <option value=''>Custom Size</option>
                            <option value='Small'>Small Box</option>
                            <option value='Medium'>Medium Box</option>
                            <option value='Large'>Large Box</option>
                            <option value='Extra Large'>Extra Large</option>
                            <option value='Phone'>Phone Size</option>
                            <option value='Document'>Document Size</option>
                          </select>
                        </div>
                      )}
                    </div>
                    <div>
                      <InputField
                        type='number'
                        max={"20"}
                        onChangeHandler={changeHandler}
                        label='Number of Pieces'
                        name='number_of_pieces'
                        value={orderData.number_of_pieces}
                      />
                      <div className={styles.select}>
                        <select
                          onChange={changeHandler}
                          name='payment_type'
                          value={orderData.payment_type}>
                          <option>Choose Payment Type</option>
                          <option value={"cash"}>Cash on Delivery</option>
                          <option value={"prepaid"}>Prepaid</option>
                        </select>
                      </div>
                    </div>
                    {orderData.receiver_city === "Saudi Arab" && (
                      <div>
                        <InputField
                          type='text'
                          onChangeHandler={changeHandler}
                          value={orderData.length}
                          label='Length'
                          name='length'
                          sup='cm'
                        />
                        <InputField
                          type='number'
                          value={orderData.width}
                          onChangeHandler={changeHandler}
                          label='Width'
                          name='width'
                          sup='cm'
                        />
                        <InputField
                          type='number'
                          value={orderData.height}
                          onChangeHandler={changeHandler}
                          label='Height'
                          name='height'
                          sup='cm'
                        />
                      </div>
                    )}
                    <div>
                      {orderData.receiver_city === "Saudi Arab" && (
                        <InputField
                          type='text'
                          onChangeHandler={changeHandler}
                          label='Actual Weight'
                          name='actual_weight'
                          value={orderData.actual_weight}
                          sup='(kg)'
                        />
                      )}
                      <InputField
                        type='number'
                        styles={{
                          background:
                            orderData.payment_type !== "cash" && "#8080802e",
                        }}
                        disabled={orderData.payment_type !== "cash"}
                        onChangeHandler={changeHandler}
                        label='Actual Product Amount'
                        name='actual_product_amount'
                        value={orderData.actual_product_amount}
                      />
                    </div>
                    <div>
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        label='Instructions'
                        name='instructions'
                        sup='(optional)'
                        value={orderData.instructions}
                      />
                    </div>

                    <div>
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        value={orderData.description}
                        label='Description'
                        name='description'
                        sup='(optional)'
                      />
                    </div>
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <div className={styles.fields}>
                    <div>
                      <InputField
                        type='text'
                        label='Total Amount to be Paid'
                        disabled={true}
                        value={
                          orderData.receiver_city === "Saudi Arab"
                            ? `${calculateTotalAmount("Saudi Arab")}AED`
                            : orderData.payment_type === "cash"
                            ? (parseInt(orderData.delivery_service_type_amount) <= parseInt(orderData.actual_product_amount)) ? `${
                                parseInt(orderData.actual_product_amount) *
                                parseFloat(orderData.number_of_pieces)
                              }AED = ${
                                parseInt(orderData.actual_product_amount) *
                                  parseFloat(orderData.number_of_pieces) -
                                parseInt(orderData.delivery_service_type_amount)
                              }AED (payment back to client) + ${parseInt(
                                orderData.delivery_service_type_amount
                              )}AED (Delivery Charge)` : `0AED = 0AED (payment back to client) + 0AED (Delivery Charge)`
                            : `${
                                isNaN(calculateTotalAmount())
                                  ? 0
                                  : calculateTotalAmount()
                              } AED`
                        }
                        sup={
                          orderData.payment_type === "cash"
                            ? "(COD)"
                            : "(Prepaid)"
                        }
                      />
                    </div>

                    {
                      parseInt(orderData.delivery_service_type_amount) <= parseInt(orderData.actual_product_amount) && <div style={{justifyContent:'center',color:'red'}}><strong>Actual product Amount is always greater than Delivery Charge</strong></div>
                    }
                    {orderData.payment_type !== "cash" && (
                      <div>
                        <>
                          <CheckoutForm
                            elements={elements}
                            complete={cardCompleteHandler}
                            changeHandler={changeHandler}
                            payeeName={orderData.payeeName}
                            payeeEmail={orderData.payeeEmail}
                          />
                        </>
                      </div>
                    )}
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
        <div className={styles.continue}>
          {step > 1 && (
            <Button
              text={"Back"}
              onClickHandler={(e) => setStep(step - 1)}
              style={{
                background: "#122333",
                color: "#fff",
                border: "none",
                borderRadius: "10px",
                padding: "0.7rem 2rem",
              }}
            />
          )}

          {step === 4 ? (
            <Button
              text={"Submit"}
              onClickHandler={submitHandler}
              style={{
                background: "#122333",
                color: "#fff",
                border: "none",
                borderRadius: "10px",
                padding: "0.7rem 2rem",
              }}
            />
          ) : (
            <Button
              text={"Continue"}
              onClickHandler={(e) => setStep(step + 1)}
              style={{
                background: "#122333",
                color: "#fff",
                border: "none",
                borderRadius: "10px",
                padding: "0.7rem 2rem",
              }}
            />
          )}
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
}

export default PlaceOrder;
