import React, { useState } from 'react'
import styles from "./Upload.module.css"
import PlaceOrder from './PlaceOrder'
import UploadOrder from './UploadOrder'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const ELEMENTS_OPTIONS = { 
  fonts: [ 
    { 
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto" 
    } 
  ] 
}; 

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY); 

function Upload({setNav}) {

  const [orderTab,setOrderTab] = useState(1)

  return (
    <div className={styles.container}>
      <div className={styles.tabHeader}>
        <h2 className={orderTab === 1 ? styles.active :""} onClick={() => setOrderTab(1)} >Place Order</h2>
        <h2 className={orderTab === 2 ? styles.active :""} onClick={() => setOrderTab(2)}>Upload Order</h2>
      </div>
      <div className={styles.orderContainer}> 
        {
          orderTab === 1 && 
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <PlaceOrder setNav={setNav}/>
          </Elements>
        }
        {
          orderTab === 2 &&
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
             <UploadOrder setNav={setNav}/>
          </Elements>
        }

      </div>
    </div>
  )
}

export default Upload