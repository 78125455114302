/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.css";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Loader from "../../../components/Loader/Loader";
import { changeDateFormat } from "../../../utils/index";
import axios from "axios";
import { useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../../../components/InputField/InputField";
import Chip from "@mui/material/Chip";
import { DatePicker } from "antd";

const Box = ({ title, amount, flag, sup }) => {
  return (
    <div className={styles.box}>
      <h2>
        {title}&nbsp;
        <sup style={{ fontSize: "0.9rem", color: "#ED4C21" }}>{sup}</sup>
      </h2>
      <h2 style={{ color: flag }}>{amount} AED</h2>
    </div>
  );
};
const { RangePicker } = DatePicker;

function Dashboard() {
  const { token } = useSelector((states) => states.admin);
  const [loading, setLoading] = useState(false);
  const [editOrder, setEditOrder] = useState(false);
  const [rows, setRows] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [data, setData] = useState([]);
  const [riders, setRiders] = useState([]);
  const [paymentPending, setPaymentPending] = useState(0);
  const [paymentReceived, setPaymentReceived] = useState(0);
  const [paymentSettled, setPaymentSettled] = useState(0);
  const [paymentRevenue, setPaymentRevenue] = useState(0);
  const [saveData, setSaveData] = useState({
    acceptedOrders: [],
    assignRiders: [],
  });
  const [orderData, setOrderData] = useState({});
  const [deliveryOrPickup, setDeliveryOrPickup] = useState(false);

  const selectedRiderHandler = (e, order_id) => {
    let assignR = [...saveData.assignRiders];
    let flag = false;
    if (e.target.value === "") {
      assignR.forEach((obj) => {
        if (obj.orderId === order_id) {
          obj.riderId = null;
          flag = true;
        }
      });
      if (!flag) {
        setSaveData({
          ...saveData,
          assignRiders: [
            ...saveData.assignRiders,
            { orderId: order_id, riderId: null },
          ],
        });
      }
      return;
    } else {
      assignR.forEach((obj) => {
        if (obj.orderId === order_id) {
          obj.riderId = e.target.value;
          flag = true;
        }
      });
      if (!flag) {
        setSaveData({
          ...saveData,
          assignRiders: [
            ...saveData.assignRiders,
            { orderId: order_id, riderId: e.target.value },
          ],
        });
      }
    }
  };

  const acceptOrderHandler = (e) => {
    if (e.target.checked) {
      setSaveData({
        ...saveData,
        acceptedOrders: [...saveData.acceptedOrders, e.target.value],
      });
    } else {
      setSaveData({
        ...saveData,
        acceptedOrders: [
          ...saveData.acceptedOrders.filter((id) => id !== e.target.value),
        ],
      });
    }
  };

  const acceptOrder = (cellValues) => {
    return (
      <input
        type='checkbox'
        disabled={cellValues.row.orderAccepted}
        defaultChecked={cellValues.row.orderAccepted}
        value={cellValues.row._id}
        onChange={acceptOrderHandler}
        style={{ width: 20, height: 20 }}
      />
    );
  };

  const paymentSettleHandler = async (e) => {
    const config = {
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/payment_settled/${e.target.value}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    if (e.target.checked) {
      setLoading(true);
      try {
        const result = await axios(config);
        if (result.data.code) {
          setLoading(false);
          getOrderDetails();
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }

    return;
  };

  const paymentCollectedFromRiderHandler = async (e) => {
    const config = {
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/payment_collected_from_rider/${e.target.value}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    if (e.target.checked) {
      setLoading(true);
      try {
        const result = await axios(config);
        if (result.data.code) {
          setLoading(false);
          getOrderDetails();
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }

    return;
  };

  const paymentSettle = (cellValues) => {
    return (
      <input
        type='checkbox'
        disabled={cellValues.row.isPaymentSettled}
        defaultChecked={cellValues.row.isPaymentSettled}
        value={cellValues.row._id}
        onChange={paymentSettleHandler}
        style={{ width: 20, height: 20 }}
      />
    );
  };

  const paymentCollectedFromRider = (cellValues) => {
    if (cellValues.row.paymentType === "cash") {
      return (
        <input
          type='checkbox'
          disabled={cellValues.row.isPaymentCollectedFromRider}
          defaultChecked={cellValues.row.isPaymentCollectedFromRider}
          value={cellValues.row._id}
          onChange={paymentCollectedFromRiderHandler}
          style={{ width: 20, height: 20 }}
        />
      );
    }

    return <>{"--"}</>;
  };

  const invoiceStatus = (cellValues) => {
    return (
      <Chip
        label={cellValues.row.isInvoiceGenerated ? "Done" : "Pending"}
        color={cellValues.row.isInvoiceGenerated ? "success" : "warning"}
        variant='outlined'
      />
    );
  };

  const getAllRiders = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/get_all_rider`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios(config);
    setRiders([...result.data.data]);
  };

  const generateRows = (array) => {
    let data = [];
    for (let i = 0; i < array.length; i++) {
      data.push({
        id: i + 1,
        senderName: array[i].senderName,
        senderCity: array[i].senderCity,
        pickupDate: changeDateFormat(array[i].pickUpDate),
        isPaymentSuccess: array[i].isPaymentSuccess,
        orderStatus: array[i].orderStatus[array[i].orderStatus.length - 1],
        isPaymentSettled: array[i].isPaymentSettled,
        totalAmount: array[i].totalAmount,
        actualProductAmount: array[i].actualProductAmount,
        _id: array[i]._id,
        orderAccepted: array[i].orderAccepted,
        rider: array[i]?.riderPickup?._id,
        cancel: array[i]?.cancel,
        deliverComplete : array[i]?.deliverComplete
      });
    }
    setRows([...data]);
  };

  const generateRows1 = (array) => {
    let data = [];
    for (let i = 0; i < array.length; i++) {
      data.push({
        id: i + 1,
        recipientName: array[i].recipientName,
        recipientCity: array[i].recipientCity,
        pickupDate: changeDateFormat(array[i].pickUpDate),
        isPaymentSuccess: array[i].isPaymentSuccess,
        orderStatus: array[i].orderStatus[array[i].orderStatus.length - 1],
        isPaymentSettled: array[i].isPaymentSettled,
        totalAmount: array[i].totalAmount,
        actualProductAmount: array[i].actualProductAmount,
        _id: array[i]._id,
        orderAccepted: array[i].orderAccepted,
        paymentType: array[i].paymentType,
        rider: array[i]?.riderDeliver?._id,
        isInvoiceGenerated: array[i].isInvoiceGenerated,
        isPaymentCollectedFromRider: array[i].isPaymentCollectedFromRider,
        cancel: array[i]?.cancel,
        deliverComplete : array[i]?.deliverComplete
      });
    }
    setRows1([...data]);
  };

  const filterHandler = (e) => {
    if (deliveryOrPickup) {
      if (e.target.value === "all" || e.target.value === "") {
        generateRows(data);
      } else {
        generateRows(
          data?.filter((item) => item.orderStatus.includes(e.target.value))
        );
      }
    } else {
      if (e.target.value === "all" || e.target.value === "") {
        generateRows(data);
      } else {
        generateRows(
          data?.filter((item) => item.orderStatus.includes(e.target.value))
        );
      }
    }
  };

  const getOrderDetails = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/order/get_all_orders`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data);
      if (result.data.code) {
        let payment_settled = 0;
        let payment_pending = 0;
        let payment_revenue = 0;
        let payment_collected = 0;
        result.data.data.map((order) => {
          if (order.paymentType === "cash") {
            if (
              order.isPaymentCollected &&
              order.isPaymentCollectedFromRider &&
              order.isPaymentSettled === false
            ) {
              payment_collected = payment_collected + order.totalAmount;
            }

            if (
              order.isPaymentCollected === true &&
              order.isPaymentCollectedFromRider === false &&
              order.isPaymentSettled === false
            ) {
              payment_pending = payment_pending + order.totalAmount;
            }

            if (
              order.isPaymentSettled === false &&
              order.isPaymentCollectedFromRider === true
            ) {
              payment_settled =
                payment_settled +
                (order.totalAmount - order.deliveryTypeAmount);
            }
          }

          if (order.isPaymentSettled === true) {
            payment_revenue = payment_revenue + order.deliveryTypeAmount;
          }
        });

        setPaymentReceived(payment_collected);
        setPaymentPending(payment_pending);
        setPaymentSettled(payment_settled);
        setPaymentRevenue(payment_revenue);
        generateRows(result.data.data);
        generateRows1(result.data.data);
        setData([...result.data.data]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getAllRiders();
    getOrderDetails();
  }, []);

  const orderEditSaveHandler = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/order/update_order`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: orderData.id,
        actualProductAmount: orderData.actual_product_amount,
        deliveryTypeAmount: orderData.service_charge,
        remark: orderData.remark,
        totalAmount: calculateTotalAmount(),
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      if (result.data.code) {
        getOrderDetails();
        setLoading(false);
        setEditOrder(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const calculateTotalAmount = () => {
    let p =
      orderData.payment_type !== "cash" ? 1 : parseFloat(orderData.pieces);
    let val = parseFloat(
      parseFloat(orderData.service_charge) +
        parseFloat(orderData.actual_product_amount) * p
    ).toFixed(2);
    return val;
  };

  const onClickOrderEditHandler = (e, _id) => {
    const obj = {
      ...data.filter((order) => order._id === _id)[0],
    };
    setOrderData({
      ...orderData,
      actual_product_amount: obj.actualProductAmount,
      payment_type: obj.paymentType,
      remark: obj.remark,
      service_charge: obj.deliveryTypeAmount,
      id: _id,
      pieces: obj.numberOfPieces,
    });
    setEditOrder(true);
  };

  const changeHandler = (e) => {
    setOrderData({
      ...orderData,
      [e.target.name]: e.target.value,
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 50,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderName",
      headerName: "Sender Name",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderCity",
      headerName: "Sender City",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pickupDate",
      headerName: "Pickup Date",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isPaymentSuccess",
      headerName: "Payment Status",
      renderCell: (cellValues) => {
        return (
          <Chip
            label={cellValues.row.isPaymentSuccess ? "Done" : "Pending"}
            color={cellValues.row.isPaymentSuccess ? "success" : "warning"}
            variant='outlined'
          />
        );
      },
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "orderStatus",
      renderCell: (cellValues) => (
        cellValues.row.cancel ? 
        <Chip label={cellValues.row.orderStatus} color='error' /> : cellValues.row.deliverComplete ? <Chip label={cellValues.row.orderStatus} color='success' /> :
        <Chip label={cellValues.row.orderStatus} color='primary' />
      ),
      headerName: "Order Status",
      width: 250,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      align: "center",
      width: 150,
      minWidth: 0,
      headerAlign: "center",
    },
    {
      field: "rider",
      renderCell: (cellValues) => {
        return (
          <div className={styles.select}>
            <select
              defaultValue={cellValues.row.rider ? cellValues.row.rider : ""}
              onChange={(e) => selectedRiderHandler(e, cellValues.row._id)}>
              <option value={""}>Select Rider</option>
              {riders?.map((rider) => {
                if (rider.riderAllocatedCity.includes(cellValues.row.senderCity)) {
                  return <option value={rider._id}>{rider.riderName}</option>;
                }
              })}
            </select>
          </div>
        );
      },
      headerName: "Assign Deliver Rider",
      width: 250,
      minWidth: 0,
      headerAlign: "center",
    },
    {
      field: "Accept Order",
      renderCell: acceptOrder,
      align: "center",
      headerAlign: "center",
      width: 100,
      minWidth: 0,
    },
    {
      field: "Action",
      renderCell: (cellValues) => (
        <EditIcon
          className={styles.action}
          onClick={(e) => onClickOrderEditHandler(e, cellValues.row._id)}
        />
      ),
      align: "center",
      headerAlign: "center",
      width: 100,
      minWidth: 0,
    },
  ];

  const col = [
    {
      field: "id",
      headerName: "S.No",
      width: 50,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientName",
      headerName: "Recipient Name",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientCity",
      headerName: "Recipient City",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pickupDate",
      headerName: "Pickup Date",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isPaymentSuccess",
      headerName: "Payment Status",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "orderStatus",
      renderCell: (cellValues) => (
        cellValues.row.cancel ? 
        <Chip label={cellValues.row.orderStatus} color='error' /> : cellValues.row.deliverComplete ? <Chip label={cellValues.row.orderStatus} color='success' /> :
        <Chip label={cellValues.row.orderStatus} color='primary' />
      ),
      headerName: "Order Status",
      width: 250,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      align: "center",
      width: 150,
      minWidth: 0,
      headerAlign: "center",
    },
    {
      field: "rider1",
      renderCell: (cellValues) => {
        return (
          <div className={styles.select}>
            <select
              defaultValue={cellValues.row.rider ? cellValues.row.rider : ""}
              onChange={(e) => selectedRiderHandler(e, cellValues.row._id)}>
              <option value={""}>Select Rider</option>
              {riders?.filter(r => r._id !== '657d70dd4f18868d94a28731')?.map((rider) => {
                if (rider.riderAllocatedCity.includes(cellValues.row.recipientCity)) {
                  return <option value={rider._id}>{rider.riderName}</option>;
                }
              })}
            </select>
          </div>
        );
      },
      headerName: "Assign Deliver Rider",
      width: 250,
      minWidth: 0,
      headerAlign: "center",
    },
    {
      field: "Invoice Status",
      renderCell: invoiceStatus,
      align: "center",
      headerAlign: "center",
      width: 200,
      minWidth: 0,
    },
    {
      field: "Payment Collected From Rider",
      renderCell: paymentCollectedFromRider,
      align: "center",
      headerAlign: "center",
      width: 200,
      minWidth: 0,
    },
    {
      field: "Payment Settle",
      renderCell: paymentSettle,
      align: "center",
      headerAlign: "center",
      width: 200,
      minWidth: 0,
    },
  ];

  const saveHandler = async (e) => {
    const config = {
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/assign_riders_and_accept_orders`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        acceptIds: saveData.acceptedOrders,
        assignIds: saveData.assignRiders,
        type: deliveryOrPickup,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      if (result.data.code) {
        getOrderDetails();
      } else {
        setLoading(false);
      }
      setSaveData({
        acceptedOrders: [],
        assignRiders: [],
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const deliveryOrPickupHandler = (e) => {
    setSaveData({
      acceptedOrders: [],
      assignRiders: [],
    });
    setDeliveryOrPickup(!deliveryOrPickup);
  };

  const getOrderDetailsDateFilter = async (dates) => {
    const config = {
      url:
        process.env.REACT_APP_BASE_URL + `/order/get_all_orders_by_date_range`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        start_date: dates[0],
        end_date: dates[1],
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data);
      if (result.data.code) {
        let payment_settled = 0;
        let payment_pending = 0;
        let payment_revenue = 0;
        let payment_collected = 0;
        result.data.data.map((order) => {
          if (order.paymentType === "cash") {
            if (
              order.isPaymentCollected &&
              order.isPaymentCollectedFromRider &&
              order.isPaymentSettled === false
            ) {
              payment_collected = payment_collected + order.totalAmount;
            }

            if (
              order.isPaymentCollected === true &&
              order.isPaymentCollectedFromRider === false &&
              order.isPaymentSettled === false
            ) {
              payment_pending = payment_pending + order.totalAmount;
            }

            if (
              order.isPaymentSettled === false &&
              order.isPaymentCollectedFromRider === true
            ) {
              payment_settled =
                payment_settled +
                (order.totalAmount - order.deliveryTypeAmount);
            }
          }

          if (order.isPaymentSettled === true) {
            payment_revenue = payment_revenue + order.deliveryTypeAmount;
          }
        });

        setPaymentReceived(payment_collected);
        setPaymentPending(payment_pending);
        setPaymentSettled(payment_settled);
        setPaymentRevenue(payment_revenue);
        generateRows(result.data.data);
        generateRows1(result.data.data);
        setData([...result.data.data]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onChangeRangeHandler = (dates) => {
    const formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));

    if (formattedDates.length === 2) {
      getOrderDetailsDateFilter(formattedDates);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Admin Dashboard</h2>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }} className={styles.range}>
          <div style={{ display: "flex", flexDirection:'column',gap:'0.3rem'}}>
            <label style={{fontWeight:'500'}}>Select start And end Date</label>
            <RangePicker style={{border:'1px solid black'}} onChange={onChangeRangeHandler} />
          </div>
        </div>
        <div className={styles.boxes}>
          <Box
            flag={true ? "red" : "green"}
            title={"Payment Pending"}
            sup={"Rider"}
            amount={paymentPending}
          />
          <Box
            flag={"green"}
            title={"Payment Collected"}
            amount={paymentReceived}
          />
          <Box
            flag={"red"}
            title={"Payment Due"}
            sup={"Client"}
            amount={paymentSettled}
          />
          <Box flag={"green"} title={"Total Revenue"} amount={paymentRevenue} />
        </div>
        <div className={styles.filter}>
          <div className={styles.select}>
            <select name='filter' onChange={filterHandler}>
              <option value=''>Select Order Status</option>
              <option value='all'>All</option>
              <option value='Order Placed'>Order Placed</option>
              <option value='Order Accepted'>Order Accepted</option>
              <option value='Package Pickup Scheduled'>
                Package Pickup Scheduled
              </option>
              <option value='Package Picked'>Package Picked</option>
              <option value='Out for Delivery'>Out for Delivery</option>
              <option value='Scheduled for Redelivery'>
                Scheduled for Redelivery
              </option>
              <option value='Delivered'>Delivered</option>
            </select>
          </div>
          <div className={styles.toggle}>
            <span className={`${styles.switcher} ${styles.switcher1}`}>
              <input
                value={deliveryOrPickup}
                onChange={deliveryOrPickupHandler}
                type='checkbox'
                id='switcher-1'
              />
              <label htmlFor='switcher-1'></label>
            </span>
            <div>
              <Button
                text={"Save"}
                onClickHandler={saveHandler}
                style={{
                  background: "#122333",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0.7rem 2rem",
                }}
              />
            </div>
          </div>
        </div>
        {!deliveryOrPickup ? (
          <div className={styles.dataTable}>
            <DataGrid
              style={{ backgroundColor: "#fff" }}
              rows={rows}
              columns={columns}
              disableRowSelectionOnClick
              autoHeight
              localeText={{ noRowsLabel: "Data not found" }}
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          </div>
        ) : (
          <div className={styles.dataTable}>
            <DataGrid
              style={{ backgroundColor: "#fff" }}
              rows={rows1}
              columns={col}
              disableRowSelectionOnClick
              autoHeight
              localeText={{ noRowsLabel: "Data not found" }}
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
            />
          </div>
        )}
      </div>
      {editOrder && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setEditOrder(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Edit Order</h1>
            </div>
            <div className={styles.inputFields}>
              <div className={styles.showInfo}>
                <div className={styles.contentInfo}>
                  <InputField
                    type='number'
                    styles={{
                      background:
                        orderData.payment_type !== "cash" && "#8080802e",
                    }}
                    disabled={orderData.payment_type !== "cash"}
                    onChangeHandler={changeHandler}
                    label='Actual Product Amount'
                    name='actual_product_amount'
                    value={orderData.actual_product_amount}
                  />
                  <InputField
                    type='number'
                    onChangeHandler={changeHandler}
                    label='Service Charge'
                    name='service_charge'
                    value={orderData.service_charge}
                  />
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Remark'
                    name='remark'
                    value={orderData.remark}
                  />
                </div>
              </div>
              <div className={styles.saveBtn}>
                <Button
                  text={"Save"}
                  onClickHandler={orderEditSaveHandler}
                  style={{
                    background: "#122333",
                    color: "#fff",
                    border: "none",
                    borderRadius: "10px",
                    padding: "0.7rem 2rem",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </>
  );
}

export default Dashboard;
