import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

if(process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT === 'Production'){
  console.log = () => {}
}

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode> 
);


