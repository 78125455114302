/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import MailIcon from "@mui/icons-material/Mail";
import Person3Icon from "@mui/icons-material/Person3";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../redux/slices/admin";
import InputField from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

function Profile() {
  const [editShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { admin, token } = useSelector((states) => states.admin);
  const [cityId, setCityId] = useState("");
  const [editCityData, setEditCityData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const logoutHandler = (e) => {
    dispatch(logout());
    navigate("/admin");
  };
  const [resetPassword, setResetPassword] = useState(false);

  const [resetData, setResetData] = useState({
    cur_pass: "",
    new_pass: "",
    conf_pass: "",
  });
  const generateRows = (array) => {
    let data = [];
    for (let i = 0; i < array.length; i++) {
      data.push({
        id: i + 1,
        city: array[i].city,
        sameDayDelivery:
          array[i].sameDayDelivery === -1 ? "N/A" : array[i].sameDayDelivery,
        nextDayDelivery:
          array[i].nextDayDelivery === -1 ? "N/A" : array[i].nextDayDelivery,
        urgentDelivery:
          array[i].urgentDelivery === -1 ? "N/A" : array[i].urgentDelivery,
        pickupAvailability: array[i].pickupAvailability,
        _id: array[i]._id,
      });
    }
    setRows([...data]);
  };

  const getAvailability = async () => {
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/get`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios(config);
    generateRows(result.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getAvailability();
  }, []);

  const handleDelete = async (e, values) => {
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/remove_availability`,
      method: "delete",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: values.row._id,
      },
    };
    try {
      const result = await axios(config);
      console.log(result);
      getAvailability();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleShowEdit = async (e, values) => {
    setEditShow(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/get_availability_by_id/${values}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data);
      setEditCityData({
        city_name:result.data.data.city.city,
        next_day_delivery:result.data.data.city.nextDayDelivery,
        urgent_delivery : result.data.data.city.urgentDelivery,
        same_day_delivery:result.data.data.city.sameDayDelivery,
        pickup_availability : result.data.data.city.pickupAvailability === 'Available' ? true : false
      });
      setCityId(result.data.data.city._id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const columns = [
    { field: "id", headerName: "S.No", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    { field: "sameDayDelivery", headerName: "Same Day Delivery", flex: 1 },
    { field: "nextDayDelivery", headerName: "Next Day Delivery", flex: 1 },
    { field: "urgentDelivery", headerName: "Urgent Delivery", flex: 1 },
    { field: "pickupAvailability", headerName: "Pickup Availability", flex: 1 },
    {
      field: "Action",
      renderCell: (cellValues) => {
        return (
          <div className={styles.wrapperAction}>
            <span>
              <EditIcon
                onClick={(e) => {
                  handleShowEdit(e, cellValues.row._id);
                }}
                className={styles.action}
              />
            </span>
            <span>
              <DeleteIcon
                onClick={(e) => {
                  handleDelete(e, cellValues);
                }}
                className={styles.action}
              />
            </span>
          </div>
        );
      },
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
  ];

  const [availableData, setAvailableData] = useState({
    city_name: "",
    next_day_delivery: "",
    same_day_delivery: "",
    urgent_delivery: "",
    pickup_availability: "",
    sdd: false,
    ndd: false,
    ud: false,
    pa: false,
  });

  const addAvailability = async (e) => {
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/add_availability`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        city: availableData.city_name,
        sameDayDelivery: availableData.sdd
          ? availableData.same_day_delivery
          : -1,
        nextDayDelivery: availableData.ndd
          ? availableData.next_day_delivery
          : -1,
        urgentDelivery: availableData.ud ? availableData.urgent_delivery : -1,
        pickupAvailability: availableData.pickup_availability,
      },
    };
    try {
      const result = await axios(config);
      console.log(result.data);
      getAvailability();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const changeCityHandler = (e) =>{
    if (e.target.name === "pa") {
      if (e.target.checked) {
        setEditCityData({
          ...editCityData,
          pickup_availability: "Available",
        });
      } else {
        setEditCityData({
          ...editCityData,
          pickup_availability: "Unavailable",
        });
      }
      return;
    }

    setEditCityData({
      ...editCityData,
      [e.target.name]: e.target.value,
    });
  }

  const changeHandler = (e) => {
    if (e.target.name === "sdd") {
      setAvailableData({
        ...availableData,
        sdd: e.target.checked,
      });
      return;
    }

    if (e.target.name === "ndd") {
      setAvailableData({
        ...availableData,
        ndd: e.target.checked,
      });
      return;
    }

    if (e.target.name === "ud") {
      setAvailableData({
        ...availableData,
        ud: e.target.checked,
      });
      return;
    }

    if (e.target.name === "pa") {
      if (e.target.checked) {
        setAvailableData({
          ...availableData,
          pickup_availability: "Available",
        });
      } else {
        setAvailableData({
          ...availableData,
          pickup_availability: "Unavailable",
        });
      }
      return;
    }

    setAvailableData({
      ...availableData,
      [e.target.name]: e.target.value,
    });
  };

  const changeHandlerReset = (e) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
  };

  const resetPassHandler = async (e) => {
    if (
      resetData.cur_pass === "" ||
      resetData.new_pass === "" ||
      resetData.conf_pass === ""
    ) {
      toast.error("All fields are required.");
      return;
    }

    if (resetData.new_pass !== resetData.conf_pass) {
      toast.error("New password and Confirm password not matched.");
      return;
    }
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/admin/reset_password`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: admin._id,
        cur_pass: resetData.cur_pass,
        new_pass: resetData.new_pass,
      },
    };

    try {
      const result = await axios(config);
      if (result.data.code === 1) {
        toast.success(result.data.message);
        setResetData({
          cur_pass: "",
          new_pass: "",
          conf_pass: "",
        });
        setResetPassword(false);
      } else {
        toast.error(result.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const editCityHandler = async (e) => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/update_city`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: cityId,
        sameDayDelivery:editCityData.same_day_delivery,
        nextDayDelivery:editCityData.next_day_delivery,
        urgentDelivery:editCityData.urgent_delivery,
        pickupAvailability:editCityData.pickup_availability
      },
    };
    setLoading(true);
    try {
      //eslint-disable-next-line no-unused-vars
      const result = await axios(config);
      setEditShow(false);
      setLoading(false);
      getAvailability();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.about}>
            <h1>About Me</h1>
          </div>
          <div className={styles.searchSec}>
            <div className={styles.icons}>
              <a href='https://api.whatsapp.com/send/?phone=971509769826&text&type=phone_number&app_absent=0'>
                <WhatsAppIcon />
              </a>
              <a href='mailto:inderjit.singh18112000@gmail.com'>
                <MailIcon />
              </a>
            </div>
            <div className={styles.logout}>
              <div>
                <p>
                  Settings <ExpandMoreOutlinedIcon />
                </p>
              </div>
              <div className={styles.option}>
                <div className={styles.wrap}>
                  <Link to='#' onClick={(e) => setResetPassword(true)}>
                    {" "}
                    Reset Password
                  </Link>
                  <Link to='/admin' onClick={logoutHandler}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.leftSec}>
            <div>
              <h1>Welcome, {admin.username}</h1>
              <div className={styles.img}>
                <img
                  src='https://cdn2.iconfinder.com/data/icons/audio-16/96/user_avatar_profile_login_button_account_member-512.png'
                  alt='avatar'
                />
              </div>
              <div className={styles.desc}>
                <p>
                  <MailIcon /> {admin.emailId}
                </p>
                <p>
                  <PhoneInTalkIcon />{admin.phoneNumber}
                </p>
                <p style={{textTransform:'capitalize'}}>
                  <Person3Icon /> {admin.role}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightSec}>
            <div className={styles.organization}>
              <h1>Add More Cities</h1>
              <div className={styles.inputField}>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='City Name'
                    name='city_name'
                    value={availableData.city_name}
                  />
                </div>
                <div className={styles.amount}>
                  <div>
                    <div className={styles.availAmount}>
                      <input
                        id='sdd'
                        name='sdd'
                        onChange={changeHandler}
                        type='checkbox'
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor='sdd'>Same Day Delivery</label>
                    </div>
                  </div>
                  {availableData.sdd && (
                    <div>
                      <InputField
                        type='number'
                        onChangeHandler={changeHandler}
                        label='Same Day Delivery'
                        name='same_day_delivery'
                        value={availableData.same_day_delivery}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.amount}>
                  <div>
                    <div className={styles.availAmount}>
                      <input
                        id='ndd'
                        name='ndd'
                        onChange={changeHandler}
                        type='checkbox'
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor='ndd'>Next Day Delivery</label>
                    </div>
                  </div>
                  {availableData.ndd && (
                    <div>
                      <InputField
                        type='number'
                        onChangeHandler={changeHandler}
                        label='Next Day Delivery'
                        name='next_day_delivery'
                        value={availableData.next_day_delivery}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.amount}>
                  <div>
                    <div className={styles.availAmount}>
                      <input
                        id='ud'
                        name='ud'
                        onChange={changeHandler}
                        type='checkbox'
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor='ud'>Urgent Delivery</label>
                    </div>
                  </div>
                  {availableData.ud && (
                    <div>
                      <InputField
                        type='number'
                        onChangeHandler={changeHandler}
                        label='Urgent Delivery'
                        name='urgent_delivery'
                        value={availableData.urgent_delivery}
                      />
                    </div>
                  )}
                </div>
                <div className={`${styles.amount} ${styles.col}`}>
                  <div>
                    <div className={styles.availAmount}>
                      <input
                        id='pa'
                        name='pa'
                        onChange={changeHandler}
                        type='checkbox'
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor='pa'>Pickup Availability</label>
                    </div>
                  </div>
                  <div>
                    <Button
                      text={"Add City"}
                      onClickHandler={addAvailability}
                      style={{
                        background: "#122333",
                        color: "#fff",
                        border: "none",
                        borderRadius: "10px",
                        padding: "0.7rem 2rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataTable}>
          <DataGrid
            style={{ backgroundColor: "#fff" }}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            autoHeight
            localeText={{ noRowsLabel: "Data not found" }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      </div>
      {resetPassword && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setResetPassword(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Reset Password</h1>
            </div>
            <div className={styles.inputFields}>
              <div className={styles.inputs}>
                <InputField
                  type='text'
                  onChangeHandler={changeHandlerReset}
                  label='Current Password'
                  name='cur_pass'
                  value={resetData.cur_pass}
                />
                <InputField
                  type='text'
                  onChangeHandler={changeHandlerReset}
                  label='New Password'
                  name='new_pass'
                  value={resetData.new_pass}
                />
                <InputField
                  type='text'
                  onChangeHandler={changeHandlerReset}
                  label='Confirm Password'
                  name='conf_pass'
                  value={resetData.conf_pass}
                />
              </div>
            </div>
            <div className={styles.saveBtn}>
              <Button
                text={"Reset"}
                onClickHandler={resetPassHandler}
                style={{
                  background: "#122333",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0.7rem 2rem",
                }}
              />
            </div>
          </div>
        </div>
      )}
        {editShow && (
        <div className={styles.loginWrapper}>
          <div
            className={styles.editWrapper}
            style={{ maxHeight: "500px", overflowY: "auto" }}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setEditShow(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Rider Edit</h1>
            </div>
            <div className={styles.inputFields}>
              <div className={styles.fields}>
                <div>
                <InputField
                    type='text'
                    onChangeHandler={changeCityHandler}
                    label='City Name'
                    name='city_name'
                    value={editCityData.city_name}
                  />
                </div>
                <div>
                <InputField
                        type='number'
                        onChangeHandler={changeCityHandler}
                        label='Same Day Delivery'
                        name='same_day_delivery'
                        value={editCityData.same_day_delivery}
                      />
                  <InputField
                        type='number'
                        onChangeHandler={changeCityHandler}
                        label='Next Day Delivery'
                        name='next_day_delivery'
                        value={editCityData.next_day_delivery}
                      />
                <InputField
                        type='number'
                        onChangeHandler={changeCityHandler}
                        label='Urgent Delivery'
                        name='urgent_delivery'
                        value={editCityData.urgent_delivery}
                      />
                </div>
                <div>
                    <div className={styles.availAmount}>
                      <input
                        id='pa'
                        name='pa'
                        onChange={changeCityHandler}
                        checked={editCityData.pickup_availability}
                        type='checkbox'
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor='pa'>Pickup Availability</label>
                    </div>
                  </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}>
                  <Button
                    text={"Update"}
                    onClickHandler={editCityHandler}
                    style={{
                      background: "#122333",
                      color: "#fff",
                      border: "none",
                      borderRadius: "10px",
                      padding: "0.7rem 2rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </>
  );
}

export default Profile;
