import React from 'react'
import styles from "./Button.module.css"

function Button({ text, style, onClickHandler, isDark = false,isDisable }) {
  style = style !== undefined ? style : {}
  return (
    <div className={!isDark ? styles.orderOnline : styles.orderOnlineDark}>
      <button
        style={style}
        onClick={onClickHandler}
        type='button'
        disabled = {isDisable}
      >
        {text !== undefined ? text : 'Button'}
      </button>
    </div>
  )
}

export default Button