import React, { useRef, useState } from "react";
import styles from "./UploadOrder.module.css";
import Button from "../../../components/Button/Button";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import Loader from "../../../components/Loader/Loader";
import Excel from "exceljs";
import { changeDateFormat } from "../../../utils/index";
import { toast } from "react-toastify";
import File from "../../../test-sample.xlsx";
import CheckoutForm from "../../../stripe/CheckoutForm";
import { useSelector } from "react-redux";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function UploadOrder({ setNav }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const hiddenFile = useRef(null);
  const hiddenLink = useRef(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [file, setFile] = useState();
  const [errorExcel, setErrorExcel] = useState("");
  const { client, token } = useSelector((states) => states.client);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [orderData, setOrderData] = useState({
    onlinePayment: false,
    payeeName: "",
    payeeEmail: "",
    payment_type: "",
  });
  const DB_HEADERS = {
    "Sender Name": "senderName",
    "Sender Mobile": "senderMobile",
    "Sender City": "senderCity",
    "Sender Address": "senderAddress",
    "Sender Email": "senderEmail",
    "Pickup On": "pickUpDate",
    "Recipient Name": "recipientName",
    "Recipient Email": "recipientEmail",
    "Recipient Mobile": "recipientMobile",
    "Recipient Country": "recipientCountry",
    "Recipient City": "recipientCity",
    "Recipient Address": "recipientFullAddress",
    "Sender Latitude": "senderLatitude",
    "Sender Longitude": "senderLongitude",
    "Recipient Latitude": "recipientLatitude",
    "Recipient Longitude": "recipientLongitude",
    "Delivery Type": "deliveryType",
    "Package Type": "packageType",
    "Instructions": "instructions",
    "Description": "description",
    "No of pieces": "numberOfPieces",
    "Actual Weight(in kg)": "actualWeight",
    "Actual Product Amount": "actualProductAmount",
    "Package Length (in cm)": "length",
    "Package Width (in cm)": "width",
    "Package Height (in cm)": "height",
  };

  const excelHeaders = [...Object.keys(DB_HEADERS)];

  const fileDownload = (e) => {
    hiddenLink.current.click();
  };

  const fileHandler = async (e) => {
    if (e.target.files.length !== 0) {
      setFile(e.target.files[0]);
      setErrorExcel("")
      setOrderData({
        onlinePayment: false,
        payeeName: "",
        payeeEmail: "",
        payment_type: "",
      })
      setLoading(true);
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(e.target.files[0]);
      const worksheet = workbook.getWorksheet(1);
      const excelData = [];
      const headers = [];
      var flag = false;
      var columCount = 0;
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) {
          row.eachCell((cell, colNumber) => {
            if (!excelHeaders.includes(cell.value.trim())) {
              flag = true;
            }
            headers.push(cell.value.trim());
            columCount = colNumber;
          });
        } else {
          let data = {};
          row.eachCell((cell, colNumber) => {
            data[DB_HEADERS[headers[colNumber - 1]]] = cell.value;
          });
          excelData.push(data);
        }
      });
      if (flag || excelHeaders.length !== columCount) {
        toast.error("No. of columns or Header mismatch");
        setRows([]);
        setLoading(false);
        return;
      } else {
        generateTableRows(excelData);
      }
    }
  };

  const generateTableRows = (array) => {
    let data = [];
    for (let i = 0; i < array.length; i++) {
      console.log(array[i]);
      data.push({
        id: i + 1,
        senderName: array[i].senderName,
        senderMobile: array[i].senderMobile,
        senderAddress: array[i].senderAddress,
        senderCity: array[i].senderCity,
        pickupDate: changeDateFormat(array[i].pickUpDate),
        recipientName: array[i].recipientName,
        recipientEmail: array[i].recipientEmail.text,
        senderEmail: array[i].senderEmail.text,
        recipientMobile: array[i].recipientMobile,
        recipientCountry: array[i].recipientCountry,
        recipientCity: array[i].recipientCity,
        recipientFullAddress: array[i].recipientFullAddress,
        senderLatitude: array[i].senderLatitude,
        senderLongitude: array[i].senderLongitude,
        recipientLatitude: array[i].recipientLatitude,
        recipientLongitude: array[i].recipientLongitude,
        deliveryType: array[i].deliveryType,
        instructions: array[i].instructions,
        description: array[i].description,
        packageType: array[i].packageType,
        numberOfPieces: array[i].numberOfPieces,
        actualWeight: array[i].actualWeight,
        actualProductAmount: array[i].actualProductAmount,
        length: array[i].length,
        width: array[i].width,
        height: array[i].height,
      });
    }
    console.log(data);
    setRows([...data]);
    setLoading(false);
  };

  const clickHandler = (e) => {
    hiddenFile.current.click();
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 50,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderName",
      headerName: "Sender Name",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderMobile",
      headerName: "Sender Mobile",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderAddress",
      headerName: "Sender Address",
      width: 200,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderCity",
      headerName: "Sender City",
      width: 130,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderEmail",
      headerName: "Sender Email",
      width: 180,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pickupDate",
      headerName: "Pickup On",
      width: 130,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientName",
      headerName: "Recipient Name",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientEmail",
      headerName: "Recipient Email",
      width: 180,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientMobile",
      headerName: "Recipient Mobile",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientCountry",
      headerName: "Recipient Country",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientCity",
      headerName: "Recipient City",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientFullAddress",
      headerName: "Recipient Address",
      width: 200,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderLatitude",
      headerName: "Sender Latitude",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "senderLongitude",
      headerName: "Sender Longitude",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientLatitude",
      headerName: "Recipient Latitude",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "recipientLongitude",
      headerName: "Recipient Longitude",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "deliveryType",
      headerName: "Delivery Type",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "instructions",
      headerName: "Instructions",
      width: 180,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      width: 180,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "packageType",
      headerName: "Package Type",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "numberOfPieces",
      headerName: "Number Of Pieces",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actualWeight",
      headerName: "Actual Weight(in kg)",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actualProductAmount",
      headerName: "Actual Product Amount",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "length",
      headerName: "Package Length (in cm)",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "width",
      headerName: "Package Width (in cm)",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "height",
      headerName: "Package Height (in cm)",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
  ];

  const cardCompleteHandler = (complete) => {
    setCardComplete(complete);
  };

  const changeHandler = (e) => {
    if (e.target.name === "payment_type" && e.target.value !== "") {
      setOrderData({
        ...orderData,
        payment_type: e.target.value,
        onlinePayment: e.target.value === "prepaid" ? true : false,
      });
      return;
    }

    setOrderData({
      ...orderData,
      [e.target.name]: e.target.value,
    });
  };

  const bulkPlaceOrder = async (e) => {
    if (!file) {
      toast.error("Please Upload file before submitting");
      return;
    }
    const form = new FormData();
    form.append("paymentType", orderData.payment_type);
    form.append("file", file);

    const config = {
      url:
        process.env.REACT_APP_BASE_URL +
        `/order/bulk_upload_orders/${client._id}`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      responseType : 'blob',
      data: form,
    };

    if (orderData.onlinePayment) {
      if (!stripe || !elements) {
        return;
      }

      if (cardComplete) {
        setProcessing(true);
      } else {
        return;
      }
      setLoading(true);
      stripe.createToken(elements.getElement(CardElement)).then(async (tok) => {
        console.log(tok.token);
        form.append("token", JSON.stringify(tok.token));
        form.append("payeeName", orderData.payeeName);
        form.append("payeeEmail", orderData.payeeEmail);
        try {
          const result = await axios(config);
          setLoading(false);
          if (result.data.code) {
            setNav(0);
          }
          const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = URL.createObjectURL(blob);
          setErrorExcel(url)
        } catch (err) {
          setLoading(false);
        }
      });
    } else {
      setLoading(true);
      try {
        const result = await axios(config);
        setLoading(false);
        if (result.data.code) {
          setNav(0);
          return
        }
        const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        setErrorExcel(url)
      } catch (err) {
        console.log(err)
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.uploadButton}>
          <div>
            <Button
              text={"upload data"}
              onClickHandler={clickHandler}
              style={{
                background: "#122333",
                color: "#fff",
                border: "none",
                borderRadius: "10px",
                padding: "0.7rem 2rem",
                whiteSpace: "nowrap",
              }}
            />
            <input
              style={{ display: "none" }}
              type='file'
              onChange={fileHandler}
              ref={hiddenFile}
              name='bulUpload'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            />
          </div>

          <Button
            text={"download sample"}
            onClickHandler={fileDownload}
            style={{
              background: "#122333",
              color: "#fff",
              border: "none",
              borderRadius: "10px",
              padding: "0.7rem 2rem",
            }}
          />
          <a
            ref={hiddenLink}
            style={{ display: "none" }}
            href={File}
            download={"Sample-Upload-File"}>
            Hide
          </a>
        </div>
        <div>
          {file?.name && <p className={styles.fileMessage}>{file.name}</p>}
        </div>
        <div className={styles.dataTable}>
          <DataGrid
            style={{ backgroundColor: "#fff" }}
            rows={rows}
            columns={columns}
            autoHeight
            localeText={{ noRowsLabel: "Uploaded your data" }}
            slots={{
              toolbar: GridToolbar,
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
        <div className={styles.select}>
          <select
            onChange={changeHandler}
            name='payment_type'
            value={orderData.payment_type}>
            <option>Choose Payment Type</option>
            <option value={"cash"}>Cash on Delivery</option>
            <option value={"prepaid"}>Prepaid</option>
          </select>
        </div>
        {orderData.onlinePayment && (
          <div>
            <CheckoutForm
              oneCol={true}
              elements={elements}
              complete={cardCompleteHandler}
              changeHandler={changeHandler}
              payeeName={orderData.payeeName}
              payeeEmail={orderData.payeeEmail}
            />
          </div>
        )}
        <div className={styles.uploadBtn}>
          <Button
            text={"Place Order"}
            onClickHandler={bulkPlaceOrder}
            style={{
              background: "#122333",
              color: "#fff",
              border: "none",
              borderRadius: "10px",
              padding: "0.7rem 2rem",
            }}
          />
          {
            errorExcel !== "" &&
            <div>
              <a href={errorExcel} download='Skip Record Error'>DownLoad Error File</a>
            </div>
          }
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
}

export default UploadOrder;
