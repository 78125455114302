import React, { useEffect, useState } from "react";
import styles from "./QRCodeScan.module.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { order_status } from "../../utils/index";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function QRCodeScan() {
  const { id, token } = useSelector((states) => states.rider);
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});
  const getDetails = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/order/get_single_order`,
      method: "post",
      data: {
        id: params.get("info"),
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      if (result.data.code) {
        setOrder(result.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);

  const paymentStatusChange = async (e, _id, status) => {
    if (e.target.checked) {
      setLoading(true);
      const config = {
        url: process.env.REACT_APP_BASE_URL + `/rider/update_payment_status`,
        method: "post",
        headers: {
          secret_key: process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: _id,
          status: status,
        },
      };
      const stat = await axios(config);
      if (stat.data.code === 1) {
        toast.success(stat.data.message);
      }
      setLoading(false);
    }
  };

  const orderStatusChange = async (e, _id, status) => {
    // if (e.target.checked) {
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/update_order_status`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: _id,
        status: status,
      },
    };
    const stat = await axios(config);
    if (stat.data.code === 1) {
      toast.success(stat.data.message);
    }
    setLoading(false);
    // }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.status}>
            <h2>Order Status</h2>
          </div>
          <div className={styles.steps}>
            <Stepper orientation='vertical'>
              {order_status.map((step, index) => {
                if (order?.orderStatus?.includes(step)) {
                  return (
                    <Step key={step} active={true} completed>
                      <StepLabel>{step}</StepLabel>
                    </Step>
                  );
                } else {
                  return (
                    <Step key={step.label}>
                      <StepLabel>{step}</StepLabel>
                    </Step>
                  );
                }
              })}
            </Stepper>
          </div>
        </div>
          {!id && !token ? (
            <div className={styles.forgotPassword}>
              <Link to={"/rider"} target="_blank">Are you rider?</Link>
            </div>
          ) : (
            <>
              {!loading && (
                <div className={styles.data}>
                  <div className={styles.otpSec}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                      }}>
                      <input
                        onChange={(e) =>
                          paymentStatusChange(e, order?._id, true)
                        }
                        checked={order?.isPaymentSuccess}
                        disabled={order?.isPaymentSuccess}
                        id='amt'
                        type='checkbox'
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor='amt'>
                        Collected Amount{" "}
                        <strong>${order?.totalAmount} AED</strong>
                      </label>
                    </div>
                  </div>
                  <div className={styles.select}>
                    <select
                      onChange={(e) =>
                        orderStatusChange(e, order?._id, e.target.value)
                      }
                      disabled={order?.orderStatus &&
                        order?.orderStatus[order?.orderStatus?.length - 1]  === 'Delivered' }
                      value={
                        order?.orderStatus &&
                        order?.orderStatus[order?.orderStatus?.length - 1]
                      }>
                      <option value=''>Select Status</option>
                      <option value='Delivered'>Delivered</option>
                      <option value='No Answer'>No Answer</option>
                      <option value='Reschedule Delivery'>
                        Reschedule Delivery
                      </option>
                      <option value='Canceled'>Canceled</option>
                    </select>
                  </div>
                </div>
              )}
            </>
          )}
      </div>
      <Loader loading={loading} />
    </>
  );
}

export default QRCodeScan;
