import React, { useState } from 'react'
import styles from "./Client.module.css"
import SideBar from '../../../components/Navbar/SideBar'
import Dashboard from './Dashboard'
import Upload from './Upload'
import Profile from './Profile'

function Client() {

    const [nav,setNav] = useState(0)

  return (
    <div className={styles.container}>
        <div className={styles.wrapper}>
            <div className={styles.sidebar}>
                <SideBar placeOrder={true} setNavHandler={setNav} navHandler={nav} secondTitle={"Place Order"}/>
            </div>
        </div>
        <div className={styles.content}>
            {
                nav === 0 &&
                <Dashboard />
            }
              {
                nav === 1 &&
                <Upload setNav={setNav}/>
            }
              {
                nav === 2 &&
                <Profile />
            }
        </div>
    </div>
  )
}

export default Client