import React from 'react'
import styles from "./SideBar.module.css"
import DashboardIcon from '@mui/icons-material/Dashboard';
import Person3Icon from '@mui/icons-material/Person3';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

function SideBar({setNavHandler,navHandler,placeOrder,secondTitle,wayBill,thirdTitle}) {

  return (
    <div className={styles.sidebar}>
        <div className={styles.logoWrapper}>
            <div className={styles.logo}>
                <img src='/img/logo.png' alt='logo'/>
            </div>
            <span className={styles.head}>Al Kanari Delivery Services</span>
        </div>
        <div className={styles.options}>
            <div className={styles.opt}>
                <DashboardIcon 
                    className={navHandler === 0 ? styles.active : ""}
                    onClick={(e) => setNavHandler(0)}
                />
                <span className={navHandler === 0 ? styles.active : ""} onClick={(e) => setNavHandler(0)}>Dashboard</span>
            </div>
            {
                placeOrder &&
                <div className={styles.opt}>
                    <DriveFolderUploadIcon
                        className={navHandler === 1 ? styles.active : ""}
                        onClick={(e) => setNavHandler(1)}
                    />
                    <span className={navHandler === 1 ? styles.active : ""} onClick={(e) => setNavHandler(1)}>{secondTitle}</span>
                </div>
            }
            {
                wayBill &&
                <div className={styles.opt}>
                    <AccountBalanceWalletIcon
                        className={navHandler === 3 ? styles.active : ""}
                        onClick={(e) => setNavHandler(3)}
                    />
                    <span className={navHandler === 3 ? styles.active : ""} onClick={(e) => setNavHandler(3)}>{thirdTitle}</span>
                </div>
            }
            <div className={styles.opt}>
                <Person3Icon 
                    className={navHandler === 2 ? styles.active : ""}
                    onClick={(e) => setNavHandler(2)}
                />
                <span className={navHandler === 2 ? styles.active : ""} onClick={(e) => setNavHandler(2)}>Profile</span>
            </div>
        </div>
    </div>
  )
}

export default SideBar