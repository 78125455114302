import React from 'react'
import { BrowserRouter,Route,Routes } from 'react-router-dom'
import {routes ,routesClient,routesAdmin,routesRider} from './routes'
import Layout from './components/Layout/Layout'
import { Provider } from 'react-redux'
import store from './redux/store'
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from "react-toastify";
import Invoice from './Pages/Invoice/Invoice';
import ForgotPassword from './Pages/forgotPassword/ForgotPassword';
import AdminProtected from './components/ProtectedRoutes/AdminProtected';
import AdminLogin from './Pages/Dashboards/Admin/AdminLogin';
import ClientProtected from './components/ProtectedRoutes/ClientProtected';
import RiderLogin from './Pages/Dashboards/Rider/RiderLogin';
import RiderProtected from './components/ProtectedRoutes/RiderProtected';
import QRCodeScan from './Pages/WayBill/QRCodeScan';
let persistor = persistStore(store);

const App = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route element={<Layout options={true} footer={true} header={true}/>}>
              {
                routes.map(({path,Component},index) => (
                  <Route key={index} path={path} element={<Component />}  />
                ))
              }
              </Route>
              <Route element={<Layout options={true} footer={false} header={false}/>}>
              {
                routesClient.map(({path,Component},index) => (
                  <Route key={index} path={path} element={<ClientProtected><Component /></ClientProtected>}  />
                ))
              }
              </Route>
              <Route element={<Layout footer={false} header={false}/>}>
              {
                routesAdmin.map(({path,Component},index) => (
                  <Route key={index} path={path} element={<AdminProtected><Component /></AdminProtected>} />
                ))
              }
                <Route path={"/admin"} element={<AdminLogin />} />
              </Route>
              <Route element={<Layout footer={false} header={false}/>}>
              {
                routesRider.map(({path,Component},index) => (
                  <Route key={index} path={path} element={<RiderProtected><Component /></RiderProtected>}  />
                ))
              }
                <Route path={"/rider"} element={<RiderLogin />} />

              </Route>
              <Route path='/invoice/:id/:token' element={<Invoice />} />
              <Route element={<Layout footer={true} header={true} options={false}/>}>        
                <Route path='/forgot_password/:id' element={<ForgotPassword />} />
                <Route path='/qr_scan' element={<QRCodeScan />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </>
  )
}

export default App