import React, { useState, useEffect } from "react";
import styles from "./RiderLogin.module.css";
import InputField from "../../../components/InputField/InputField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { riderAuth, addRider } from "../../../redux/slices/rider";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";

const RiderLogin = () => {
  const { id, token } = useSelector((states) => states.rider);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({});
  const [flagForgot, setFlagForgot] = useState(true);
  const [email, setEmail] = useState("");
  const changeHandler = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!id && !token) {
      return;
    }
    getUserDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  const getUserDetails = () => {
    dispatch(addRider({ id: id, token: token })).then((res) => {
      setLoading(false);
      navigate("/rider/dashboard");
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(riderAuth({ ...login }))
      .then((res) => {
        if (res.payload.code === 0) {
          setLoading(false);
          toast.error(res.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onSubmitResetHandler = async (e) => {
    if (email === "") {
      toast.error("Email is required");
      return;
    }

    const config = {
      url: process.env.REACT_APP_BASE_URL + `/generate_forgot_password_link`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: email,
        link_for: "rider",
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      if (result.data.code === 1) {
        toast.success(result.data.message);
        setEmail("");
      } else {
        toast.error(result.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <div className={styles.logo}>
              <img src='/img/logo.png' alt='logo' />
            </div>
            <div className={styles.message}>
              <h1>
                Welcome Rider!
              </h1>
            </div>
          </div>
          <div className={styles.right}>
            {flagForgot ? (
              <>
                <div className={styles.loginHead}>
                  <h2>Login</h2>
                </div>
                <div className={styles.fields}>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Email'
                    name='emailId'
                  />
                  <InputField
                    type='password'
                    onChangeHandler={changeHandler}
                    label='Password'
                    name='password'
                  />
                </div>
                <div className={styles.btn}>
                  <button onClick={onSubmitHandler}>Login </button>
                </div>
                <div className={styles.forgotPass}>
                  <button onClick={(e) => setFlagForgot(false)}>
                    forgot password ?
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.loginHead}>
                  <h2>Reset Password</h2>
                </div>
                <div className={styles.fields}>
                  <InputField
                    type='text'
                    onChangeHandler={(e) => setEmail(e.target.value)}
                    label='Enter Email here'
                    value={email}
                  />
                </div>
                <div className={styles.btn}>
                  <button onClick={onSubmitResetHandler}>Submit </button>
                </div>
                <div className={styles.forgotPass}>
                  <button onClick={(e) => setFlagForgot(true)}>
                    Back to Login ?
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
};

export default RiderLogin;
