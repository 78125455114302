import React from 'react'
import styles from "./Layout.module.css"
import NavBar from '../Navbar/NavBar'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer/Footer'

function Layout({footer,header,options}) {

  return (
    <div className={styles.layoutContainer}>
        {
            header && <NavBar options={options}/>
        }
        <Outlet />
        {
          footer && <Footer />
        }
    </div>
  )
}

export default Layout