import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../components/Loader/Loader";

function ForgotPassword() {
  const { id } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    new_pass: "",
    confirm_pass: "",
  });

  console.log(data);

  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = async (e) => {
    if (data.new_pass === "" || data.confirm_pass === "") {
      toast.error("Both Fields are required");
      return;
    }
    if (data.new_pass !== data.confirm_pass) {
      toast.error("New Password And Confirm Password not match");
      return;
    }

    const config = {
      url: process.env.REACT_APP_BASE_URL + `/update_forgot_password`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        code: id,
        password: data.new_pass,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data);
      if (result.data.code === 1) {
        if(result.data.data.linkFor === 'admin'){
            navigate('/admin')
        }else if(result.data.data.linkFor === 'client'){
            navigate('/')
        }else if(result.data.data.linkFor === 'rider'){
            navigate('/rider')
        }
      } else {
        toast.error(result.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1>Forgot Password</h1>
          <div className={styles.inputFields}>
            <InputField
              type='text'
              onChangeHandler={changeHandler}
              label='New Password'
              name='new_pass'
              value={data.new_pass}
            />
            <InputField
              type='text'
              onChangeHandler={changeHandler}
              label='Confirm Password'
              name='confirm_pass'
              value={data.confirm_pass}
            />
          </div>
          <div>
            <Button
              text={"Submit"}
              onClickHandler={submitHandler}
              style={{
                background: "#122333",
                color: "#fff",
                border: "none",
                borderRadius: "10px",
                padding: "0.7rem 2rem",
              }}
            />
          </div>
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
}

export default ForgotPassword;
