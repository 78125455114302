/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import styles from "./Profile.module.css"
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailIcon from '@mui/icons-material/Mail';
import Person3Icon from '@mui/icons-material/Person3';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {logout} from "../../../redux/slices/rider"
import { toast } from "react-toastify";
import Loader from '../../../components/Loader/Loader';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';

function Profile() {

  const {rider,token} = useSelector(states => states.rider)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logoutHandler = (e) =>{
    dispatch(logout())
    navigate("/rider")
  }
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const [resetData, setResetData] = useState({
    cur_pass: "",
    new_pass: "",
    conf_pass: "",
  });

  const changeHandlerReset = (e) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
  };

  const resetPassHandler = async (e) => {
    if (
      resetData.cur_pass === "" ||
      resetData.new_pass === "" ||
      resetData.conf_pass === ""
    ) {
      toast.error("All fields are required.");
      return;
    }

    if (resetData.new_pass !== resetData.conf_pass) {
      toast.error("New password and Confirm password not matched.");
      return;
    }
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/reset_password`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: rider._id,
        cur_pass: resetData.cur_pass,
        new_pass: resetData.new_pass,
      },
    };

    try {
      const result = await axios(config);
      if (result.data.code === 1) {
        toast.success(result.data.message);
        setResetData({
          cur_pass: "",
          new_pass: "",
          conf_pass: "",
        });
        setResetPassword(false);
      } else {
        toast.error(result.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.about}>
            <h1>About Me</h1>
          </div>
          <div className={styles.searchSec}>
            <div className={styles.icons}>
              <a href="https://api.whatsapp.com/send/?phone=971509769826&text&type=phone_number&app_absent=0"><WhatsAppIcon /></a>
              <a href='mailto:inderjit.singh18112000@gmail.com'>
                <MailIcon />
              </a>

            </div>
            <div className={styles.logout}>
              <div>
                <p>Settings <ExpandMoreOutlinedIcon /></p>
              </div>
              <div className={styles.option}>
                <div className={styles.wrap}>
                  <Link to='#' onClick={(e) => setResetPassword(true)}>
                    {" "}
                    Reset Password
                  </Link>
                  <Link to='/rider' onClick={logoutHandler}>Logout</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}> 
          <div className={styles.leftSec}>
            <div>
              <h1>Welcome, {rider.riderName}</h1>
              <div className={styles.img}>
                <img src='https://cdn2.iconfinder.com/data/icons/audio-16/96/user_avatar_profile_login_button_account_member-512.png' alt='avatar'/>
              </div>
              <div className={styles.desc}>
                <p><MailIcon /> {rider.emailId}</p>
                <p><PhoneInTalkIcon />{rider.phoneNumber}</p>
                <p style={{textTransform:'capitalize'}}><Person3Icon /> {rider.role}</p>
              </div>
            </div>
          </div>
        </div>
      </div>   
      {resetPassword && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setResetPassword(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Reset Password</h1>
            </div>
            <div className={styles.inputFields}>
              <div className={styles.inputs}>
                <InputField
                  type='text'
                  onChangeHandler={changeHandlerReset}
                  label='Current Password'
                  name='cur_pass'
                  value={resetData.cur_pass}
                />
                <InputField
                  type='text'
                  onChangeHandler={changeHandlerReset}
                  label='New Password'
                  name='new_pass'
                  value={resetData.new_pass}
                />
                <InputField
                  type='text'
                  onChangeHandler={changeHandlerReset}
                  label='Confirm Password'
                  name='conf_pass'
                  value={resetData.conf_pass}
                />
              </div>
            </div>
            <div className={styles.saveBtn}>
              <Button
                text={"Reset"}
                onClickHandler={resetPassHandler}
                style={{
                  background: "#122333",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0.7rem 2rem",
                }}
              />
            </div>
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </>
  )
}

export default Profile