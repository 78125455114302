export const isEmailValid = (email) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
};

export const isPasswordValid = (password) => {
    // Password should be at least 8 characters long and contain at least one lowercase letter,
    // one uppercase letter, one digit, and one special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
};

export const validatePassword = (password) => {

    // return "no error";

    // Check if the password is at least 8 characters long
    if (password.length < 8) {
        return "Password must be at least 8 characters long";
    }

    // Check if the password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
        return "Password must contain at least one lowercase letter";
    }

    // Check if the password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        return "Password must contain at least one uppercase letter";
    }

    // Check if the password contains at least one digit
    if (!/\d/.test(password)) {
        return "Password must contain at least one digit";
    }

    // Check if the password contains at least one special character
    if (!/[@$!%*?&]/.test(password)) {
        return "Password must contain at least one special character";
    }

    return "no error";
};