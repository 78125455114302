/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./ManageRider.module.css";
import Button from "../../../components/Button/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../../../components/InputField/InputField";
import LockIcon from "@mui/icons-material/Lock";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import {toast} from "react-toastify"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

function ManageRider() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [riderId, setRiderId] = useState("");
  const [availability, setAvailability] = useState([]);
  const [editRiderData, setEditRiderData] = useState({
    rider_name: "",
    user_name: "",
    bike_number: "",
    email_address: "",
    mobile_number: "",
    allocated_city: [],
    whatsapp: "",
  });
  const { token } = useSelector((states) => states.admin);
  const [editShow, setEditShow] = useState(false);

  const getRiderDetails = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/get_all_rider`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data);
      if (result.data.code) {
        let data = [];
        for (let i = 0; i < result.data.data.length; i++) {
          data.push({
            id: i + 1,
            fullName: result.data.data[i].riderName,
            riderName: result.data.data[i].riderName,
            username: result.data.data[i].username,
            emailId: result.data.data[i].emailId,
            phoneNumber: result.data.data[i].phoneNumber,
            riderAllocatedCity: result.data.data[i].riderAllocatedCity,
            riderBikeNo: result.data.data[i].riderBikeNo,
            action: result.data.data[i]._id,
            active:result.data.data[i].active
          });
        }
        setRows([...data]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getRiderDetails();
    getAvailability();
  }, []);

  const handleDelete = (e, values) => {
    console.log(values.row.action);
  };

  const handleShowEdit = async (e, values) => {
    setEditShow(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/get_rider/${values}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result);
      setEditRiderData({
        email_address: result.data.data.user.emailId,
        mobile_number: result.data.data.user.phoneNumber,
        rider_name: result.data.data.user.riderName,
        user_name: result.data.data.user.username,
        bike_number: result.data.data.user.riderBikeNo,
        allocated_city: result.data.data.user.riderAllocatedCity,
      });
      setRiderId(result.data.data.user._id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const changeHandler = (e) => {
    setEditRiderData({
      ...editRiderData,
      [e.target.name]: e.target.value,
    });
  };

  const getAvailability = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/get`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios(config);
    setAvailability([...result.data.data]);
  };

  const blockUnblockRiderHandler = async (e, id, active) => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/block_and_unblock`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
        active: !active,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data.data);
      if(result.data.data.active){
        toast.success('Rider unblocked successfully')
      }else{
        toast.success('Rider blocked successfully')
      }
      getRiderDetails();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleChange = (event) => {
    console.log(event.target);
    setEditRiderData({
      ...editRiderData,
      allocated_city:
        typeof value === "string"
          ? event.target.value.split(",")
          : event.target.value,
    });
  };

  const columns = [
    { field: "id", headerName: "S.No", width: 50, minWidth: 0 },
    { field: "riderName", headerName: "Rider Name", width: 200, minWidth: 0 },
    { field: "username", headerName: "Username", width: 150, minWidth: 0 },
    { field: "emailId", headerName: "Email Address", width: 250, minWidth: 0 },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
      minWidth: 0,
    },
    {
      field: "riderAllocatedCity",
      headerName: "Allocated City",
      width: 150,
      minWidth: 0,
    },
    {
      field: "riderBikeNo",
      headerName: "Bike Number",
      width: 100,
      minWidth: 0,
    },
    {
      field: "Action",
      renderCell: (cellValues) => {
        return (
          <div className={styles.wrapperAction}>
            <span style={{visibility:'hidden'}}>
              {/* <RemoveRedEyeIcon
                onClick={(e) => {
                  handleDelete(e, cellValues);
                }}
                className={styles.action}
              /> */}
            </span>
            <span>
              <EditIcon
                onClick={(e) => {
                  handleShowEdit(e, cellValues.row.action);
                }}
                className={styles.action}
              />
            </span>
            <span>
              {cellValues.row.active ? (
                <LockIcon
                  onClick={(e) =>
                    blockUnblockRiderHandler(e, cellValues.row.action, true)
                  }
                  className={styles.action}
                />
              ) : (
                <NoEncryptionGmailerrorredIcon
                  onClick={(e) =>
                    blockUnblockRiderHandler(e, cellValues.row.action, false)
                  }
                  className={styles.action}
                />
              )}
            </span>
          </div>
        );
      },
      align: "center",
      headerAlign: "center",
      width: 150,
      minWidth: 0,
    },
  ];

  const clickHandler = (e) => {
    navigate("/addRider");
  };

  const editRiderHandler = async (e) => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/update_rider`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: riderId,
        username: editRiderData.user_name,
        emailId: editRiderData.email_address,
        phoneNumber: editRiderData.mobile_number,
        riderName: editRiderData.rider_name,
        riderAllocatedCity: editRiderData.allocated_city,
        riderBikeNo: editRiderData.bike_number,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      setEditShow(false);
      setLoading(false);
      getRiderDetails();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <div className={styles.riderContainer}>
        <div className={styles.addRider}>
          <Button
            text={"Add Rider"}
            onClickHandler={clickHandler}
            style={{
              background: "#122333",
              color: "#fff",
              border: "none",
              borderRadius: "10px",
              padding: "0.7rem 2rem",
            }}
          />
        </div>
        <div className={styles.allRider}>
          <DataGrid
            style={{ backgroundColor: "#fff" }}
            rows={rows}
            disableRowSelectionOnClick
            columns={columns}
            autoHeight
            slots={{
              toolbar: GridToolbar,
            }}
            localeText={{ noRowsLabel: "Uploaded your data" }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      </div>
      {editShow && (
        <div className={styles.loginWrapper}>
          <div
            className={styles.editWrapper}
            style={{ maxHeight: "550px", overflowY: "auto" }}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setEditShow(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Rider Edit</h1>
            </div>
            <div className={styles.inputFields}>
              <div className={styles.fields}>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Rider Name'
                    name='rider_name'
                    value={editRiderData.rider_name}
                  />
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='User Name'
                    name='user_name'
                    value={editRiderData.user_name}
                  />
                </div>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Email Address'
                    name='email_address'
                    value={editRiderData.email_address}
                  />
                </div>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Mobile Name'
                    name='mobile_number'
                    value={editRiderData.mobile_number}
                  />
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Bike Number'
                    name='bike_number'
                    value={editRiderData.bike_number}
                  />
                </div>
                <div>
                  <div className={styles.select}>
                    {/* <select
                      name='allocated_city'
                      value={editRiderData.allocated_city}
                      onChange={changeHandler}>
                      <option value=''>Allocated City</option>
                      {availability?.map((cities, index) => {
                        return (
                          <option key={index} value={cities.city}>
                            {cities.city}
                          </option>
                        );
                      })}
                    </select> */}
                            <FormControl sx={{ m: 1, width: 300 }}>
                         <InputLabel id="demo-multiple-chip-label">Select Allocated City</InputLabel>
                    <Select
                          labelId='demo-multiple-chip-label'
                          id='demo-multiple-name'
                          multiple
                          value={editRiderData.allocated_city}
                          input={
                            <OutlinedInput
                              id='select-multiple-chip'
                              label='Select Allocated City'
                            />
                          }
                          onChange={handleChange}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                gap: 0.5,
                              }}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}>
                          {availability?.map((cities) => (
                            <MenuItem key={cities.city} value={cities.city}>
                              {cities.city}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}>
                  <Button
                    text={"Update"}
                    onClickHandler={editRiderHandler}
                    style={{
                      background: "#122333",
                      color: "#fff",
                      border: "none",
                      borderRadius: "10px",
                      padding: "0.7rem 2rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </>
  );
}

export default ManageRider;
