import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Invoice.module.css";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import { changeDateFormat } from "../../utils/index";
import { useReactToPrint } from "react-to-print";
import Button from "../../components/Button/Button";

function Invoice() {
  const { id, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getInvoice = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/invoice/get_invoice_by_id/${id}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      if (result.data.code) {
        let o = result.data.data.invc.orderIds;
        var total_amount = 0;
        var total_service_charges = 0;
        for (let i = 0; i < o.length; i++) {
          total_amount =
            parseFloat(total_amount) + parseFloat(o[i].totalAmount);
          total_service_charges =
            parseFloat(total_service_charges) +
            parseFloat(o[i].deliveryTypeAmount);
        }
        setInvoice({
          ...result.data.data.invc,
        });

        setInvoiceData({
          ...invoiceData,
          amt_total: parseFloat(total_amount).toFixed(2),
          total_service: parseFloat(total_service_charges).toFixed(2),
          net_total: parseFloat(
            parseFloat(total_amount) - parseFloat(total_service_charges)
          ).toFixed(2),
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <>
      <div className={styles.wrapper} style={{ width: "100%" }}>
        <h1>Invoice</h1>
        <div className={styles.print} ref={componentRef}>
          <div className={styles.container}>
            <table className={styles.table} width='100%'>
              <tr>
                <td>
                  <table width='100%'>
                    <tr>
                      <td align='left' width='50%'>
                        <table width='100%'>
                          <tr>
                            <td>
                              <div className={styles.header}>
                                <div className={styles.logoContainer}>
                                  <div className={styles.logo}>
                                    <img
                                      className={styles.Image}
                                      src='/img/logo.png'
                                      alt='logo'
                                    />
                                  </div>
                                  <h1>AL KANARI DELIVERY SERVICES</h1>
                                </div>
                                <div className={styles.desc}>
                                  <p>
                                    Shipper :{" "}
                                    <strong>
                                      {invoice?.client?.companyName}
                                    </strong>
                                  </p>
                                  <p>
                                    Shipper Account :{" "}
                                    <strong>{invoice?.client?._id}</strong>
                                  </p>
                                  <p>
                                    Shipper Contact :{" "}
                                    <strong>
                                      {invoice?.client?.phoneNumberOne}
                                    </strong>
                                  </p>
                                  <p>
                                    Shipper Address :{" "}
                                    <strong>
                                      {invoice?.client?.addressArray[0]}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td align='right' width='40%'>
                        <table width='100%'>
                          <tr>
                            <td>
                              <div className={styles.invoiceInfo}>
                                <p>
                                  Invoice Id : <strong>{invoice?._id}</strong>
                                </p>
                                <p>
                                  Date :{" "}
                                  <strong>
                                    {changeDateFormat(
                                      new Date(invoice?.createdAt)
                                    )}
                                  </strong>
                                </p>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <br></br>
            <br></br>
            <table
              width='100%'
              style={{
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
                padding: "1rem 0 0.5rem 0",
              }}>
              <tr>
                <td>
                  <table width='100%'>
                    <tr>
                      <th>S No.</th>
                      <th>Tracking No.</th>
                      <th>Receiver</th>
                      <th>Delivery Date</th>
                      <th>Payment Type</th>
                      <th>Amount</th>
                      <th>Service Charges</th>
                      <th>Outstanding</th>
                      <th>Remarks</th>
                    </tr>
                    <tbody>
                      {invoice?.orderIds?.map((order, index) => (
                        <tr>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              paddingTop: "1rem",
                              textAlign: "center",
                            }}>
                            {index + 1}
                          </td>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              textAlign: "center",
                              paddingTop: "1rem",
                            }}>
                            {order._id.slice(0, 7) + "..."}
                          </td>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              textAlign: "left",
                              paddingTop: "1rem",
                            }}>
                            {order.recipientName}<br></br>
                            {order.recipientCity}<br></br>
                            {order.recipientMobile}
                          </td>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              textAlign: "center",
                              paddingTop: "1rem",
                            }}>
                            {changeDateFormat(new Date(order.deliveredDate))}
                          </td>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              textAlign: "center",
                              paddingTop: "1rem",
                            }}>
                            {order.paymentType === 'cash' ? 'COD' :'Prepaid'}
                          </td>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              textAlign: "center",
                              paddingTop: "1rem",
                            }}>
                            {parseFloat(order.totalAmount).toFixed(2)}
                          </td>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              textAlign: "center",
                              paddingTop: "1rem",
                            }}>
                            {parseFloat(order.deliveryTypeAmount).toFixed(2)}
                          </td>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              textAlign: "center",
                              paddingTop: "1rem",
                            }}>
                            {parseFloat(
                              parseFloat(order.totalAmount) -
                                parseFloat(order.deliveryTypeAmount)
                            ).toFixed(2)}
                          </td>
                          <td
                            style={{
                              paddingBottom: "1rem",
                              paddingTop: "1rem",
                              textAlign: "center",
                            }}>
                            {order?.remark}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
            <br></br>
            <table width='100%'>
              <tr>
              <td align='left'>
                  <div>
                    <p style={{width:'200px',height:'50px',border:'2px solid black',marginBottom:'0.3rem'}}></p>
                    <p>Signature : </p>
                  </div>
                </td>
                <td align='right' style={{ width: "40%" }}>
                  <div className={styles.bottom} >
                    <p>
                      Total :{" "}
                      <strong>
                        {parseFloat(invoiceData?.amt_total).toFixed(2)}
                      </strong>
                    </p>
                    <p>
                      Total Shipments :{" "}
                      <strong>{invoice?.orderIds?.length}</strong>
                    </p>
                    <p>
                      Service Charges :{" "}
                      <strong>
                        {parseFloat(invoiceData?.total_service).toFixed(2)}
                      </strong>
                    </p>
                  </div>
                </td>
              </tr>
            </table>
            <br></br>
            <table width='100%' style={{ borderTop: "2px solid black" }}>
              <tr>
                <td width='30%' align='right' style={{ paddingTop: "1rem" }}>
                  <div className={styles.bottom} style={{ width: "40%" }}>
                    <p>
                      Net Total :{" "}
                      <strong>{parseFloat(invoiceData?.net_total).toFixed(2)}</strong>
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className={styles.printBtn}>
          <Button
            text={"Print Invoice"}
            onClickHandler={handlePrint}
            style={{
              background: "#122333",
              color: "#fff",
              border: "none",
              borderRadius: "10px",
              padding: "0.7rem 2rem",
            }}
          />
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
}

export default Invoice;
