import React,{useState} from 'react'
import styles from "./ManageClient.module.css"
import ManageClient from './ManageClient'
import ManageRider from "./ManageRider"

function Management() {
  const [tab,setTab] = useState(1)
  return (
    <div className={styles.container}>
    <div className={styles.content}>
        <div className={styles.tabHeader}>
            <h2 className={tab === 1 ? styles.active :""} onClick={() => setTab(1)} >Client Management</h2>
            <h2 className={tab === 2 ? styles.active :""} onClick={() => setTab(2)}>Rider Management</h2>
        </div>
        <div className={styles.tabContainer}> 
            {
            tab === 1 && <ManageClient />
            
            }
            {
            tab === 2 && <ManageRider />
            }
        </div>
    </div>
</div>
  )
}

export default Management