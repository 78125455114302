import React,{useEffect} from 'react'
import styles from "./Home.module.css"
import Button from "../../components/Button/Button"
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";


function Card({count,bullets,title,desc}){
  return(
    <div className={styles.cardContainer} data-aos="zoom-in">
      <div className={styles.count}>
        <span>{count < 10 ? "0"+count : count}</span>
      </div>
      <div className={styles.heading}> 
        <h1>{title}</h1>
      </div>
      <div className={styles.cardDesc}>
        <p>{desc}</p>
      </div>
      <div className={styles.bullets}>
        <ul>
          {
            bullets.map((bullet,index) => (
              <li key={index}>{bullet}</li>
            ))
          }
        </ul>
      </div>
      {/* <div >
        <Button 
          text={"Learn More"}
          style={{
            background: "linear-gradient(90deg, #122333 50%, rgba(0, 0, 0, 0.00) 50%, #ED4C21 50.01%)",
            color:"#fff"
        }}
        />
      </div> */}
      <div></div>
    </div>
  )
}

const COUNT = [
  {
    title:'Swift Deliveries',
    desc:'At Al Kanari Ride Delivery Services, we understand the importance of time-sensitive deliveries. Our swift and reliable services ensure that your parcels reach their destination on time, every time.',
    bullets:["Reliable","Fast Delivery","On-time Shipment"]
  },
  {
    title:'Personalized Solutions',
    desc:`We believe that every customer's needs are unique. That's why we offer personalized delivery solutions tailored to meet your specific requirements.`,
    bullets:["Regular Shipment","One-day Shipment","Rapid Shipment(2-4 hours)"]
  },
  {
    title:'Expert Handling of Fragile Items',
    desc:`Al Karani's trained professionals use appropriate packaging materials and employ best practices to ensure that your fragile packages are transported safely and arrive at their destination in pristine condition. `,
    bullets:["Secure packaging","Safety of product is our priority"]
  },
]
const teamsData=[
  {
   id:1,
   img:"img/team.jpg.jpg",
   title:"Dereck Jackson",
   subTitle:"Courier"
  },
  {
   id:2,
   img:"/img/team.jpg.jpg",
   title:"Dereck Jackson",
   subTitle:"Courier"
  },
  {
   id:3,
   img:"/img/team.jpg.jpg",
   title:"Dereck Jackson",
   subTitle:"Courier"
  },
  {
   id:4,
   img:"/img/team.jpg.jpg",
   title:"Dereck Jackson",
   subTitle:"Courier"
  },
]



function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      // easing: "ease-in-out",
      easing: "ease",
      once: false, // Easing function for the animation
      anchorPlacement: "top-bottom"
    }); // Initialize AOS
  }, []);
  const navigate = useNavigate();

  const { id, token } = useSelector((states) => states.client);

  useEffect(() => {
    if (!id && !token) {
      return;
    }
    navigate("/client");
  },[])

  return (
    <>
   
    <div className={styles.container}> 
        <div className={styles.wrapper}>
          <div className={styles.heroSection} data-aos="zoom-in">
            <div className={styles.heroImg}>
              <h1>Al Kanari <br></br>Delivery Services</h1>
              <div className={styles.desc}>
                <p>We are courier company that provides fast <br></br> and reliable package transport.</p>
              </div>
              <div className={styles.orderNow}>
                <Button
                  text = {"Order Now"}
                  style={{
                    background: 'linear-gradient(90deg, #ED4C21 50%, #FFF 50%)',
                    color:'#000'
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.fastBicycle} data-aos="zoom-in">
            <div className={styles.leftSec}>
              <img src='/img/bicycle_img.png' alt='bicycle'/>
            </div>
            <div className={styles.rightSec}>
              <div className={styles.inner}>
                  <h1>We Deliver Magic, Not Just Packages</h1>
                  <div className={styles.bicycleDesc}>
                    <p>Experience the convenience of Al Kanari Delivery - documents, gifts, accessories, or anything - all with a touch of elegance and efficiency.</p>
                  </div>
                  <div className={styles.orderNow}>
                    <Button
                      text = {"Order Now"}
                      style={{
                        background: 'linear-gradient(90deg, #FFF 50%, #ED4C21 50%)',
                        color:'#000'
                      }}
                    />
                  </div>
              </div>
            </div>
          </div>
          <div className={styles.localBusiness} data-aos="zoom-in">
              <div className={styles.title}>
                  <p>Vision</p>
              </div>
              <div className={styles.descBusiness}>        
                <p>Deliveries as Unique as Dubai Itself...</p>
              </div>
              <div className={styles.circle}>
                <span className={styles.outer}>
                  <span className={styles.inner}>
                  </span>
                </span>
              </div>

          </div>
          <div className={styles.ourServices}>
              <h2>Our services</h2>
          </div>
          <div className={styles.services}>
              {
                COUNT.map((data,index) => {
                      return(
                        <Card 
                          key={index}  
                          count={index+1}
                          title={data.title}
                          desc={data.desc}
                          bullets={data.bullets}
                        />
                      )
                    })
                    
              }
          </div>
          <div className={styles.happyClient}>
              <div className={styles.testimonials}>
                <p>Testimonials</p>
                <p>{`"I can't express how thankful I am for the exceptional service provided by Al Kanari. Living in UAE, I often crave dishes from various restaurants, and they always make it possible, even when I'm miles away from my favorite eateries. Fast, reliable, and courteous - they've truly elevated my experience!"`}</p>
                <p>- Atif Hussain</p>
              </div>
              <div className={styles.clients} data-aos="zoom-in">
                <p>our happy clients</p>
                <img src='/img/bicycle_img.png' alt='' />
              </div>
          </div>
          <div className={styles.fastTeam}>
              <h2>Our Fast Team</h2>
          </div>
          <div className={styles.teamsContainer}>
        
            {teamsData.map((team)=>(
              <div className={styles.card} key={team.id} data-aos="zoom-in">
                <img src={team.img} alt='' className={styles.img}/>
                <p className={styles.para}>{team.title}</p>
               <span className={styles.subtitle}>{team.subTitle}</span>
               </div>
            ))}
               
          </div>
          <div className={styles.moreAbout}>
                    <Button
                      text = {"MORE ABOUT US"}
                      style={{
                        background:'linear-gradient(90deg, #ED4C21 50%, #122333 50%)',
                        color:'#FFF'
                      }}
                      onClickHandler={()=>navigate("/aboutUs")}
                    />
                  </div>
        </div>
       
    </div>
     
    </>

  )
}

export default Home