import React, { useState } from "react";
import styles from "./Profile.module.css";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import MailIcon from "@mui/icons-material/Mail";
import Person3Icon from "@mui/icons-material/Person3";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FlagIcon from "@mui/icons-material/Flag";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, updateAddress } from "../../../redux/slices/client";
import Button from "../../../components/Button/Button";
import axios from "axios";
import DomainIcon from "@mui/icons-material/Domain";
import Loader from "../../../components/Loader/Loader";
import InputField from "../../../components/InputField/InputField";
import { toast } from "react-toastify";

function Profile() {
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const { client, token } = useSelector((states) => states.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editable, setEditable] = useState(false);
  const [resetData, setResetData] = useState({
    cur_pass: "",
    new_pass: "",
    conf_pass: "",
  });
  const [filterAddress, setFilterAddress] = useState([
    ...client.addressArray.map((ele, index) => ({
      id: index,
      value: ele,
    })),
  ]);

  const logoutHandler = (e) => {
    dispatch(logout());
    navigate("/");
  };

  const addressChangeHandler = (e, id) => {
    let arr = [...filterAddress];
    arr.forEach((item) => {
      if (item.id === id) {
        item.value = e.target.value;
      }
    });
    setFilterAddress([...arr]);
  };

  const removeAddress = (e, id) => {
    setFilterAddress([...filterAddress.filter((item) => item.id !== id)]);
  };

  const addMore = () => {
    setFilterAddress([
      ...filterAddress,
      { id: filterAddress[filterAddress.length - 1].id + 1, value: "" },
    ]);
  };

  const saveAddress = async (e) => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/client/update_address`,
      method: "put",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: client._id,
        newAddress: [...filterAddress.map((addr) => addr.value)],
      },
    };

    setLoading(true);

    const result = await axios(config);

    if (result.data.code) {
      dispatch(updateAddress([...filterAddress.map((addr) => addr.value)]));
      setLoading(false);
      setEditable(false);
    }
  };

  const changeHandler = (e) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
  };

  const resetPassHandler = async (e) => {
    if (
      resetData.cur_pass === "" ||
      resetData.new_pass === "" ||
      resetData.conf_pass === ""
    ) {
      toast.error("All fields are required.");
      return;
    }

    if (resetData.new_pass !== resetData.conf_pass) {
      toast.error("New password and Confirm password not matched.");
      return;
    }
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/client/reset_password`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: client._id,
        cur_pass: resetData.cur_pass,
        new_pass: resetData.new_pass,
      },
    };

    try {
      const result = await axios(config);
      if (result.data.code === 1) {
        toast.success(result.data.message);
        setResetData({
          cur_pass: "",
          new_pass: "",
          conf_pass: "",
        })
        setResetPassword(false)
      } else {
        toast.error(result.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.about}>
            <h1>About Me</h1>
          </div>
          <div className={styles.searchSec}>
            <div className={styles.icons}>
              <a href='https://api.whatsapp.com/send/?phone=971509769826&text&type=phone_number&app_absent=0'>
                <WhatsAppIcon />
              </a>
              <a href='mailto:sales@alkanarideliveryservices.com'>
                <MailIcon />
              </a>
            </div>
            <div className={styles.logout}>
              <div>
                <p>
                  Settings <ExpandMoreOutlinedIcon />
                </p>
              </div>
              <div className={styles.option}>
                <div className={styles.wrap}>
                  <Link to='#' onClick={(e) => setEditable(true)}>
                    {" "}
                    Edit (Address)
                  </Link>
                  <Link to='#' onClick={(e) => setResetPassword(true)}>
                    {" "}
                    Reset Password
                  </Link>
                  <Link to='/' onClick={logoutHandler}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.leftSec}>
            <div>
              <h1>Welcome, {client.username}</h1>
              <div className={styles.img}>
                <img
                  src='https://cdn2.iconfinder.com/data/icons/audio-16/96/user_avatar_profile_login_button_account_member-512.png'
                  alt='avatar'
                />
              </div>
              <div className={styles.desc}>
                <p>
                  <DomainIcon /> {client.companyName}
                </p>
                <p>
                  <FlagIcon /> {client.defaultAddress}
                </p>
                <p>
                  <MailIcon /> {client.emailId}
                </p>
                <p>
                  <PhoneInTalkIcon />{client.phoneNumberOne}
                </p>
                <p style={{textTransform:'capitalize'}}>
                  <Person3Icon /> {client.role}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightSec}>
            <div className={styles.organization}>
              <h1>Organization Name</h1>
              <div>
                <p>{client?.companyName}</p>
              </div>
            </div>
            <div className={styles.organization}>
              <h1>About Organization</h1>
              <div>
                <p>{client?.aboutOrganization}</p>
              </div>
            </div>
            <div className={styles.address}>
              <h1>Saved Addresses</h1>
              <div className={styles.addr}>
                <div>
                  <h2>{client.defaultAddress}</h2>
                </div>
                {
                  // eslint-disable-next-line array-callback-return
                  client.addressArray.map((addr, index) => {
                    if (addr !== client.defaultAddress) {
                      return (
                        <div key={index}>
                          <h2>{addr}</h2>
                        </div>
                      );
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {editable && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setEditable(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Address</h1>
            </div>
            <div className={styles.addMore}>
              <Button
                text={"Add +"}
                onClickHandler={addMore}
                style={{
                  background: "#122333",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0.7rem 2rem",
                }}
              />
            </div>
            <div className={styles.inputFields}>
              <div className={styles.inputs}>
                {
                  // eslint-disable-next-line array-callback-return
                  filterAddress.map((addr) => {
                    if (addr.value !== client.defaultAddress) {
                      return (
                        <div className={styles.addrItem}>
                          <input
                            type='text'
                            placeholder='Enter Address'
                            onChange={(e) => addressChangeHandler(e, addr.id)}
                            value={addr.value}
                          />
                          <span onClick={(e) => removeAddress(e, addr.id)}>
                            <CloseIcon />
                          </span>
                        </div>
                      );
                    }
                  })
                }
              </div>
            </div>
            <div className={styles.saveBtn}>
              <Button
                text={"Save"}
                onClickHandler={saveAddress}
                style={{
                  background: "#122333",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0.7rem 2rem",
                }}
              />
            </div>
          </div>
        </div>
      )}

      {resetPassword && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setResetPassword(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Reset Password</h1>
            </div>
            <div className={styles.inputFields}>
              <div className={styles.inputs}>
                <InputField
                  type='text'
                  onChangeHandler={changeHandler}
                  label='Current Password'
                  name='cur_pass'
                  value={resetData.cur_pass}
                />
                <InputField
                  type='text'
                  onChangeHandler={changeHandler}
                  label='New Password'
                  name='new_pass'
                  value={resetData.new_pass}
                />
                <InputField
                  type='text'
                  onChangeHandler={changeHandler}
                  label='Confirm Password'
                  name='conf_pass'
                  value={resetData.conf_pass}
                />
              </div>
            </div>
            <div className={styles.saveBtn}>
              <Button
                text={"Reset"}
                onClickHandler={resetPassHandler}
                style={{
                  background: "#122333",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0.7rem 2rem",
                }}
              />
            </div>
          </div>
        </div>
      )}

      <Loader loading={loading} />
    </>
  );
}

export default Profile;
