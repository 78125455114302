import React,{useState} from 'react';
import styles from './GetInTouch.module.css';
import NavBar from '../../components/Navbar/NavBar';
import Callback from '../../components/Callback/Callback';
import Loader from '../../components/Loader/Loader';
const GetInTouch = () => {
    const [isLoading, setIsLoading] = useState(false);
    const data = [
        {

            id: 1,
            icon: "/img/phone.jpg",
            title: "CALL US",
            subHeading: "+971-52-329-2964",
            description: null
        },
        {
            id: 2,
            icon: "/img/location.png",
            title: "EMAIL US",
            subHeading: "sales@alkanarideliveryservices.com",
            description: null
        },
    ];
  
    return (
        <>
            <div className={styles.main_container}>
               <Callback theme={true} setIsLoading={setIsLoading}/>

                <div className={styles.boxes} >

                    {data.map((itm) => (
                        <div className={styles.box}>
                            <h3>{itm.title}</h3>
                            <img src={itm.icon} alt="gjg" />
                            <span>{itm.subHeading}</span>
                            <p>{itm.description}</p>
                        </div>

                    ))}
                </div>
            </div>
            <iframe
                title='Our Location'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.280513017559!2d55.506479874923066!3d25.39541922364424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f780955248cf%3A0xd1308d051c41ea47!2sAl%20Eazaz%20Groups!5e0!3m2!1sen!2sin!4v1692986220679!5m2!1sen!2sin"
                style={{
                    width: "100%",
                    height: "410px",
                    border: "0",
                    allowfullscreen: "",
                    loading: "lazy",
                    referrerpolicy: "no-referrer-when-downgrade",
                    title: "myFrame"
                }} />
            <Loader loading={isLoading}/>
        </>
    )
}

export default GetInTouch;