/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styles from "./AddClient.module.css"
import Button from '../../../components/Button/Button'
import InputField from '../../../components/InputField/InputField'
import { useSelector } from 'react-redux';
import axios from 'axios'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/Loader/Loader';
import Autocomplete from "react-google-autocomplete"
import { toast } from 'react-toastify';
import { isEmailValid } from '../../../utils/Validations';

const Steps = ({ desc, val, step, clickHandler }) => {
  return (
    <div className={styles.steps}>
      <span className={`${styles.circle} ${step === val ? styles.active : ""}`}>
        <span></span>
      </span>
      <div>
        <h3>Step - {val}</h3>
        <p>{desc}</p>
      </div>
    </div>
  )
}

function AddClient() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [availability, setAvailability] = useState([])
  const { token } = useSelector(states => states.admin)
  const [step, setStep] = useState(1)
  const [addrData, setAddrData] = useState({
    default_address: "",
    lat:"",
    lng:""
  })
  const [clientData, setClientData] = useState({
    full_name: "",
    email_address: "",
    mobile_number: "",
    user_name: "",
    alt_mobile: "",
    org_name: "",
    about_org: "",
    cities: [],
    whatsapp: ""
  })
  const clickHandler = (flag) => {
    setStep(flag)
  }

  const getAvailability = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/get`,
      method: 'get',
      headers: {
        'secret_key': process.env.REACT_APP_SECRET_KEY,
        'Authorization': `Bearer ${token}`
      },
    }
    const result = await axios(config)
    setAvailability([...result.data.data])
  }

  const changeHandler = (e) => {
    if (e.target.name === 'cities') {
      if (e.target.checked) {
        setClientData({
          ...clientData,
          cities: [...clientData.cities, e.target.value]
        })
      } else {
        setClientData({
          ...clientData,
          cities: [...clientData.cities.filter(city => city !== e.target.value)]
        })
      }
      return
    }
    setClientData({
      ...clientData,
      [e.target.name]: e.target.value
    })
  }

  const submitHandler = async (e) => {
    if(!clientData.user_name.length>0 && !clientData.email_address.length>0 && !clientData.mobile_number.length>0 && !clientData.default_address.length>0 && !clientData.whatsapp.length>0 && !clientData.org_name.length>0 && !clientData.about_org.length>0 && !clientData.full_name.length>0 && !clientData.cities.length>0){
      toast.error("Please fill all required fields.");
    }
    else if(!isEmailValid(clientData.email_address)){
      toast.error("Please enter valid email adddress.");
    }
    else if( clientData.mobile_number<10){
      toast.error("Please enter valid mobile number.");
    }
    else
    {
      setLoading(true)
      const config = {
        url: process.env.REACT_APP_BASE_URL + `/client/add_client`,
        method: 'post',
        headers: {
          'secret_key': process.env.REACT_APP_SECRET_KEY,
          'Authorization': `Bearer ${token}`
        },
        data: {
          username: clientData.user_name,
          emailId: clientData.email_address,
          phoneNumberOne: clientData.mobile_number,
          phoneNumberTwo: clientData.alt_mobile,
          defaultAddress: addrData.default_address,
          latitude: addrData.lat,
          longitude: addrData.lng,
          whatsappNo: clientData.whatsapp,
          companyName: clientData.org_name,
          aboutOrganization: clientData.about_org,
          fullName: clientData.full_name,
          cities: [...clientData.cities]
        }
      }
      const result = await axios(config)
      console.log(result);
      if (result.data.code) {
        navigate('/dashboard')
      }else{
        toast.error(result.data.message)
      }
      setLoading(false)
    }
    
  }

  useEffect(() => {
    getAvailability()
  },[]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>

          <div className={styles.head}>
            <h2>Add Client</h2>
            <span className={styles.backButton} onClick={(e) => navigate('/dashboard')}><ArrowBackIcon /></span>
          </div>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <div className={styles.leftStaple}>
                <Steps
                  clickHandler={() => clickHandler(1)}
                  step={step}
                  val={1}
                  desc="Personal Information"
                />
                <Steps
                  step={step}
                  clickHandler={() => clickHandler(2)}
                  val={2}
                  desc="Organization Details"
                />
              </div>
              <div className={styles.rightStaple}>
                <form>
                  {
                    step === 1 && <>
                      <div className={styles.fields}>
                        <div>
                          <InputField
                            type='text'
                            onChangeHandler={changeHandler}
                            label='Full Name'
                            name='full_name'
                            value={clientData.full_name}
                          />
                          <InputField
                            type='text'
                            onChangeHandler={changeHandler}
                            label='User Name'
                            name='user_name'
                            value={clientData.user_name}
                          />
                        </div>
                        <div>
                          <InputField
                            type='text'
                            onChangeHandler={changeHandler}
                            label='Email Address'
                            name='email_address'
                            value={clientData.email_address}
                          />
                          <InputField
                            type='text'
                            onChangeHandler={changeHandler}
                            label='WhatsApp Number'
                            name='whatsapp'
                            value={clientData.whatsapp}
                          />
                        </div>
                        <div>
                          <InputField
                            type='text'
                            onChangeHandler={changeHandler}
                            label='Mobile Number'
                            name='mobile_number'
                            value={clientData.mobile_number}
                          />
                          <InputField
                            type='number'
                            onChangeHandler={changeHandler}
                            label='Alternate Mobile'
                            name='alt_mobile'
                            value={clientData.alt_mobile}
                          />
                        </div>
                      </div>
                    </>
                  }
                  {
                    step === 2 && <>
                      <div className={styles.fields}>
                                <div>
                                  <InputField 
                                      type='text'
                                      onChangeHandler={changeHandler}
                                      label = 'Organization Name'
                                      name = 'org_name'
                                      value={clientData.org_name}
                                  />
                                </div>
                                <div>
                                    <InputField 
                                      type='text'
                                      onChangeHandler={changeHandler}
                                      label = 'About Organization'
                                      name = 'about_org'
                                      value={clientData.about_org}
                                  />
                                </div>
                                {/* <div>
                                    <InputField 
                                      type='text'
                                      onChangeHandler={changeHandler}
                                      label = 'Default Address'
                                      name = 'default_address'
                                      value={clientData.default_address}
                                  />
                                </div> */}
                                <div className={styles.address}>
                                <Autocomplete
                                      apiKey="AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU"
                                      style={{ width: "100%" }}
                                      required={true}
                                      placeholder=''
                                      onPlaceSelected={(place) => {
                                        let lat = place.geometry.location.lat()
                                        let lng = place.geometry.location.lng()
                                        setAddrData({
                                          ...addrData,
                                          default_address :place.formatted_address,
                                          lat:lat,
                                          lng:lng
                                        })
                                      }}
                                      defaultValue={addrData.default_address}
                                      options={{
                                          componentRestrictions: { country: ["ae"], },
                                          types: ["geocode", "establishment"],
                                      }}
                                  />
                                    <label>Default Address</label>
                                </div>
                                <div className={styles.checkWrapper}>
                                      <div>
                                          <p>Choose Available cities</p>
                                      </div>
                                      <div className={styles.checkBoxContainer}>
                                          {
                                          // eslint-disable-next-line array-callback-return
                                          availability.map((cities,index) => {
                                              if(cities.pickupAvailability === 'Available'){
                                              return (
                                              <div className={styles.checkBox}>
                                                  <input onChange={changeHandler} id={`check${index}`} type='checkbox' name='cities' key={index} value={cities.city} style={{ width: 20, height: 20 }}/>
                                                  <label htmlFor={`check${index}`}>{cities.city}</label>
                                              </div>
                                              )
                                              }
                                          })
                                          }
                                      </div>
                                </div>
                              </div>
                      </>
                    }
                  </form>
                </div>
              </div>
              <div className={styles.continue}>
                {
                  step > 1 &&
                  <Button 
                    text = {"Back"}
                    onClickHandler={(e) => setStep(step - 1)}
                    style={{
                      background: '#122333',
                      color:'#fff',
                      border:'none',
                      borderRadius:'10px',
                      padding:'0.7rem 2rem'
                    }}
                  />
                }      

              {
                step === 2 ? <Button
                  text={"Submit"}
                  onClickHandler={submitHandler}
                  style={{
                    background: '#122333',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '10px',
                    padding: '0.7rem 2rem'
                  }}
                />
                  :
                  <Button
                    text={"Continue"}
                    onClickHandler={(e) => setStep(step + 1)}
                    style={{
                      background: '#122333',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '10px',
                      padding: '0.7rem 2rem'
                    }}
                  />
              }
            </div>
          </div>
        </div>
      </div>
      <Loader loading={loading} />
    </>
  )
}

export default AddClient