"use client"
import React from 'react'
import style from './InputField.module.css'

function InputField({type,onChangeHandler,label,name,styles,value,onBlurHandler,sup,max,disabled,sub,error=""}) {
  return (
    <div style={{display: 'flex',flexDirection:"column",gap:0}}>

    <div className={style.inputFieldWrapper}>
        <input 
            type={type}
            style={styles && styles}
            placeholder=' '
            required={true}
            onChange={onChangeHandler}
            name = {name}
            value={value}
            max={max}
            disabled={disabled}
            onBlur={onBlurHandler}
        />
        <label>{label}&nbsp;<sup>{sup !== undefined ? sup : ''}</sup><sub>{sub !== undefined ? sub : ''}</sub></label>
    </div>
        {(error !== "" || error.length>0) && <p style={{color:"red"}}>{`Error - ${error}`}</p>}
    </div>
  )
}

export default InputField