/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import axios from "axios";
import { changeDateFormat, camelCaseToTitleCase } from "../../../utils/index";
import { Link } from "react-router-dom";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Chip from "@mui/material/Chip";
import { DatePicker } from "antd";

const Box = ({ title, amount, flag, sup }) => {
  return (
    <div className={styles.box}>
      <h2>
        {title}&nbsp;
        <sup style={{ fontSize: "0.9rem", color: "#ED4C21" }}>{sup}</sup>
      </h2>
      <h2 style={{ color: flag }}>{amount} AED</h2>
    </div>
  );
};

const { RangePicker } = DatePicker;

function Dashboard() {
  const { client, token } = useSelector((states) => states.client);
  const [loading, setLoading] = useState(false);
  const [paymentReceived, setPaymentReceived] = useState(0);
  const [paymentPending, setPaymentPending] = useState(0);
  const [rows, setRows] = useState([]);

  const getOrderDetails = async () => {
    const config = {
      url:
        process.env.REACT_APP_BASE_URL +
        `/order/get_order_details_by_id/${client._id}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data);
      if (result.data.code) {
        let payment_pending = 0;
        let payment_collected = 0;
        result.data.data.map((order) => {
          if (order.paymentType === "cash") {
            if (
              order.isPaymentCollected === true &&
              order.isPaymentCollectedFromRider &&
              order.isPaymentSettled === false
            ) {
              payment_pending = payment_pending + (order.totalAmount - order.deliveryTypeAmount);
            }

            if (
              order.isPaymentCollected === true &&
              order.isPaymentCollectedFromRider === true &&
              order.isPaymentSettled === true
            ) {
              payment_collected = payment_collected + (order.totalAmount - order.deliveryTypeAmount);
            }
          }
        });
        let data = [];
        for (let i = 0; i < result.data.data.length; i++) {
          console.log(result.data);
          data.push({
            id: i + 1,
            senderName: result.data.data[i].senderName,
            pickupDate: changeDateFormat(result.data.data[i].pickUpDate),
            recipientName: result.data.data[i].recipientName,
            recipientCity: result.data.data[i].recipientCity,
            deliveryType: camelCaseToTitleCase(
              result.data.data[i].deliveryType
            ),
            isPaymentSuccess: result.data.data[i].isPaymentSuccess,
            orderStatus:
              result.data.data[i].orderStatus[
                result.data.data[i].orderStatus.length - 1
              ],
            isPaymentSettled: result.data.data[i].isPaymentSettled
              ? "Done"
              : "Pending",
            totalAmount: result.data.data[i].totalAmount,
            actualProductAmount: result.data.data[i].actualProductAmount,
            isInvoice: result.data.data[i].isInvoiceGenerated,
            invoiceId: result.data.data[i].invoice,
            cancel: result.data.data[i]?.cancel,
            deliverComplete : result.data.data[i]?.deliverComplete
            // action: (<button onClick={(e) => result.data.data[i]._id}>View</button>)
          });
        }
        setPaymentReceived(payment_collected);
        setPaymentPending(payment_pending);
        setRows([...data]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getOrderDetails();
  }, []);

  const invoiceStatus = (cellValues) => {
    return (
      <Chip
        label={cellValues.row.isInvoice ? "Done" : "Pending"}
        color={cellValues.row.isInvoice ? "success" : "warning"}
        variant='outlined'
      />
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 50,
      minWidth: 0,
    },
    {
      field: "senderName",
      headerName: "Sender Name",
      width: 150,
      minWidth: 0,
    },
    {
      field: "pickupDate",
      headerName: "Pickup Date",
      width: 150,
      minWidth: 0,
    },
    {
      field: "recipientName",
      headerName: "Recipient Name",
      width: 150,
      minWidth: 0,
    },
    {
      field: "recipientCity",
      headerName: "Recipient City",
      width: 150,
      minWidth: 0,
    },
    {
      field: "deliveryType",
      headerName: "Delivery Type",
      width: 150,
      minWidth: 0,
    },
    {
      field: "isPaymentSuccess",
      headerName: "Payment Status",
      renderCell: (cellValues) => {
        return (
          <Chip
            label={cellValues.row.isPaymentSuccess ? "Done" : "Pending"}
            color={cellValues.row.isPaymentSuccess ? "success" : "warning"}
            variant='outlined'
          />
        );
      },
      width: 150,
      minWidth: 0,
    },
    {
      field: "orderStatus",
      renderCell: (cellValues) => (
        cellValues.row.cancel ? 
        <Chip label={cellValues.row.orderStatus} color='error' /> : cellValues.row.deliverComplete ? <Chip label={cellValues.row.orderStatus} color='success' /> :
        <Chip label={cellValues.row.orderStatus} color='primary' />
      ),
      headerName: "Order Status",
      width: 250,
      minWidth: 0,
    },
    {
      field: "isPaymentSettled",
      headerName: "Payment Settled",
      width: 150,
      minWidth: 0,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 100,
      minWidth: 0,
    },
    {
      field: "Invoice Status",
      renderCell: invoiceStatus,
      align: "center",
      headerAlign: "center",
      width: 150,
      minWidth: 0,
    },
    {
      field: "View",
      width: 150,
      minWidth: 0,
      renderCell: (cellValues) => {
        if (cellValues.row.isInvoice) {
          return (
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "green",
              }}
              to={`/invoice/${cellValues.row.invoiceId}/${token}`}
              target='_blank'>
              View <ArrowOutwardIcon />
            </Link>
          );
        }

        return <></>;
      },
    },
    // { field: 'action', headerName: 'Action'},
  ];

  const getOrderDetailsDateFilter = async (dates) => {
    const config = {
      url:
        process.env.REACT_APP_BASE_URL +
        `/order/get_order_details_by_id_date_range/${client._id}`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        start_date: dates[0],
        end_date: dates[1],
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      if (result.data.code) {
        let payment_pending = 0;
        let payment_collected = 0;
        result.data.data.map((order) => {
          if (order.paymentType === "cash") {
            if (
              order.isPaymentCollected === true &&
              order.isPaymentCollectedFromRider &&
              order.isPaymentSettled === false
            ) {
              payment_pending = payment_pending + (order.totalAmount - order.deliveryTypeAmount);
            }

            if (
              order.isPaymentCollected === true &&
              order.isPaymentCollectedFromRider === true &&
              order.isPaymentSettled === true
            ) {
              payment_collected = payment_collected + (order.totalAmount - order.deliveryTypeAmount);
            }
          }
        });
        let data = [];
        for (let i = 0; i < result.data.data.length; i++) {
          data.push({
            id: i + 1,
            senderName: result.data.data[i].senderName,
            pickupDate: changeDateFormat(result.data.data[i].pickUpDate),
            recipientName: result.data.data[i].recipientName,
            recipientCity: result.data.data[i].recipientCity,
            deliveryType: camelCaseToTitleCase(
              result.data.data[i].deliveryType
            ),
            isPaymentSuccess: result.data.data[i].isPaymentSuccess
              ? "Done"
              : "Pending",
            orderStatus:
              result.data.data[i].orderStatus[
                result.data.data[i].orderStatus.length - 1
              ],
            isPaymentSettled: result.data.data[i].isPaymentSettled
              ? "Done"
              : "Pending",
            totalAmount: result.data.data[i].totalAmount,
            actualProductAmount: result.data.data[i].actualProductAmount,
            isInvoice: result.data.data[i].isInvoiceGenerated,
            invoiceId: result.data.data[i].invoice,
            // action: (<button onClick={(e) => result.data.data[i]._id}>View</button>)
          });
        }
        setPaymentReceived(payment_collected);
        setPaymentPending(payment_pending);
        setRows([...data]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onChangeRangeHandler = (dates) => {
    const formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));

    if (formattedDates.length === 2) {
      getOrderDetailsDateFilter(formattedDates);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Shipment</h2>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }} className={styles.range}>
          <div style={{ display: "flex", flexDirection:'column',gap:'0.3rem'}}>
            <label style={{fontWeight:'500'}}>Select start And end Date</label>
            <RangePicker style={{border:'1px solid black'}} onChange={onChangeRangeHandler} />
          </div>
        </div>
        <div className={styles.boxes}>
          <Box
            flag={"green"}
            title={"Payment Received"}
            amount={paymentReceived}
          />
          <Box
            flag={"red"}
            title={"Payment Due"}
            sup={"admin"}
            amount={paymentPending}
          />
        </div>
        <div className={styles.dataTable}>
          <DataGrid
            style={{ backgroundColor: "#fff" }}
            rows={rows}
            disableRowSelectionOnClick
            columns={columns}
            autoHeight
            slots={{
              toolbar: GridToolbar,
            }}
            localeText={{ noRowsLabel: "Data not found" }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
}

export default Dashboard;
