import React,{useState} from 'react'
import {
    CardElement
} from "@stripe/react-stripe-js"
import styles from "./CheckoutForm.module.css"
import InputField from '../components/InputField/InputField';

const CARD_OPTIONS = { 
    hidePostalCode: true, 
    iconStyle: "solid", 
    style: { 
      base: { 
        backgroundColor:"#fff",
        iconColor: "#c4f0ff", 
        fontWeight: 700, 
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif", 
        fontSize: "16px", 
        fontSmoothing: "antialiased", 
        border: "2px solid black",
      }, 
      invalid: { 
        iconColor: "red", 
        color: "red" 
      } 
    } 
  }; 

const ErrorMessage = ({ children }) => ( 
    <div className={styles.errorMessage}> 
      <svg width="20" height="20" viewBox="0 0 17 17"> 
        <path 
          fill="#FFF" 
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z" 
        /> 
        <path 
          fill="red" 
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z" 
        /> 
      </svg> 
      {children} 
    </div> 
  ) 

function CheckoutForm({complete,elements,changeHandler,payeeName,payeeEmail,oneCol}) {
 
    const [error, setError] = useState(null); 

  return (
    <div className={styles.checkoutForm} style={{display: oneCol? 'flex' : '',flexDirection : oneCol ? 'row':''}}>
        <div style={{flex: oneCol ? '2' : ''}}>
            <InputField 
                type='text'
                label = 'Payee Name'
                name = 'payeeName'
                value={payeeName}
                onChangeHandler={changeHandler}
            />
        </div>
        <div style={{flex: oneCol ? '2' : ''}}>
            <InputField 
                onChangeHandler={changeHandler}
                type='text'
                label = 'Payee Email'
                value={payeeEmail}
                name = 'payeeEmail'
            />
        </div>
        <div style={{flex: oneCol ? '2' : ''}} className={styles.cardNumber}>
            <CardElement 
                options={CARD_OPTIONS} 
                onChange={(e) => { 
                    console.log(e);
                    setError(e.error); 
                    if (e.error) { 
                        elements.getElement("card").focus(); 
                        return; 
                    } 
                    complete(e.complete); 
                }}  
            /> 
        </div>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
  )
}

export default CheckoutForm