import React from 'react'
import styles from './PageNotFound.module.css'

const PageNotFound = () => {
  return (
    <div className={styles.pnf_container}>
      <div className={styles.fixWidth}>
        <div className={styles.pnf_wrapper}>
          <span className={styles.pnf_oops}>Oo<span className={styles.color}>ps!  </span></span>
          <span className={styles.pnf_404}>4<span className={styles.color}>0</span>4</span>
        </div>
        <div className={styles.pnf}>Page Not Found</div>
      </div>
    </div>
  )
}

export default PageNotFound