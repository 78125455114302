import React, { useState } from "react";
import styles from "./RiderHistory.module.css"
import PickUp from "./PickUp";
import Delivery from "./Delivery";

function RiderHistory() {

    const [tab,setTab] = useState(1)
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Rider History</h2>
      </div>
      <div className={styles.content}>
        <div className={styles.tabHeader}>
          <h2
            className={tab === 1 ? styles.active : ""}
            onClick={() => setTab(1)}>
            PickUp History
          </h2>
          <h2
            className={tab === 2 ? styles.active : ""}
            onClick={() => setTab(2)}>
            Delivery History
          </h2>
        </div>
        <div className={styles.tabContainer}>
          {tab === 1 && (
            <PickUp history={true}/>
          )}
          {tab === 2 && (
            <Delivery history={true}/>
          )}
        </div>
      </div>
    </div>
  );
}

export default RiderHistory;
