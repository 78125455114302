import { createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import axios from "axios"
const initialState = {
    rider:{},
    id:'',
    token : "",
    error:""
}

export const riderAuth = createAsyncThunk(
    'riderAuth/login',
    async (loginData) => {
        const config = {
            url:process.env.REACT_APP_BASE_URL + "/rider/login",
            method:'post',
            data:{
                emailId : loginData.emailId,
                password : loginData.password
            }
        }
        try{
            const result = await axios(config)       
            if(result.data.code){
                return result.data.data
            }else{
                return result.data
            }
        }catch(err) {
            return err 
        }
    }
)

export const addRider = createAsyncThunk(
    'addRider/add',
    async (data) => {
        const config = {
            url:process.env.REACT_APP_BASE_URL + `/rider/get_rider/${data.id}`,
            method:'get',
            headers: { 
                'secret_key': process.env.REACT_APP_SECRET_KEY, 
                'Authorization': `Bearer ${data.token}`
            }
        }
        try{
            const result = await axios(config)
            if(result.data.code){
                return result.data.data
            }else{
                return result.data.message
            }
        }catch(err){
            return err
        }
    }
)

const riderSlice = createSlice({
    name:"rider",
    initialState,
    reducers:{
        logout:(state,action) =>{
            state.rider = {}
            state.token = ""
            state.id = ""
            state.error = ""
        },
    },
    extraReducers : {

        [riderAuth.fulfilled] : (state,action) => {
            console.log(action);
            state.token = action.payload.token
            state.id = action.payload.id
        },

        [addRider.fulfilled] : (state,action) => {
            state.rider = {
                ...action.payload.user
            }
        },
     
     }
})
export const  { logout,updateAddress } = riderSlice.actions
export default riderSlice.reducer;