import React, { useState } from 'react';
import styles from './About.module.css';
import Callback from '../../components/Callback/Callback';
import Loader from '../../components/Loader/Loader';

const AboutUs = () => {

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className={styles.aboutus_container}>
        <div className={styles.grey_box_panel}>
          <div className={styles.grey_box}>
            <div className={styles.grey_box1}>
              Transforming Delivery into a new Lifestyle
            </div>
          </div>
          <div className={styles.grey_box}>
            <div className={styles.grey_box2}>
              Al Kanari Delivery Services is your trusted source for tailored, efficient, and safe deliveries, driven by our unwavering commitment to your satisfaction.
            </div>
          </div>
        </div>
        <div className={styles.cycling_box}>
          <img src={"./img/cyclist.png"} style={{ borderRadius: 40 }} alt={"loading..."} />
        </div>
        <div className={styles.ourDifferentiators}>
          <h2>Our Differentiators</h2>
        </div>
        <div className={styles.parent_box}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              01
              <div style={{ height: "2px", width: "80px", backgroundColor: "black", marginLeft: 10, marginRight: 10 }}></div>
              Swift and Reliable Deliveries
            </div>
            <div className={styles.cardBody}>
              Al Alkanari Ride Delivery Services, we understand the importance of time-sensitive deliveries. Our swift and reliable services ensure that your packages and parcels reach their destination on time, every time.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              02
              <div style={{ height: "2px", width: "80px", backgroundColor: "black", marginLeft: 10, marginRight: 10 }}></div>
              Personalized Delivery Solutions
            </div>
            <div className={styles.cardBody}>
              We believe that every customer's needs are unique. That's why we offer personalized delivery solutions tailored to meet your specific requirements.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              03
              <div style={{ height: "2px", width: "80px", backgroundColor: "black", marginLeft: 10, marginRight: 10 }}></div>
              State of the Art Package Tracking
            </div>
            <div className={styles.cardBody}>
              Transparency and communication are essential when it comes to delivery services. With Alkanari Ride Delivery Services, you can stay informed about your package's journey from pick-up to drop-off.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              04
              <div style={{ height: "2px", width: "80px", backgroundColor: "black", marginLeft: 10, marginRight: 10 }}></div>
              Customer-centric Delivery Services
            </div>
            <div className={styles.cardBody}>
              Al Kanari Delivery Services apart as a reliable and customer-centric delivery partner. Whether it's for businesses or individuals, we are dedicated to providing top-notch services that prioritize efficiency, security, and customer satisfaction.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              05
              <div style={{ height: "2px", width: "80px", backgroundColor: "black", marginLeft: 10, marginRight: 10 }}></div>
              Expert Handling of Fragile Items
            </div>
            <div className={styles.cardBody}>
              Sending delicate and fragile items requires extra care and attention. At Al Kanari Delivery Services, we specialize in the secure handling of such items.
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              06
              <div style={{ height: "2px", width: "80px", backgroundColor: "black", marginLeft: 10, marginRight: 10 }}></div>
              On-time Delivery Services
            </div>
            <div className={styles.cardBody}>
              When you choose Al Kanari Delivery Services, you're choosing a partner dedicated to ensuring that your goods and packages reach you exactly when you expect them.
            </div>
          </div>
        </div>
        <Callback theme={false} setIsLoading={setIsLoading} />
      </div>
      <Loader loading={isLoading} />
    </>
  )
}

export default AboutUs;