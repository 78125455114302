import React, {useState} from 'react'
import SideBar from "../../../components/Navbar/SideBar"
import styles from "./Rider.module.css"
import PickUp from './PickUp'
import Delivery from './Delivery'
import Profile from './Profile'
import RiderHistory from './RiderHistory'
// import Profile from './Profile'
// import Management from './Management'

const Box = ({title,amount,flag}) => {
  return(
    <div className={styles.box}>
      <h2>{title}</h2>
      <h2 style={{color:flag}}>{amount} AED</h2>
    </div>
  )
}

function Rider() {

  const [nav,setNav] = useState(0)
  const [tab,setTab] = useState(1)
  const [totalPaymentReceived,setTotalPaymentReceived] = useState(0)

  return (
    <div className={styles.container}>
    <div className={styles.wrapper}>
        <div className={styles.sidebar}>
            <SideBar placeOrder={true} secondTitle={"History"} setNavHandler={setNav} navHandler={nav}/>
        </div>
    </div>
    {
      nav === 0 &&
      <div className={styles.container}>
      <div className={styles.header}>
            <h2>Rider Dashboard</h2>
          </div>
          <div className={styles.boxes}>
            <Box 
                flag = {"green"}
                title={"Payment Received"} 
                amount={totalPaymentReceived}
            />
          </div>
        <div className={styles.content}>
            <div className={styles.tabHeader}>
                <h2 className={tab === 1 ? styles.active :""} onClick={() => setTab(1)} >PickUp Management</h2>
                <h2 className={tab === 2 ? styles.active :""} onClick={() => setTab(2)}>Delivery Management</h2>
            </div>
            <div className={styles.tabContainer}> 
                {
                tab === 1 && <PickUp history={false} setTotalPaymentReceived={setTotalPaymentReceived}/>
                
                }
                {
                tab === 2 && <Delivery history={false} setTotalPaymentReceived={setTotalPaymentReceived}/>
                }
            </div>
        </div>
    </div>
    }
    {
      nav === 1 &&
      <RiderHistory />
    }
    {
      nav === 2 &&
      <Profile />
     }

</div>
  )
}

export default Rider