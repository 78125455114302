import React, { useEffect, useState } from "react";
import styles from "./Delivery.module.css";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Chip from "@mui/material/Chip";

import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import InputField from "../../../components/InputField/InputField";

function Delivery({ setTotalPaymentReceived, history }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU",
    libraries: ["places"],
  });
  const [openMap, setOpenMap] = useState(false);
  const [noRouteFound, setNoRouteFound] = useState(false);
  const [senderLatLong, setSenderLatLng] = useState({});
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const { id, token, error } = useSelector((states) => states.rider);
  const [data, setData] = useState([]);
  const [OTP, setOTP] = useState("");

  const getDeliveryOrders = async () => {
    setLoading(true);
    const config = {
      url:
        process.env.REACT_APP_BASE_URL +
        `/rider/get_rider_delivery_orders/${id}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const result = await axios(config);
      if (result.data.code) {
        setData([...result.data.data.orders_delivery]);
        if (setTotalPaymentReceived !== undefined) {
          let amount = 0;
          for (let i = 0; i < result.data.data.orders_delivery.length; i++) {
            if (
              result.data.data.orders_delivery[i].paymentType === "cash" &&
              result.data.data.orders_delivery[i]
                .isPaymentCollectedFromRider === false &&
              result.data.data.orders_delivery[i].isPaymentCollected === true
            ) {
              amount = amount + result.data.data.orders_delivery[i].totalAmount;
            }
          }
          setTotalPaymentReceived(amount);
        }
      } else {
        return result.data.message;
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    getDeliveryOrders();
  }, []);

  const containerStyle = {
    width: "100%",
    height: "180px",
  };

  const [loading, setLoading] = useState(false);

  const onclickShowDirection = (obj) => {
    setSenderLatLng({
      ...obj,
    });
    setOpenMap(true);
    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const directionService = new window.google.maps.DirectionsService();
        directionService
          .route({
            origin: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            destination: {
              lat: obj.lat,
              lng: obj.lng,
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          })
          .then((res) => {
            setDirectionsResponse(res);
          })
          .catch((err) => {
            setNoRouteFound(true);
            console.log(err);
          });
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const sendOtp = async (e, _id) => {
    setLoading(true);
    const config = {
      url:
        process.env.REACT_APP_BASE_URL + `/rider/send_otp_to_receiver/${_id}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    const otp = await axios(config);
    if (otp.data.code === 1) {
      toast.success(otp.data.message);
      getDeliveryOrders();
    }
    setLoading(false);
  };

  const verifyOtp = async (e, _id) => {
    if (OTP === "") {
      toast.warning("Please fill OTP");
      return;
    }
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/verify_receiver_otp`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: _id,
        otp: OTP,
      },
    };
    try {
      const stat = await axios(config);
      if (stat.data.code === 1) {
        toast.success(stat.data.message);
        getDeliveryOrders();
      } else {
        toast.error(stat.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const orderStatusChange = async (e, _id, status) => {
    // if (e.target.checked) {
      setLoading(true);
      const config = {
        url: process.env.REACT_APP_BASE_URL + `/rider/update_order_status`,
        method: "post",
        headers: {
          secret_key: process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: _id,
          status: status,
        },
      };
      const stat = await axios(config);
      if (stat.data.code === 1) {
        toast.success(stat.data.message);
        getDeliveryOrders();
      }
      setLoading(false);
    // }
  };

  const paymentStatusChange = async (e, _id, status) => {
    if (e.target.checked) {
      setLoading(true);
      const config = {
        url: process.env.REACT_APP_BASE_URL + `/rider/update_payment_status`,
        method: "post",
        headers: {
          secret_key: process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: _id,
          status: status,
        },
      };
      const stat = await axios(config);
      if (stat.data.code === 1) {
        toast.success(stat.data.message);
        getDeliveryOrders();
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.pickupContainer}>
        <div className={styles.pickup}>
          {data?.length === 0 ? (
            <>
              <h3>No items Found</h3>
            </>
          ) : (
            <>
              {data.map((item, index) => {
                if (history === true && (item.deliverComplete === true || item.cancel === true)) {
                  return (
                    <div className={styles.detailsContainer}>
                      <div className={`${styles.map} `}>
                        <div
                          className={styles.overlay}
                          onClick={() =>
                            onclickShowDirection({
                              lat: item.recipientLatitude,
                              lng: item.recipientLongitude,
                            })
                          }>
                          <h2>Show Direction</h2>
                        </div>
                        {isLoaded && (
                          <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{
                              lat: item.recipientLatitude,
                              lng: item.recipientLongitude,
                            }}
                            zoom={15}>
                            <MarkerF
                              position={{
                                lat: item.recipientLatitude,
                                lng: item.recipientLongitude,
                              }}>
                              <InfoWindowF
                                position={{
                                  lat: item.recipientLatitude,
                                  lng: item.recipientLongitude,
                                }}>
                                <div>
                                  <h4>{item.recipientFullAddress}</h4>
                                </div>
                              </InfoWindowF>
                            </MarkerF>
                          </GoogleMap>
                        )}
                      </div>
                      <div className={styles.details}>
                        <div>
                          <h3>Details</h3>
                        </div>
                        <div>
                            <p>Amount : {`${item.totalAmount} AED (${item.paymentType === 'cash' ? "COD" : item.paymentType})`}</p>
                          </div>
                          <div>
                            <p>Payment Status : {item.isPaymentCollected ? "Received" : "Pending"}</p>
                          </div>
                        <div>
                          <p>Name : {item.recipientName}</p>
                        </div>
                        <div>
                          <p>Address : {item.recipientFullAddress}</p>
                        </div>
                        <div>
                          <p>Phone No. : {item.recipientMobile}</p>
                        </div>
                      </div>
                          {item.deliverComplete && item.cancel === false ? (
                          <>
                            <Chip label='Delivered Success' color='success' />
                          </>
                        ) : (
                          <>
                            <Chip label='Delivered Cancel' color='error' />
                          </>
                        )}
                    </div>
                  );
                }

                if (history === false && item.deliverComplete === false && item.orderAccepted === true && item.cancel === false) {
                  return (
                    <div className={styles.detailsContainer}>
                      <div className={`${styles.map} `}>
                        <div
                          className={styles.overlay}
                          onClick={() =>
                            onclickShowDirection({
                              lat: item.recipientLatitude,
                              lng: item.recipientLongitude,
                            })
                          }>
                          <h2>Show Direction</h2>
                        </div>
                        {isLoaded && (
                          <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{
                              lat: item.recipientLatitude,
                              lng: item.recipientLongitude,
                            }}
                            zoom={15}>
                            <MarkerF
                              position={{
                                lat: item.recipientLatitude,
                                lng: item.recipientLongitude,
                              }}>
                              <InfoWindowF
                                position={{
                                  lat: item.recipientLatitude,
                                  lng: item.recipientLongitude,
                                }}>
                                <div>
                                  <h4>{item.recipientFullAddress}</h4>
                                </div>
                              </InfoWindowF>
                            </MarkerF>
                          </GoogleMap>
                        )}
                      </div>
                      <div className={styles.details}>
                        <div>
                          <h3>Details</h3>
                        </div>
                        <div>
                            <p>Amount : {`${item.totalAmount} AED (${item.paymentType === 'cash' ? "COD" : item.paymentType})`}</p>
                          </div>
                          <div>
                            <p>Payment Status : {item.isPaymentCollected ? "Received" : "Pending"}</p>
                          </div>
                        <div>
                          <p>Name : {item.recipientName}</p>
                        </div>
                        <div>
                          <p>Address : {item.recipientFullAddress}</p>
                        </div>
                        <div>
                          <p>Phone No. : {item.recipientMobile}</p>
                        </div>
                      </div>
                      {item.deliverComplete ? (
                        <>
                          <Chip label="Delivery Success" color="success" />
                        </>
                      ) : (
                        <>
                          {item?.afterDeliveryOtpClaimed ? (
                            <>
                              {item?.paymentType !== "prepaid" && (
                                <div className={styles.otpSec}>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "1rem",
                                      alignItems: "center",
                                    }}>
                                    <input
                                      onChange={(e) =>
                                        paymentStatusChange(e, item._id, true)
                                      }
                                      checked={item.isPaymentSuccess}
                                      disabled={item.isPaymentSuccess}
                                      id='amt'
                                      type='checkbox'
                                      style={{ width: 20, height: 20 }}
                                    />
                                    <label htmlFor='amt'>
                                      Collected Amount{" "}
                                      <strong>${item.totalAmount} AED</strong>
                                    </label>
                                  </div>
                                </div>
                              )}
                              <div className={styles.otpSec}>
                                {/* <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center",
                                  }}>
                                  <input
                                    onChange={(e) =>
                                      orderStatusChange(
                                        e,
                                        item._id,
                                        "Delivered"
                                      )
                                    }
                                    checked={item.orderStatus.includes(
                                      "Delivered"
                                    )}
                                    disabled={item.orderStatus.includes(
                                      "Delivered"
                                    )}
                                    id='delivered'
                                    type='checkbox'
                                    style={{ width: 20, height: 20 }}
                                  />
                                  <label htmlFor='delivered'>Delivered</label>
                                </div> */}
                                <div className={styles.select}>
                                  <select onChange={(e) =>
                                          orderStatusChange(
                                            e,
                                            item._id,
                                            e.target.value
                                          )
                                        } value={item.orderStatus[item.orderStatus.length -1]}>
                                        <option value=''>Select Status</option>
                                        <option value='Delivered'>Delivered</option>
                                        <option value='No Answer'>No Answer</option>
                                        <option value='Reschedule Delivery'>Reschedule Delivery</option>
                                        <option value='Canceled'>Canceled</option>
                                      </select>
                                </div>
                              </div>
                              {/* <div className={styles.otpSec}>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center",
                                  }}>
                                  <input
                                    onChange={(e) =>
                                      orderStatusChange(
                                        e,
                                        item._id,
                                        "Delivery Completed"
                                      )
                                    }
                                    checked={item.orderStatus.includes(
                                      "Delivery Completed"
                                    )}
                                    disabled={item.orderStatus.includes(
                                      "Delivery Completed"
                                    )}
                                    id='delivered_complete'
                                    type='checkbox'
                                    style={{ width: 20, height: 20 }}
                                  />
                                  <label htmlFor='delivered_complete'>
                                    Delivery Completed
                                  </label>
                                </div>
                              </div> */}
                            </>
                          ) : (
                            <>
                              <div className={styles.otpSec}>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center",
                                  }}>
                                  <input
                                    onChange={(e) =>
                                      orderStatusChange(
                                        e,
                                        item._id,
                                        "Delivery Attempted"
                                      )
                                    }
                                    checked={item.orderStatus.includes(
                                      "Delivery Attempted"
                                    )}
                                    disabled={item.orderStatus.includes(
                                      "Delivery Attempted"
                                    )}
                                    id='checkStatus'
                                    type='checkbox'
                                    style={{ width: 20, height: 20 }}
                                  />
                                  <label htmlFor='checkStatus'>
                                    Delivery Attempted
                                  </label>
                                </div>
                              </div>
                              <div className={styles.otpSec}>
                                <Button
                                  text={"Send OTP"}
                                  onClickHandler={(e) => sendOtp(e, item._id)}
                                  style={{
                                    width: "100%",
                                    background: "#122333",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "10px",
                                    padding: "0.7rem 2rem",
                                    whiteSpace: "nowrap",
                                  }}>
                                  Send OTP
                                </Button>
                              </div>
                              <div className={styles.verifyOtps}>
                                <InputField
                                  type='text'
                                  onChangeHandler={(e) => {
                                    setOTP(e.target.value);
                                  }}
                                  label='Enter OTP'
                                />
                                <Button
                                  text={"Verify OTP"}
                                  onClickHandler={(e) => verifyOtp(e, item._id)}
                                  style={{
                                    width: "100%",
                                    background: "#122333",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "10px",
                                    padding: "0.7rem 2rem",
                                    whiteSpace: "nowrap",
                                  }}>
                                  Verify OTP
                                </Button>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  );
                }

              })}
            </>
          )}
        </div>
      </div>
      {openMap && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper}>
            <div className={styles.close}>
              <CloseIcon
                onClick={() => {
                  setOpenMap(false);
                  setNoRouteFound(false);
                  setDirectionsResponse(null);
                }}
              />
            </div>
            <div className={styles.headerAddr}>
              <h1>Directions</h1>
            </div>
            {!noRouteFound ? (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "350px",
                }}
                center={{
                  lat: senderLatLong.lat,
                  lng: senderLatLong.lng,
                }}
                zoom={15}>
                {directionsResponse !== null && (
                  <DirectionsRenderer directions={directionsResponse} />
                )}
              </GoogleMap>
            ) : (
              <div style={{ textAlign: "center" }}>
                <h1>No Route Found</h1>
              </div>
            )}
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </>
  );
}

export default Delivery;
