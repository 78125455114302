import React, { useEffect, useState } from "react";
import styles from "./PickUp.module.css";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import InputField from "../../../components/InputField/InputField";

function PickUp({ setTotalPaymentReceived, history }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU",
    libraries: ["places"],
  });
  const [openMap, setOpenMap] = useState(false);
  const [noRouteFound, setNoRouteFound] = useState(false);
  const [senderLatLong, setSenderLatLng] = useState({});
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const { id, token, error } = useSelector((states) => states.rider);
  const [data, setData] = useState([]);
  const [OTP, setOTP] = useState("");

  const getPickupOrders = async () => {
    setLoading(true);
    const config = {
      url:
        process.env.REACT_APP_BASE_URL + `/rider/get_rider_pickup_orders/${id}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const result = await axios(config);
      if (result.data.code) {
        setData([...result.data.data.orders_pickup]);
        if (setTotalPaymentReceived !== undefined) {
          let amount = 0;
          for (let i = 0; i < result.data.data.orders_pickup.length; i++) {
            if (
              result.data.data.orders_pickup[i].paymentType === "cash" &&
              result.data.data.orders_pickup[i].isPaymentCollectedFromRider ===
                false &&
              result.data.data.orders_pickup[i].isPaymentCollected === true
            ) {
              amount = amount + result.data.data.orders_pickup[i].totalAmount;
            }
          }
          setTotalPaymentReceived(amount);
        }
      } else {
        return result.data.message;
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    getPickupOrders();
  }, []);

  const containerStyle = {
    width: "100%",
    height: "180px",
  };

  const [loading, setLoading] = useState(false);

  const onclickShowDirection = (obj) => {
    setSenderLatLng({
      ...obj,
    });
    setOpenMap(true);
    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const directionService = new window.google.maps.DirectionsService();
        directionService
          .route({
            origin: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            destination: {
              lat: obj.lat,
              lng: obj.lng,
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          })
          .then((res) => {
            setDirectionsResponse(res);
          })
          .catch((err) => {
            setNoRouteFound(true);
            console.log(err);
          });
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const sendOtp = async (e, _id) => {
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/send_otp_to_sender/${_id}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    const otp = await axios(config);
    if (otp.data.code === 1) {
      toast.success(otp.data.message);
      getPickupOrders();
    }
    setLoading(false);
  };

  const orderStatusChange = async (e, _id, status) => {
    // if (e.target.checked) {
      setLoading(true);
      const config = {
        url: process.env.REACT_APP_BASE_URL + `/rider/update_order_status`,
        method: "post",
        headers: {
          secret_key: process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: _id,
          status: status,
        },
      };
      const stat = await axios(config);
      if (stat.data.code === 1) {
        toast.success(stat.data.message);
        getPickupOrders();
      }
      setLoading(false);
    // }
  };

  const verifyOtp = async (e, _id) => {
    if (OTP === "") {
      toast.warning("Please fill OTP");
      return;
    }
    setLoading(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/rider/verify_sender_otp`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: _id,
        otp: OTP,
      },
    };
    try {
      const stat = await axios(config);
      if (stat.data.code === 1) {
        toast.success(stat.data.message);
        getPickupOrders();
      } else {
        toast.error(stat.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.pickupContainer}>
        <div className={styles.pickup}>
          {data?.length === 0 ? (
            <>
              <h3>No items Found</h3>
            </>
          ) : (
            <>
              {data.map((item, index) => {
                if (history === true && (item.pickupComplete === true || item.cancel === true)) {
                  return (
                    <>
                      <div className={styles.detailsContainer}>
                        <div className={`${styles.map} `}>
                          <div
                            className={styles.overlay}
                            onClick={() =>
                              onclickShowDirection({
                                lat: item.senderLatitude,
                                lng: item.senderLongitude,
                              })
                            }>
                            <h2>Show Direction</h2>
                          </div>
                          {isLoaded && (
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={{
                                lat: item.senderLatitude,
                                lng: item.senderLongitude,
                              }}
                              zoom={15}>
                              <MarkerF
                                position={{
                                  lat: item.senderLatitude,
                                  lng: item.senderLongitude,
                                }}>
                                <InfoWindowF
                                  position={{
                                    lat: item.senderLatitude,
                                    lng: item.senderLongitude,
                                  }}>
                                  <div>
                                    <h4>{item.senderAddress}</h4>
                                  </div>
                                </InfoWindowF>
                              </MarkerF>
                            </GoogleMap>
                          )}
                        </div>
                        <div className={styles.details}>
                          <div>
                            <h3>Details</h3>
                          </div>
                          <div>
                            <p>Amount : {`${item.totalAmount} AED (${item.paymentType === 'cash' ? "COD" : item.paymentType})`}</p>
                          </div>
                          <div>
                            <p>Payment Status : {item.isPaymentCollected ? "Received" : "Pending"}</p>
                          </div>
                          <div>
                            <p>Name : {item.senderName}</p>
                          </div>
                          <div>
                            <p>Address : {item.senderAddress}</p>
                          </div>
                          <div>
                            <p>Phone No. : {item.senderMobile}</p>
                          </div>
                        </div>
                        {item.pickupComplete && item.cancel === false ? (
                          <>
                            <Chip label='Pickup Success' color='success' />
                          </>
                        ) : (
                          <>
                            <Chip label='Pickup Cancel' color='error' />
                          </>
                        )}
                      </div>
                    </>
                  );
                }

                if (history === false && item.pickupComplete === false && item.orderAccepted === true && item.cancel === false) {
                  return (
                    <>
                      <div className={styles.detailsContainer}>
                        <div className={`${styles.map} `}>
                          <div
                            className={styles.overlay}
                            onClick={() =>
                              onclickShowDirection({
                                lat: item.senderLatitude,
                                lng: item.senderLongitude,
                              })
                            }>
                            <h2>Show Direction</h2>
                          </div>
                          {isLoaded && (
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={{
                                lat: item.senderLatitude,
                                lng: item.senderLongitude,
                              }}
                              zoom={15}>
                              <MarkerF
                                position={{
                                  lat: item.senderLatitude,
                                  lng: item.senderLongitude,
                                }}>
                                <InfoWindowF
                                  position={{
                                    lat: item.senderLatitude,
                                    lng: item.senderLongitude,
                                  }}>
                                  <div>
                                    <h4>{item.senderAddress}</h4>
                                  </div>
                                </InfoWindowF>
                              </MarkerF>
                            </GoogleMap>
                          )}
                        </div>
                        <div className={styles.details}>
                          <div>
                            <h3>Details</h3>
                          </div>
                          <div>
                            <p>Amount : {`${item.totalAmount} AED (${item.paymentType === 'cash' ? "COD" : item.paymentType})`}</p>
                          </div>
                          <div>
                            <p>Payment Status : {item.isPaymentCollected ? "Received" : "Pending"}</p>
                          </div>
                          <div>
                            <p>Name : {item.senderName}</p>
                          </div>
                          <div>
                            <p>Address : {item.senderAddress}</p>
                          </div>
                          <div>
                            <p>Phone No. : {item.senderMobile}</p>
                          </div>
                        </div>
                        {item.pickupComplete ? (
                          <>
                            <Chip label='Pickup Success' color='success' />
                          </>
                        ) : (
                          <>
                            {item?.beforeDeliveryOtpClaimed ? (
                              <>
                                <div className={styles.otpSec}>
                                <div className={styles.select}>
                                    <select onChange={(e) =>
                                        orderStatusChange(
                                          e,
                                          item._id,
                                          e.target.value
                                        )
                                      } value={item.orderStatus[item.orderStatus.length -1]}>
                                      <option value=''>Select Status</option>
                                      <option value='Package Picked'>Package Picked</option>
                                      <option value='No Answer'>No Answer</option>
                                      <option value='Reschedule Pickup'>Reschedule Pickup</option>
                                      <option value='Canceled'>Canceled</option>
                                    </select>
                                  </div>
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      gap: "1rem",
                                      alignItems: "center",
                                    }}>
                                    <input
                                      onChange={(e) =>
                                        orderStatusChange(
                                          e,
                                          item._id,
                                          "Package Picked Up"
                                        )
                                      }
                                      checked={item.orderStatus.includes(
                                        "Package Picked Up"
                                      )}
                                      disabled={item.orderStatus.includes(
                                        "Package Picked Up"
                                      )}
                                      id='checkStatus'
                                      type='checkbox'
                                      style={{ width: 20, height: 20 }}
                                    />
                                    <label htmlFor='checkStatus'>
                                      Package Picked Up
                                    </label>
                                  </div> */}
                                </div>
                                {/* <div className={styles.otpSec}>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "1rem",
                                      alignItems: "center",
                                    }}>
                                    <input
                                      onChange={(e) =>
                                        orderStatusChange(
                                          e,
                                          item._id,
                                          "Reached Warehouse"
                                        )
                                      }
                                      checked={item.orderStatus.includes(
                                        "Reached Warehouse"
                                      )}
                                      disabled={item.orderStatus.includes(
                                        "Reached Warehouse"
                                      )}
                                      id='warehouse'
                                      type='checkbox'
                                      style={{ width: 20, height: 20 }}
                                    />
                                    <label htmlFor='warehouse'>
                                      Reached Warehouse
                                    </label>
                                  </div>
                                </div> */}
                              </>
                            ) : (
                              <>
                                <div className={styles.otpSec}>
                                  <Button
                                    text={"Send OTP"}
                                    onClickHandler={(e) => sendOtp(e, item._id)}
                                    style={{
                                      width: "100%",
                                      background: "#122333",
                                      color: "#fff",
                                      border: "none",
                                      borderRadius: "10px",
                                      padding: "0.7rem 2rem",
                                      whiteSpace: "nowrap",
                                    }}>
                                    Send OTP
                                  </Button>
                                </div>
                                <div className={styles.verifyOtps}>
                                  <InputField
                                    type='text'
                                    onChangeHandler={(e) => {
                                      setOTP(e.target.value);
                                    }}
                                    label='Enter OTP'
                                  />
                                  <Button
                                    text={"Verify OTP"}
                                    onClickHandler={(e) =>
                                      verifyOtp(e, item._id)
                                    }
                                    style={{
                                      width: "100%",
                                      background: "#122333",
                                      color: "#fff",
                                      border: "none",
                                      borderRadius: "10px",
                                      padding: "0.7rem 2rem",
                                      whiteSpace: "nowrap",
                                    }}>
                                    Verify OTP
                                  </Button>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  );
                }
              })}
            </>
          )}
        </div>
      </div>
      {openMap && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper}>
            <div className={styles.close}>
              <CloseIcon
                onClick={() => {
                  setOpenMap(false);
                  setNoRouteFound(false);
                  setDirectionsResponse(null);
                }}
              />
            </div>
            <div className={styles.headerAddr}>
              <h1>Directions</h1>
            </div>
            {!noRouteFound ? (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "350px",
                }}
                center={{
                  lat: senderLatLong.lat,
                  lng: senderLatLong.lng,
                }}
                zoom={15}>
                {directionsResponse !== null && (
                  <DirectionsRenderer directions={directionsResponse} />
                )}
              </GoogleMap>
            ) : (
              <div style={{ textAlign: "center" }}>
                <h1>No Route Found</h1>
              </div>
            )}
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </>
  );
}

export default PickUp;
