import React, {useState} from 'react'
import SideBar from "../../../components/Navbar/SideBar"
import styles from "./Admin.module.css"
import Dashboard from './Dashboard'
import Profile from './Profile'
import Management from './Management'
import WayBillDashboard from './WayBillDashboard'

function Admin() {

  const [nav,setNav] = useState(0)

  return (
    <div className={styles.container}>
    <div className={styles.wrapper}>
        <div className={styles.sidebar}>
            <SideBar placeOrder={true} wayBill={true} setNavHandler={setNav} navHandler={nav} secondTitle={"Management"} thirdTitle={"Way Bill"}/>
        </div>
    </div>
    <div className={styles.content}>
        {
            nav === 0 &&
            <Dashboard />
        }
          {
            nav === 1 &&
            <Management />
        }
          {
            nav === 2 &&
            <Profile />
        }
          {
            nav === 3 &&
            <WayBillDashboard />
        }
    </div>
</div>
  )
}

export default Admin