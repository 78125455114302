/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./ManageClient.module.css";
import Button from "../../../components/Button/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link, useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import CloseIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Chip from "@mui/material/Chip";
import InputField from "../../../components/InputField/InputField";
import {toast} from "react-toastify"
function ManageClient() {
  const [loading, setLoading] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [showClientInfo, setShowClientInfo] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientTab, setClientTab] = useState(0);
  const [rows, setRows] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [deliverRows, setDeliverRows] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const { token } = useSelector((states) => states.admin);
  const navigate = useNavigate();
  const [editClientData, setEditClientData] = useState({
    cities:[]
  });
  const [availability, setAvailability] = useState([]);

  const getAvailability = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/get`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios(config);
    setAvailability([...result.data.data]);
  };

  const getAllDeliveredOrders = async (_id) => {
    const config = {
      url:
        process.env.REACT_APP_BASE_URL +
        `/order/get_all_delivered_orders/${_id}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setClient({
      ...clients.filter((client) => client._id === _id)[0],
    });
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data.data);
      if (result.data.code) {
        let data = [];
        for (let i = 0; i < result.data.data.length; i++) {
          data.push({
            id: i + 1,
            orderId: result.data.data[i]._id,
            receiverFullName: result.data.data[i].recipientName,
            date: result.data.data[i].deliveredDate,
            Payment: result.data.data[i].paymentType=== 'cash' ? "COD" : result.data.data[i].paymentType,
            amount: parseFloat(result.data.data[i].totalAmount).toFixed(2),
            serviceCharge: parseFloat(
              result.data.data[i].deliveryTypeAmount
            ).toFixed(2),
            outStanding: parseFloat(
              parseFloat(result.data.data[i].totalAmount) -
                parseFloat(result.data.data[i].deliveryTypeAmount)
            ).toFixed(2),
            action: result.data.data[i].client._id,
            isInvoice: result.data.data[i].isInvoiceGenerated,
            invoiceId: result.data.data[i].invoice,
          });
        }
        console.log(data);
        setDeliverRows([...data]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getClientDetails = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/client/get_all_client`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      if (result.data.code) {
        let data = [];
        for (let i = 0; i < result.data.data.length; i++) {
          data.push({
            id: i + 1,
            fullName: result.data.data[i].fullName,
            emailId: result.data.data[i].emailId,
            phoneNumberOne: result.data.data[i].phoneNumberOne,
            defaultAddress: result.data.data[i].defaultAddress,
            companyName: result.data.data[i].companyName,
            totalPaymentDue: result.data.data[i].totalPaymentDue,
            action: result.data.data[i]._id,
            active: result.data.data[i].active,
          });
        }
        setRows([...data]);
        setClients([...result.data.data]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  console.log(client);

  useEffect(() => {
    getClientDetails();
    getAvailability();
  }, []);

  const invoiceSelectHandler = (e, _id, cid) => {
    setClientId(cid);
    if (e.target.checked) {
      setSelectedInvoice([...selectedInvoice, _id]);
    } else {
      setSelectedInvoice([...selectedInvoice.filter((id) => id !== _id)]);
    }
  };

  const handleShow = (e, values) => {
    setShowClientInfo(true);
  };

  const handleShowEdit = async (e, values) => {
    setEditShow(true);
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/client/get_client/${values}`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      setEditClientData({
        full_name: result.data.data.user.fullName,
        user_name: result.data.data.user.username,
        email_address: result.data.data.user.emailId,
        whatsapp: result.data.data.user.whatsappNo,
        mobile_number: result.data.data.user.phoneNumberOne,
        alt_mobile: result.data.data.user.phoneNumberTwo,
        org_name: result.data.data.user.companyName,
        about_org: result.data.data.user.aboutOrganization,
        cities: [...result.data.data.user.cities]
      });
      setClientId(result.data.data.user._id)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };


  const blockUnblockClientHandler = async (e, id, active) => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/client/block_and_unblock`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
        active: !active,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data.data);
      if(result.data.data.active){
        toast.success('Client unblocked successfully')
      }else{
        toast.success('Client blocked successfully')
      }
      getClientDetails();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const invoiceStatus = (cellValues) => {
    return (
      <Chip
        label={cellValues.row.isInvoice ? "Done" : "Pending"}
        color={cellValues.row.isInvoice ? "success" : "warning"}
        variant='outlined'
      />
    );
  };

  const editClientHandler = async (e) => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/client/update_client`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data:{
        id:clientId,
        username : editClientData.user_name,
        emailId :editClientData.email_address,
        phoneNumberOne : editClientData.mobile_number,
        phoneNumberTwo :editClientData.alt_mobile,
        whatsappNo:editClientData.whatsapp,
        companyName : editClientData.org_name,
        aboutOrganization:editClientData.about_org,
        fullName :editClientData.full_name,
        cities : [...editClientData?.cities],
      }
    };
    setLoading(true);
    try {
      const result = await axios(config);
      setEditShow(false);
      setLoading(false);
      getClientDetails()
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const col = [
    {
      field: "id",
      headerName: "S.No",
      width: 50,
      minWidth: 0,
    },
    {
      field: "orderId",
      headerName: "Tracking Id",
      width: 250,
      minWidth: 0,
    },
    {
      field: "receiverFullName",
      headerName: "Receiver Name",
      width: 150,
      minWidth: 0,
    },
    {
      field: "date",
      headerName: "Delivery Date",
      width: 200,
      minWidth: 0,
    },
    {
      field: "Payment",
      headerName: "Payment Mode",
      width: 150,
      minWidth: 0,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      minWidth: 0,
    },
    {
      field: "serviceCharge",
      headerName: "Service Charge",
      width: 120,
      minWidth: 0,
    },
    {
      field: "outStanding",
      headerName: "Outstanding",
      width: 100,
      minWidth: 0,
    },
    {
      field: "Invoice",
      renderCell: (cellValues) => {
        return (
          <input
            type='checkbox'
            disabled={cellValues.row.isInvoice}
            defaultChecked={cellValues.row.isInvoice}
            onChange={(e) =>
              invoiceSelectHandler(
                e,
                cellValues.row.orderId,
                cellValues.row.action
              )
            }
            style={{ width: 20, height: 20 }}
          />
        );
      },
      align: "center",
      headerAlign: "center",
      fixed: true,
      width: 100,
      minWidth: 0,
    },
    {
      field: "Invoice Status",
      renderCell: invoiceStatus,
      align: "center",
      headerAlign: "center",
      width: 150,
      minWidth: 0,
    },
    {
      field: "View",
      renderCell: (cellValues) => {
        if (cellValues.row.isInvoice) {
          return (
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "green",
              }}
              to={`/invoice/${cellValues.row.invoiceId}/${token}`}
              target='_blank'>
              View <ArrowOutwardIcon />
            </Link>
          );
        }

        return <></>;
      },
      align: "center",
      headerAlign: "center",
      width: 100,
      minWidth: 0,
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 50,
      minWidth: 0,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 150,
      minWidth: 0,
    },
    {
      field: "emailId",
      headerName: "Email Address",
      width: 250,
      minWidth: 0,
    },
    {
      field: "phoneNumberOne",
      headerName: "Phone Number",
      width: 150,
      minWidth: 0,
    },
    {
      field: "defaultAddress",
      headerName: "Address",
      width: 250,
      minWidth: 0,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 200,
      minWidth: 0,
    },
    {
      field: "totalPaymentDue",
      headerName: "Payment Due",
      width: 100,
      minWidth: 0,
      align: "center",
    },
    {
      field: "Action",
      renderCell: (cellValues) => {
        return (
          <div className={styles.wrapperAction}>
            <span>
              <RemoveRedEyeIcon
                onClick={(e) => {
                  handleShow(e, cellValues);
                  getAllDeliveredOrders(cellValues.row.action);
                }}
                className={styles.action}
              />
            </span>
            <span>
              <EditIcon
                onClick={(e) => {
                  handleShowEdit(e, cellValues.row.action);
                }}
                className={styles.action}
              />
            </span>
            <span>
              {cellValues.row.active ? (
                <LockIcon
                  onClick={(e) =>
                    blockUnblockClientHandler(e, cellValues.row.action, true)
                  }
                  className={styles.action}
                />
              ) : (
                <NoEncryptionGmailerrorredIcon
                  onClick={(e) =>
                    blockUnblockClientHandler(e, cellValues.row.action, false)
                  }
                  className={styles.action}
                />
              )}
            </span>
          </div>
        );
      },
      align: "center",
      headerAlign: "center",
      fixed: true,
      width: 200,
      minWidth: 0,
    },
  ];

  const clickHandler = (e) => {
    navigate("/addClient");
  };

  const generateInvoice = async (e) => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/invoice/add_invoice`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      data: {
        order_ids: selectedInvoice,
        client_id: clientId,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data.data);
      getAllDeliveredOrders(clientId);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const changeHandler = (e) => {
    if (e.target.name === "cities") {
      if (e.target.checked) {
        setEditClientData({
          ...editClientData,
          cities: [...editClientData.cities, e.target.value],
        });
      } else {
        setEditClientData({
          ...editClientData,
          cities: [
            ...editClientData.cities.filter((city) => city !== e.target.value),
          ],
        });
      }
      return;
    }
    setEditClientData({
      ...editClientData,
      [e.target.name]: e.target.value,
    });
  };

  console.log(editClientData);

  return (
    <>
      <div className={styles.clientContainer}>
        <div className={styles.addClient}>
          <Button
            text={"Add Client"}
            onClickHandler={clickHandler}
            style={{
              background: "#122333",
              color: "#fff",
              border: "none",
              borderRadius: "10px",
              padding: "0.7rem 2rem",
            }}
          />
        </div>
        <div className={styles.allClient}>
          <DataGrid
            style={{ backgroundColor: "#fff" }}
            rows={rows}
            disableRowSelectionOnClick
            columns={columns}
            autoHeight
            slots={{
              toolbar: GridToolbar,
            }}
            localeText={{ noRowsLabel: "Uploaded your data" }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      </div>
      {showClientInfo && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setShowClientInfo(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Client Info</h1>
            </div>
            <div className={styles.inputFields}>
              <div className={styles.clientTab}>
                <h2
                  onClick={(e) => setClientTab(0)}
                  className={clientTab === 0 && styles.tabActive}>
                  Information
                </h2>
                <h2
                  onClick={(e) => setClientTab(1)}
                  className={clientTab === 1 && styles.tabActive}>
                  Invoice
                </h2>
              </div>
              {clientTab === 0 && (
                <div className={styles.showInfo}>
                  <div className={styles.contentInfo}>
                    <div className={styles.row}>
                      <p>
                        Org Name : <strong>{client?.companyName}</strong>
                      </p>
                    </div>
                    <div className={styles.row}>
                      <p>
                        About Org Name :{" "}
                        <strong>{client?.aboutOrganization}</strong>
                      </p>
                    </div>
                    <div className={styles.row}>
                      <p>
                        Name : <strong>{client?.fullName}</strong>
                      </p>
                      <p>
                        User Name : <strong>{client?.username}</strong>
                      </p>
                    </div>
                    <div className={styles.row}>
                      <p>
                        Mobile No. : <strong>{client?.phoneNumberOne}</strong>
                      </p>
                      <p>
                        Alt Mobile No. :{" "}
                        <strong>{client?.phoneNumberTwo}</strong>
                      </p>
                    </div>
                    <div className={styles.row}>
                      <p>
                        Whatsapp No. : <strong>{client?.whatsappNo}</strong>
                      </p>
                      <p>
                        Email : <strong>{client?.emailId}</strong>
                      </p>
                    </div>
                    <div className={styles.row}>
                      <p>
                        Default Address :{" "}
                        <strong>{client?.defaultAddress}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {clientTab === 1 && (
                <div className={styles.invoiceSec}>
                  <div>
                    <DataGrid
                      style={{ backgroundColor: "#fff" }}
                      rows={deliverRows}
                      disableRowSelectionOnClick
                      columns={col}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      autoHeight
                      localeText={{ noRowsLabel: "Uploaded your data" }}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                    />
                  </div>
                  <div className={styles.saveBtn}>
                    <Button
                      text={"Generate Invoice"}
                      onClickHandler={generateInvoice}
                      style={{
                        background: "#122333",
                        color: "#fff",
                        border: "none",
                        borderRadius: "10px",
                        padding: "0.7rem 2rem",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {editShow && (
        <div className={styles.loginWrapper}>
          <div className={styles.editWrapper} style={{maxHeight:'600px',overflowY:'auto'}}>
            <div className={styles.close}>
              <CloseIcon onClick={() => setEditShow(false)} />
            </div>
            <div className={styles.headerAddr}>
              <h1>Client Edit</h1>
            </div>
            <div className={styles.inputFields}>
              <div className={styles.fields}>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Full Name'
                    name='full_name'
                    value={editClientData.full_name}
                  />
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='User Name'
                    name='user_name'
                    value={editClientData.user_name}
                  />
                </div>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Email Address'
                    name='email_address'
                    value={editClientData.email_address}
                  />
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='WhatsApp Number'
                    name='whatsapp'
                    value={editClientData.whatsapp}
                  />
                </div>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Mobile Number'
                    name='mobile_number'
                    value={editClientData.mobile_number}
                  />
                  <InputField
                    type='number'
                    onChangeHandler={changeHandler}
                    label='Alternate Mobile'
                    name='alt_mobile'
                    value={editClientData.alt_mobile}
                  />
                </div>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Organization Name'
                    name='org_name'
                    value={editClientData.org_name}
                  />
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='About Organization'
                    name='about_org'
                    value={editClientData.about_org}
                  />
                </div>
                <div>
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='Organization Name'
                    name='org_name'
                    value={editClientData.org_name}
                  />
                  <InputField
                    type='text'
                    onChangeHandler={changeHandler}
                    label='About Organization'
                    name='about_org'
                    value={editClientData.about_org}
                  />
                </div>
                <div className={styles.checkWrapper}>
                  <div>
                    <p>Choose Available cities</p>
                  </div>
                  <div className={styles.checkBoxContainer}>
                    {
                      // eslint-disable-next-line array-callback-return
                      availability.map((cities, index) => {
                        if (cities.pickupAvailability === "Available") {
                          return (
                            <div className={styles.checkBox}>
                              <input
                                onChange={changeHandler}
                                id={`check${index}`}
                                type='checkbox'
                                name='cities'
                                key={index}
                                value={cities.city}
                                checked={editClientData?.cities?.includes(cities.city)}
                                style={{ width: 20, height: 20 }}
                              />
                              <label htmlFor={`check${index}`}>
                                {cities.city}
                              </label>
                            </div>
                          );
                        }
                      })
                    }
                  </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                    <Button
                      text={"Update"}
                      onClickHandler={editClientHandler}
                      style={{
                        background: "#122333",
                        color: "#fff",
                        border: "none",
                        borderRadius: "10px",
                        padding: "0.7rem 2rem",
                      }}
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader loading={loading} />
    </>
  );
}

export default ManageClient;
