import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function RiderProtected({children}) {
    const navigate = useNavigate();
    const { id, token } = useSelector((states) => states.rider);
  
    if (!id && !token) {
      return <Navigate to={'/rider'} />;
    }
    return <>{children}</>;
}

export default RiderProtected