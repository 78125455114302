import React, { useEffect, useState } from "react";
import styles from "./WayBillDashboard.module.css";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { changeDateFormat } from "../../../utils";
import Button from "../../../components/Button/Button";

function WayBillDashboard() {
  const [rows, setRows] = useState([]);
  const { token } = useSelector((states) => states.admin);
  const [loading, setLoading] = useState(false);
  const [saveData, setSaveData] = useState([]);
  const [PDFUrl, setPDFUrl] = useState("");

  const wayBillAcceptHandler = (e) => {
    const d = [...rows]
    if (e.target.checked) {
      let total  = saveData.length + 1 

      if(total <= 5){
        setSaveData([...saveData,e.target.value])
      }

      if(total >= 5){
          d.forEach(item => {
            if([...saveData,e.target.value].includes(item._id)){
              item.editable = false
            }else{
              item.editable = true
            }
          })
          setRows(d)
          return
        }
    } else {
      setSaveData([...saveData.filter((id) => id !== e.target.value)]);
      d.forEach(item => {
          item.editable = false
      })
      setRows(d)
    }
  };

  console.log(saveData);

  const acceptForWayBill = (cellValues) => {
    return (
      <input
        type='checkbox'
        value={cellValues.row._id}
        disabled={cellValues.row.editable}
        onChange={wayBillAcceptHandler}
        style={{ width: 20, height: 20 }}
      />
    );
  };

  const generateRows = (array) => {
    let data = [];
    for (let i = 0; i < array.length; i++) {
      data.push({
        id: i + 1,
        recipientName: array[i].recipientName,
        recipientCity: array[i].recipientCity,
        pickupDate: changeDateFormat(array[i].pickUpDate),
        isPaymentSuccess: array[i].isPaymentSuccess,
        orderStatus: array[i].orderStatus[array[i].orderStatus.length - 1],
        isPaymentSettled: array[i].isPaymentSettled,
        totalAmount: array[i].totalAmount,
        actualProductAmount: array[i].actualProductAmount,
        _id: array[i]._id,
        editable:false,
        orderAccepted: array[i].orderAccepted,
        paymentType: array[i].paymentType,
        wayBillStatus: array[i].wayBillStatus,
        rider: array[i]?.riderDeliver?._id,
        isInvoiceGenerated: array[i].isInvoiceGenerated,
        isPaymentCollectedFromRider: array[i].isPaymentCollectedFromRider,
        cancel: array[i]?.cancel,
        deliverComplete: array[i]?.deliverComplete,
      });
    }
    setRows([...data]);
  };

  const getOrderDetails = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/order/get_all_orders`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      console.log(result.data);
      if (result.data.code) {
        generateRows(result.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 50,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pickupDate",
      headerName: "Pickup Date",
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isPaymentSuccess",
      headerName: "Payment Status",
      renderCell: (cellValues) => {
        return (
          <Chip
            label={cellValues.row.isPaymentSuccess ? "Done" : "Pending"}
            color={cellValues.row.isPaymentSuccess ? "success" : "warning"}
            variant='outlined'
          />
        );
      },
      width: 150,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      align: "center",
      width: 150,
      minWidth: 0,
      headerAlign: "center",
    },
    {
      field: "orderStatus",
      renderCell: (cellValues) =>
        cellValues.row.cancel ? (
          <Chip label={cellValues.row.orderStatus} color='error' />
        ) : cellValues.row.deliverComplete ? (
          <Chip label={cellValues.row.orderStatus} color='success' />
        ) : (
          <Chip label={cellValues.row.orderStatus} color='primary' />
        ),
      headerName: "Order Status",
      width: 250,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "wayBillStatus",
      renderCell: (cellValues) => {
        return (
          <Chip
            label={cellValues.row.wayBillStatus ? "Done" : "Pending"}
            color={cellValues.row.wayBillStatus ? "success" : "warning"}
            variant='outlined'
          />
        );
      },
      headerName: "Way Bill Status",
      width: 170,
      minWidth: 0,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Generate Way Bill",
      renderCell: acceptForWayBill,
      align: "center",
      headerAlign: "center",
      width: 170,
      minWidth: 0,
    },
  ];

  useEffect(() => {
    getOrderDetails();
  }, []);

  const wayBillGenerateHandler = async (e) => {
    if (saveData.length === 0) return;

    const config = {
      url: process.env.REACT_APP_BASE_URL + `/order/way_bill_generate`,
      method: "post",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
      data: {
        data: saveData,
      },
    };
    setLoading(true)

    try {
      const wayBill = await axios(config);
      const blob = new Blob([wayBill.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setPDFUrl(url);
      setLoading(false)
      getOrderDetails()
    } catch (err) {
      console.log(err);
    setLoading(false)
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Way Bill Dashboard</h2>
        </div>
        <div className={styles.dataTable}>
          <DataGrid
            style={{ backgroundColor: "#fff" }}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            autoHeight
            localeText={{ noRowsLabel: "Data not found" }}
            slots={{
              toolbar: GridToolbar,
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
        <p><strong style={{color:'red'}}>Note:- Please only select 5 orders at a time.</strong></p>
        <div className={styles.uploadBtn}>
          <Button
            text={"Generate Way Bill"}
            onClickHandler={wayBillGenerateHandler}
            style={{
              background: "#122333",
              color: "#fff",
              border: "none",
              borderRadius: "10px",
              padding: "0.7rem 2rem",
            }}
          />
           {
            PDFUrl !== "" &&
            <div>
              <a href={PDFUrl} download='Way Bill'>Download Way Bill</a>
            </div>
          }
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
}

export default WayBillDashboard;
