import React, { useState,useEffect } from 'react'
import styles from "./Navbar.module.css"
import { Link, useLocation } from 'react-router-dom'
import Login from '../Login/Login'
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

function NavBar({options}) {

    const [mobileNav, setMobileNav] = useState(false);
    const [login, setLogin] = useState(false);
    const [width, setWidth] = useState(1030);
    const location = useLocation()
    // eslint-disable-next-line no-unused-vars
    const [active, setActive] = useState(location.pathname);
    console.log(location.pathname)
    const loginHandler = () => {
        setLogin(!login)
    }
    const activeHandler = (id) => {
        setActive(id)
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
          setWidth(window.innerWidth);
        });
      }, []);

    return (
        <>
            <div className={styles.navWrapper}>
                <div className={styles.navBarContainer} {
                    ...{
                        ...((!options && width > 1029)&& {style:{padding:'1rem 0'}})
                    }
                }>
                    <div className={styles.logoWrapper}>
                        <div className={styles.logo}>
                            <img className={styles.mirroredImage} src='/img/logo.png' alt='logo' />
                        </div>
                        AL KANARI DELIVERY SERVICES
                    </div>
                    <div className={styles.deskTop}>
                        {
                            options &&
                            <div className={styles.navOptions}>
                                <ul>
                                    <li> <Link className={location.pathname === '/' && styles.active} to={"/"}> Home </Link></li>
                                    <li> <Link className={location.pathname === '/aboutUs' && styles.active} to={"/aboutUs"}> About Us </Link></li>
                                    {/* <li> <Link onClick={(e) => activeHandler(2)} className={active === location.pathname && styles.active} to={"#"}> Services </Link></li> */}
                                    <li> <Link onClick={(e) => {
                                        activeHandler(3)
                                        loginHandler()
                                    }} className={location.pathname === '' && styles.active} to={"#"} > Login </Link></li>
                                    <li> <Link className={location.pathname === '/getInTouch' && styles.active} to={"/getInTouch"}> Get in Touch </Link></li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.mobile}>
                    {
                        options &&
                        <div className={styles.bars} onClick={(e) => (setMobileNav(true))}>
                            <MenuIcon />
                        </div>
                    }
                    <div className={`${styles.navOptions} ${styles.mobileNav} ${mobileNav ? styles.show : styles.hide}`}>
                        <div className={styles.cross} onClick={(e) => (setMobileNav(false))}>
                            <CloseIcon />
                        </div>
                        <ul>
                            <li> <Link className={location.pathname === '/' && styles.active} to={"/"}> Home </Link></li>
                            <li> <Link className={location.pathname === '/aboutUs' && styles.active} to={"/aboutUs"}> About Us </Link></li>
                            {/* <li> <Link className={location.pathname === 2 && styles.active} to={"#"}> Services </Link></li> */}
                            <li> <Link onClick={(e) => {
                                activeHandler(3)
                                loginHandler()
                            }} className={location.pathname === '' && styles.active} to={"#"} > Login </Link></li>
                            <li> <Link className={location.pathname === '/getInTouch' && styles.active} to={"/getInTouch"}> Get in Touch </Link></li>
                        </ul>
                        {/* <Button
                        text = {"Order Online"}
                        style={{
                            background: 'linear-gradient(90deg, #ED4C21 50%, #122333 50%)',
                            color:'#fff'
                        }}
                        /> */}
                    </div>
                </div>
            </div>
            {
                login && <Login
                    loginHandler={loginHandler}
                />
            }
        </>
    )
}

export default NavBar