import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { addClient, clientAuth } from "../../redux/slices/client";
import Loader from "../Loader/Loader";
import store from "../../redux/store";
import { toast } from "react-toastify";
import axios from "axios";

let persistor = persistStore(store);

function Login({ loginHandler }) {
  const [flagForgot, setFlagForgot] = useState(true);
  const dispatch = useDispatch();
  const { id, token } = useSelector((states) => states.client);
  const [login, setLogin] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reset_email, setReset_email] = useState("");
  const onChangeHandler = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!id && !token) {
      return;
    }
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token]);

  const getUserDetails = () => {
    dispatch(addClient({ id: id, token: token })).then((res) => {
      setLoading(false);
      navigate("/client");
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(clientAuth({ ...login }))
      .then((res) => {
        if (res.payload.code === 0) {
          setLoading(false);
          toast.error(res.payload.message);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onSubmitResetHandler = async (e) => {
    if (reset_email === "") {
      toast.error("Email is required");
      return;
    }

    const config = {
      url: process.env.REACT_APP_BASE_URL + `/generate_forgot_password_link`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: reset_email,
        link_for: "client",
      },
    };
    setLoading(true);
    try {
      const result = await axios(config);
      if (result.data.code === 1) {
        toast.success(result.data.message);
        setReset_email('')
      } else {
        toast.error(result.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return ReactDOM.createPortal(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className={styles.loginContainer} onClick={loginHandler}></div>
        <div className={styles.loginWrapper}>
          {flagForgot ? (
            <>
              <div className={styles.header}>
                <h1>Login to Your Account</h1>
                <p>Welcome To Al Kanari Delivery Services</p>
                {/* <p>WE ARE HERE TO DELIVER...</p> */}
              </div>
              <div className={styles.inputFields}>
                <div className={styles.inputs}>
                  <input
                    onChange={onChangeHandler}
                    type='email'
                    name='emailId'
                    placeholder='Email'
                    value={login.emailId}
                  />
                  <input
                    onChange={onChangeHandler}
                    type='password'
                    name='password'
                    placeholder='Password'
                    value={login.password}
                  />
                  <div className={styles.btn}>
                    <button onClick={onSubmitHandler}>
                      Login to Your Account
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.forgotPassword}>
                <Link
                  to={"#"}
                  onClick={(e) => setFlagForgot(false)}
                  style={{ color: "#fff" }}>
                  Forgot Password?
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className={styles.header}>
                <h1>Reset Your Account</h1>
                <p>Welcome To Al Kanari Delivery Services</p>
                {/* <p>WE ARE HERE TO DELIVER...</p> */}
              </div>
              <div className={styles.inputFields}>
                <div className={styles.inputs}>
                  <input
                    onChange={(e) => setReset_email(e.target.value)}
                    type='email'
                    placeholder='Enter Email here'
                    value={reset_email}
                  />
                  <div className={styles.btn}>
                    <button onClick={onSubmitResetHandler}>Submit</button>
                  </div>
                </div>
              </div>
              <div className={styles.forgotPassword}>
                <Link
                  to={"#"}
                  onClick={(e) => setFlagForgot(true)}
                  style={{ color: "#fff" }}>
                  Back To Login
                </Link>
              </div>
            </>
          )}
        </div>
        <Loader loading={loading} />
      </PersistGate>
    </Provider>,
    document.getElementById("login")
  );
}

export default Login;
