import React, { useState } from 'react'
import styles from './Callback.module.css';
import Button from '../Button/Button';
import axios from 'axios';
import { toast } from 'react-toastify';

const Callback = ({ theme, setIsLoading }) => {
    const [queryData, setQueryData] = useState({
        name: "",
        mobileNo: "",
        emailId: "",
        subject: "",
        query: "",
        isUrgent: false,
    });

    const nameHandler = (event) => {
        setQueryData({ ...queryData, name: event.target.value });
    }
    const mobileHandler = (event) => {
        setQueryData({ ...queryData, mobileNo: event.target.value });
    }
    const emailHandler = (event) => {
        setQueryData({ ...queryData, emailId: event.target.value });
    }
    const subjectHandler = (event) => {
        setQueryData({ ...queryData, subject: event.target.value });
    }
    const queryHandler = (event) => {
        setQueryData({ ...queryData, query: event.target.value });
    }
    const isUrgentHandler = (event) => {
        setQueryData({ ...queryData, isUrgent: event.target.checked });
    }

    const sendMessageHandler = async (e) => {
        e.preventDefault();
        if(!queryData.emailId.length>0  && !queryData.name.length>0 && !queryData.subject.length>0 && !queryData.mobileNo.length>0 && !queryData.query.length>0){
            toast.error("Please fill all the required fields.");
        }
        else
        {
            let data = {
                "name": queryData.name,
                "email": queryData.emailId,
                "subject": queryData.subject,
                "phone": queryData.mobileNo,
                "message": queryData.query,
                "enquryType": queryData.isUrgent ? "Critical" : "Normal",
                "isFullfilled": false
            };
    
            let config = {
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + "/contact/add_contact",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    ...data,
                }
            };
            setIsLoading(true);
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    setIsLoading(false);
                    if (response.data.code === 1) {
                        toast.success(response.data.message);
                        
                    }
                    else {
                        toast.error(response.data.message);
                    }
                    setQueryData({
                        name: "",
                        mobileNo: "",
                        emailId: "",
                        subject: "",
                        query: "",
                        isUrgent: false,
                    });
    
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    toast.error(error);
                });
        }
        

    }
    const themeVal = theme ? styles.contact_Container : styles.light;
    const blackLineVal = theme ? styles.blackLine : styles.orangeLine;
    return (
        <div>
            <div className={themeVal}>
                <div className={styles.left_container}>
                    <h1>Request <br /> a Callback</h1>
                    <div className={blackLineVal}></div>
                    <p>It only takes 30 seconds and then we'll call you <br />
                        back, Monday to Friday, 9am-5pm. Easy.</p>

                    <span>Or Contact Us at +971-52-329-2964</span>
                </div>
                <form className={styles.formContainer}>
                    <div className={styles.firstRowInput}>
                        <input className={styles.input} onChange={nameHandler} placeholder='Name' type="text" />
                        <input className={styles.input} onChange={mobileHandler} placeholder='Mobile Number' type="text" />
                    </div>
                    <div className={styles.row}>
                        <input className={styles.input} onChange={emailHandler} placeholder='Email' type="email" />
                    </div>
                    <div className={styles.row}>
                        <input className={styles.input} onChange={subjectHandler} placeholder='Subject' type="text" />
                    </div>
                    <div className={styles.row} style={{ marginTop: 10 }}>
                        <div className={styles.checkBox}>
                            <input id={`isUrgent`} type='checkbox' style={{ width: 20, height: 20 }} onChange={isUrgentHandler} />
                            <label htmlFor={`isUrgent`}>{`Urgent !`}</label>
                        </div>
                    </div>
                    <div className={styles.textareaContainers}>
                        <textarea placeholder='MESSAGE' onChange={queryHandler} />
                    </div>
                    <div className={styles.sndMsg}>
                        <Button
                            text={"SEND MESSAGE"}
                            style={{
                                color: theme ? '#ED4C21' : "#fff",
                            }}
                            onClickHandler={sendMessageHandler}
                            isDark={theme}
                        />
                    </div>
                </form>
            </div>

        </div>
    )
}

export default Callback