/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./AddRider.module.css";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import { useSelector } from "react-redux";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { isEmailValid } from "../../../utils/Validations";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

function AddRider() {
  const navigate = useNavigate();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((states) => states.admin);
  const [riderData, setRiderData] = useState({
    rider_name: "",
    user_name: "",
    bike_number: "",
    email_address: "",
    mobile_number: "",
    allocated_city: [],
    whatsapp: "",
  });
  const [addrData, setAddrData] = useState({
    address: "",
  });

  const getAvailability = async () => {
    const config = {
      url: process.env.REACT_APP_BASE_URL + `/availability/get`,
      method: "get",
      headers: {
        secret_key: process.env.REACT_APP_SECRET_KEY,
        Authorization: `Bearer ${token}`,
      },
    };
    const result = await axios(config);
    setAvailability([...result.data.data]);
  };

  useEffect(() => {
    getAvailability();
  }, []);

  const submitHandler = async (e) => {
    if (
      !riderData.user_name.length > 0 &&
      !riderData.email_address.length > 0 &&
      !riderData.address.length > 0 &&
      !riderData.whatsapp.length > 0 &&
      !riderData.bike_number.length > 0 &&
      !riderData.allocated_city.length > 0 &&
      !riderData.rider_name.length > 0
    ) {
      toast.error("Please fill all required fields.");
    } else if (!isEmailValid(riderData.email_address)) {
      toast.error("Please enter valid email adddress.");
    } else if (riderData.mobile_number < 10) {
      toast.error("Please enter valid mobile number.");
    } else {
      setLoading(true);
      const config = {
        url: process.env.REACT_APP_BASE_URL + `/rider/add_rider`,
        method: "post",
        headers: {
          secret_key: process.env.REACT_APP_SECRET_KEY,
          Authorization: `Bearer ${token}`,
        },
        data: {
          username: riderData.user_name,
          emailId: riderData.email_address,
          phoneNumber: riderData.mobile_number,
          riderAddress: addrData.address,
          whatsappNo: riderData.whatsapp,
          riderBikeNo: riderData.bike_number,
          riderAllocatedCity: riderData.allocated_city,
          riderName: riderData.rider_name,
        },
      };
      const result = await axios(config);
      console.log(result);
      if (result.data.code) {
        setLoading(false);
        navigate("/dashboard");
      }else{
        setLoading(false);
      }
    }
  };

  const changeHandler = (e) => {
    setRiderData({
      ...riderData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event) => {
    setRiderData({
      ...riderData,
      allocated_city:
        typeof value === "string"
          ? event.target.value.split(",")
          : event.target.value,
    });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.head}>
            <h2>Add Rider</h2>
            <span
              className={styles.backButton}
              onClick={(e) => navigate("/dashboard")}>
              <ArrowBackIcon />
            </span>
          </div>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <div className={styles.rightStaple}>
                <form>
                  <div className={styles.fields}>
                    <div>
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        label='Rider Name'
                        name='rider_name'
                        value={riderData.rider_name}
                      />
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        label='User Name'
                        name='user_name'
                        value={riderData.user_name}
                      />
                    </div>
                    <div>
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        label='Email Address'
                        name='email_address'
                        value={riderData.email_address}
                      />
                    </div>
                    <div>
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        label='Mobile Name'
                        name='mobile_number'
                        value={riderData.mobile_number}
                      />
                      <InputField
                        type='text'
                        onChangeHandler={changeHandler}
                        label='Bike Number'
                        name='bike_number'
                        value={riderData.bike_number}
                      />
                    </div>
                    <div>
                      <div className={styles.select}>
                        {/* <select name='allocated_city' multiple onChange={changeHandler}>
                          <option value=''>Allocated City</option>
                          {availability?.map((cities, index) => {
                            return (
                              <option key={index} value={cities.city}>
                                {cities.city}
                              </option>
                            );
                          })}
                        </select> */}
                           <FormControl sx={{ m: 1, width: 300 }}>
                         <InputLabel id="demo-multiple-chip-label">Select Allocated City</InputLabel>
                        <Select
                          labelId='demo-multiple-chip-label'
                          id='demo-multiple-chip'
                          multiple
                          value={riderData.allocated_city}
                          input={
                            <OutlinedInput
                              id='select-multiple-chip'
                              label='Select Allocated City'
                            />
                          }
                          onChange={handleChange}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                gap: 0.5,
                              }}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}>
                          {availability?.map((cities) => (
                            <MenuItem key={cities.city} value={cities.city}>
                              {cities.city}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className={styles.fields}>
                    {/* <div>
                                    <InputField 
                                      type='text'
                                      onChangeHandler={changeHandler}
                                      label = 'Address'
                                      name = 'address'
                                      value={riderData.address}
                                  />
                                </div> */}
                    <div></div>
                    <div className={styles.address}>
                      <div>
                        <Autocomplete
                          apiKey='AIzaSyDsewz1qgdIcG21YdoAgIlLQwY546IxdCU'
                          style={{ width: "100%" }}
                          required={true}
                          placeholder=''
                          onPlaceSelected={(place) => {
                            setAddrData({
                              ...addrData,
                              address: place.formatted_address,
                            });
                          }}
                          defaultValue={addrData.address}
                          options={{
                            componentRestrictions: { country: ["ae"] },
                            types: ["geocode", "establishment"],
                          }}
                        />
                        <label>Full Address</label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className={styles.continue}>
              <Button
                text={"Submit"}
                onClickHandler={submitHandler}
                style={{
                  background: "#122333",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  padding: "0.7rem 2rem",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Loader loading={loading} />
    </>
  );
}

export default AddRider;
