import React from 'react';
import './Footer.css';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <div className='footer__cont'>
            <div className="footer_parent">
                <div className="footer_heading">
                    <div className="left_footer_heading">
                        <a className="icon-1" href="https://twitter.com/akdelivery">
                            <TwitterIcon />
                        </a>
                        <a className="icon-2" href="https://www.facebook.com/alkanarideliveryservicesuae?mibextid=ZbWKwL">
                            <FacebookIcon />
                        </a>
                        <a className="icon-3" href="https://www.instagram.com/alkanarideliveryservicesuae/">
                            <InstagramIcon />
                        </a>
                    </div>
                    <div className="right_footer_heading">
                        <div className="call_us_text">
                            Call us to order delivery
                        </div>
                        <div className='phone'>
                            <div className="call_us_icon">
                                <PhoneIcon />
                            </div>
                            <div className="call_us_text">
                                +971-52-329-2964
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_body">
                    <div className="services_column">
                        <div className="col_heading">
                            Services
                        </div>
                        <ul className='spacing'>
                            <Link className='services_a' to="/#"><li className='services_li'>Rapid Delivery</li></Link>
                            <Link className='services_a' to="/#"><li className='services_li'>Next day delivery</li></Link>
                            <Link className='services_a' to="/#"><li className='services_li'>Real Time Tracking</li></Link>
                        </ul>
                    </div>
                    <div className="customer_care_column">
                        <div className="col_heading">
                            Customer Care
                        </div>
                        <ul className='spacing'>
                            <Link className='customer_a' to="/getInTouch"><li className='customer_li'>Get In Touch</li></Link>
                            <Link className='customer_a' to="/aboutUs"><li className='customer_li'>About Us</li></Link>
                        </ul>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="copyright">
                        © {new Date().getFullYear()}. All Rights Reserved
                    </div>
                    <div className="terms_conditions">
                        <a href="/">Terms of Use</a> and <a href="/">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer