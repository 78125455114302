import Client from "./Pages/Dashboards/Client/Client"
import Admin from "./Pages/Dashboards/Admin/Admin"
import Home from "./Pages/Home/Home"
import PageNotFound from "./Pages/PageNotFound/PageNotFound"
import AboutUs from "./Pages/AboutUs/AboutUs"
import GetInTouch from "./Pages/ContactUs/GetInTouch"
import AddClient from "./Pages/Dashboards/Admin/AddClient"
import AddRider from "./Pages/Dashboards/Admin/AddRider"
import Rider from "./Pages/Dashboards/Rider/Rider"


const routes = [
    {
        path:'/',
        Component:Home
    },
    {
        path:'aboutUs',
        Component:AboutUs
    },
    {
        path:'/getInTouch',
        Component:GetInTouch
    },
    {
        path:'/*',
        Component:PageNotFound
    },

]

const routesClient = [
    {
        path:'client',
        Component:Client
    }
]

const routesAdmin = [
    {
        path:'dashboard',
        Component:Admin
    },
    {
        path:'addClient',
        Component:AddClient
    },
    {
        path:'addRider',
        Component:AddRider
    },
]

const routesRider = [
    {
        path:'rider/dashboard',
        Component:Rider
    },
]

export { routes,routesClient,routesAdmin,routesRider }