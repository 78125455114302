import { configureStore,combineReducers } from "@reduxjs/toolkit";
import clientReducer from "./slices/client"
import riderReducer from "./slices/rider"
import adminReducer from "./slices/admin"
import localStorage from 'redux-persist/lib/storage'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';


const persistConfig = {
    key: 'alk',
    version:1,
    storage:localStorage,
};

const rootReducer =  combineReducers({
    admin:adminReducer,
    client:clientReducer,
    rider:riderReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer : persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    devTools : window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
})

export default store