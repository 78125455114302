import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function ClientProtected({ children }) {
  const navigate = useNavigate();
  const { id, token } = useSelector((states) => states.client);

  if (!id && !token) {
    return <Navigate to={'/'} />;
  }
  return <>{children}</>;
}

export default ClientProtected;
