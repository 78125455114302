import { createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import axios from "axios"
const initialState = {
    client:{},
    id:'',
    token : "",
    error:""
}

export const clientAuth = createAsyncThunk(
    'clientAuth/login',
    async (loginData) => {
        const config = {
            url:process.env.REACT_APP_BASE_URL + "/client/login",
            method:'post',
            data:{
                emailId : loginData.emailId,
                password : loginData.password
            }
        }
        try{
            const result = await axios(config)       
            if(result.data.code){
                return result.data.data
            }else{
                return result.data
            }
        }catch(err) {
            return err 
        }
    }
)

export const addClient = createAsyncThunk(
    'addClient/add',
    async (data) => {
        const config = {
            url:process.env.REACT_APP_BASE_URL + `/client/get_client/${data.id}`,
            method:'get',
            headers: { 
                'secret_key': process.env.REACT_APP_SECRET_KEY, 
                'Authorization': `Bearer ${data.token}`
            }
        }
        try{
            const result = await axios(config)
            if(result.data.code){
                return result.data.data
            }else{
                return result.data.message
            }
        }catch(err){
            return err
        }
    }
)

const clientSlice = createSlice({
    name:"client",
    initialState,
    reducers:{
        logout:(state,action) =>{
            state.client = {}
            state.token = ""
            state.id = ""
            state.error = ""
        },
        updateAddress:(state,action) =>{
            state.client = {
                ...state.client,
                addressArray : [state.client.defaultAddress,...action.payload]
            } 
        },
    },
    extraReducers : {

        [clientAuth.fulfilled] : (state,action) => {
            state.token = action.payload.token
            state.id = action.payload.id
        },

        [addClient.fulfilled] : (state,action) => {
            state.client = {
                ...action.payload.user
            }
        },
     
     }
})
export const  { logout,updateAddress } = clientSlice.actions
export default clientSlice.reducer;