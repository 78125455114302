import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    admin:{},
    token:"",
    id:""
}

const adminSlice = createSlice({
    name:"admin",
    initialState,
    reducers:{
        adminLogin : (state,action) =>{
            state.token = action.payload.token
            state.isAuthenticated = true
            state.id = action.payload.id
        },
        addAdmin:(state,action) =>{
            state.admin = {
                ...action.payload
            }
        },
        logout:(state,action) => {
            state.admin = {}
            state.token = ""
            state.id = ""
            state.isAuthenticated = false
        },
    }
})
export const  { addAdmin,logout,adminLogin } = adminSlice.actions
export default adminSlice.reducer;